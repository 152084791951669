import axios from "axios"
import { Base_API_KEY } from "config-global"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap"
import { fetchCasesRequest } from "store/advocate/actions"

const CustomModal = ({ isOpen, toggle, selectedClientData }) => {
  // State for input values
  const [date, setDate] = useState("")
  const [description, setDescription] = useState("")
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()
  // Function to handle form submission
  const handleSubmit = async e => {
    const token = sessionStorage.getItem("accessToken")
    e.preventDefault()
    try {
      // Make API call to submit form data
      const response = await axios.put(
        `${Base_API_KEY}/advocate/callHistory/${selectedClientData?.caseId}`,
        {
          title: title,
          description: description,

          date: date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log("API Response:", response.data)

      // Close the modal after form submission
      toggle()
      setTitle("")
      setDate("")
      setDescription("")
      dispatch(fetchCasesRequest({ status: "" }))
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Timeline</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit} className="pt-3">
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <Input
              type="text"
              className="form-control"
              placeholder="Enter title"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="date">Date:</label>
            <Input
              type="date"
              className="form-control"
              id="date"
              value={date}
              onChange={e => setDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="description">Description:</label>
            <Input
              className="form-control"
              type="textarea"
              id="description"
              placeholder="Enter Description"
              rows="3"
              value={description}
              onChange={e => setDescription(e.target.value)}
              required
            />{" "}
          </div>
          <div className="d-flex justify-content-end gap-4">
            <Button className="mt-4" color="secondary" onClick={toggle}>
              Cancel
            </Button>

            <Button type="submit" className="mt-4" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default CustomModal
