import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { useState } from "react"
import CheckboxGroup from "./CheckboxGroup"
import RadioGroup from "./RadioGroup"
import axios from "utils/api"
const EnForm = ({ lang, caseId }) => {
  const [disDefault, setdisDefault] = useState(0)
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }
  const options2 = ["Yes", "No"]
  const options1 = ["Yes", "No", "Not applicable"]
  const ratingOptions = [
    { label: "5 Excellent", value: 5 },
    { label: "4", value: 4 },
    { label: "3", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Poor", value: 1 },
  ]
  const ratingOptions2 = [
    { label: "5 Excellent", value: 5 },
    { label: "4", value: 4 },
    { label: "3 Somewhat Knowledgeable", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Poor", value: 1 },
  ]
  const ratingOptions3 = [
    { label: "5 Very Knowledgeable", value: 5 },
    { label: "4", value: 4 },
    { label: "3 Somewhat Knowledgeable", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Not Knowledgeble", value: 1 },
  ]
  const ratingOptions4 = [
    { label: "5 Excellent", value: 5 },
    { label: "4", value: 4 },
    { label: "3", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Poor", value: 1 },
    { label: "Not Applicable", value: 0 },
  ]
  // formik

  const formik = useFormik({
    initialValues: {
      disagreementResolved: [],
      appropriateServices: [],
      advocateKnowledge: null,
      meetingPerformance: null,
      communicationSatisfaction: [],
      attendedMeetings: [],
      comfortableAdvocating: [],
      overallExperienceRating: null,
      recommendToFriend: [],
      additionalComments: "",
    },
    validationSchema: Yup.object({
      // Define validation rules for your form fields here
    }),
    onSubmit: async values => {
      // Handle form submission here
      try {
        const apiData = {
          disagreement_resolved: values.disagreementResolved,
          appropriate_services: values.appropriateServices,
          advocate_knowledge: values.advocateKnowledge,
          meeting_performance: values.meetingPerformance,
          communication_satisfaction: values.communicationSatisfaction,
          attended_meetings: values.attendedMeetings,
          comfortable_advocating: values.comfortableAdvocating,
          overall_experience_rating: values.overallExperienceRating,
          recommend_to_friend: values.recommendToFriend,
          additional_comments: values.additionalComments,
        }
        const response = await axios.post(
          `/feedback/case_form?lang=${lang}&caseId=${caseId}`,
          apiData
        )
      } catch (error) {
        // Handle errors
        console.error("API Error:", error)
      }
    },
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="">
              <Col lg="12">
                <Row className="d-flex justify-content-center ">
                  <Col lg="6">
                    <Card>
                      <CardBody
                        style={{
                          borderTop: "solid 10px #e8c428",
                          borderRadius: "10px",
                        }}
                      >
                        <h1 className=" text-center">DPEOP Parent Survey </h1>
                        <p className="card-title-desc ">
                          Thank you for contacting the Delaware Public Education
                          Ombudsperson Program(DPEOP). Please complete the
                          survey below and let us know how we have done in
                          providing advocacy services to your family. Your
                          answers will help us to improve our program. This
                          survey is anonymous. Your email address and any other
                          personally identifying information will not be
                          recorded.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          The information and support I received from DPEOP
                          helped to resolve a disagreement with my child's
                          school or district.
                        </h6>

                        <CheckboxGroup
                          options={options2}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [...formik.values.disagreementResolved, option]
                              : formik.values.disagreementResolved.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "disagreementResolved",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          My child has recieved more appropriate services and/or
                          supports as a result of the information and support I
                          received from DPEOP.
                        </h6>
                        <CheckboxGroup
                          options={options1}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [...formik.values.appropriateServices, option]
                              : formik.values.appropriateServices.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "appropriateServices",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          On a scale of 1-5, how knowledgeable did you feel the
                          DPEOP advocate was in regards to your educational
                          issue?
                        </h6>
                        <RadioGroup
                          question=""
                          options={ratingOptions3}
                          selectedValue={formik.values.advocateKnowledge}
                          onChange={value =>
                            formik.setFieldValue("advocateKnowledge", value)
                          }
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          How would you rate our performance in the meeting we
                          attended on behalf of your child?
                        </h6>

                        <RadioGroup
                          question="meetingPerformance"
                          options={ratingOptions4}
                          selectedValue={formik.values.meetingPerformance}
                          onChange={value =>
                            formik.setFieldValue("meetingPerformance", value)
                          }
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          Are you satisfied with the level of communication you
                          received while working with DPEOP
                        </h6>
                        <CheckboxGroup
                          options={options2}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [
                                  ...formik.values.communicationSatisfaction,
                                  option,
                                ]
                              : formik.values.communicationSatisfaction.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "communicationSatisfaction",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              Did we attend any school or district meetings with
                              you on behalf of your child?
                            </h6>
                            <CheckboxGroup
                              options={options2}
                              onChange={(option, checked) => {
                                const newOptions = checked
                                  ? [...formik.values.attendedMeetings, option]
                                  : formik.values.attendedMeetings.filter(
                                      item => item !== option
                                    )

                                formik.setFieldValue(
                                  "attendedMeetings",
                                  newOptions
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              The information and support I received from DPEOP
                              helped me feel more comfortable in advocating for
                              a critical need relating to my child's education.
                            </h6>
                            <CheckboxGroup
                              options={options2}
                              onChange={(option, checked) => {
                                const newOptions = checked
                                  ? [
                                      ...formik.values.comfortableAdvocating,
                                      option,
                                    ]
                                  : formik.values.comfortableAdvocating.filter(
                                      item => item !== option
                                    )

                                formik.setFieldValue(
                                  "comfortableAdvocating",
                                  newOptions
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              Please rate your overall experience with DPEOP.
                            </h6>
                            <RadioGroup
                              question="overallExperienceRating"
                              options={ratingOptions}
                              selectedValue={
                                formik.values.overallExperienceRating
                              }
                              onChange={value =>
                                formik.setFieldValue(
                                  "overallExperienceRating",
                                  value
                                )
                              }
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col lg={12}>
              <Button
                type="submit"
                style={{ backgroundColor: "#1b242b" }}
                className="d-flex justify-content-center m-auto"
              >
                Submit
              </Button>
            </Col>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EnForm
