import PropTypes from "prop-types"
import React, { useState } from "react"
import { login, startLoading, hasError } from "../../redux/Slices/login"
import { useNavigate } from "react-router-dom"
import axios from "utils/api"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Toast,
  ToastBody,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
// import profile from "assets/images/profile-img.png";
import logo from "assets/images/Final.png"

const AdvocateRegister = props => {
  //meta title
  document.title = "Advocate Register | dpeopconnect"

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const isLoading = useSelector(state => state.auth.startLoading())
  // const errors = useSelector(state => state.auth.error)
  //use State
  const [successMessage, setSuccessMessage] = useState("")
  const [errors, setError] = useState("")

  const [toast, setToast] = useState(false)
  const toggleToast = () => {
    setToast(!toast)
  }
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || "",
      email: "" || "",
      // password: "123456" || "",
      password: "" || "",
      first_name: "" || "",
      last_name: "" || "",
      //   cell_phone: "advocate@gmail.com" || "",
      role: "ADVOCATE" || "",
      //   cell_phone: "0321-2131212" || "",

      // password: "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=?!]).{8,}$/,
          "Password must contain 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character, and be at least 8 characters long"
        ),

      first_name: Yup.string()
        .required("Please Enter Your firstName")
        .matches(
          /^[a-zA-Z\s]+$/,
          "First Name must contain only letters and spaces"
        ),

      last_name: Yup.string()
        .required("Please Enter Your lastName")
        .matches(
          /^[a-zA-Z\s]+$/,
          "Last Name must contain only letters and spaces"
        ),

      //cell_phone: Yup.string().required("Please enter your cellphone"),
    }),

    onSubmit: async values => {
      try {
        const authToken = sessionStorage.getItem("accessToken")

        // Set headers for the request
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }

        // Make an API request to register the user
        const response = await axios.post("admin/add_advocate", values, config)

        // Handle the response as needed
        // console.log("User registered successfully", response.data)

        if (response.data.success === false) {
          setSuccessMessage("")
          setToast(true)
          setError(
            response.data.message ||
              "Failed to register advocate. Please try again"
          )
        } else {
          setSuccessMessage("Advocate has been registered successfully!")
          setToast(true)
          setError("")
          setTimeout(() => {
            // Navigate to the dashboard page
            navigate('/advocates-list');
          }, 2000);
        }

        // You can navigate to another page after successful registration
        // navigate('/some-other-page');
      } catch (error) {
        // Handle registration failure
        console.error("Registration failed", error.response.data)
        setError(
          error.response.data.message ||
            "Failed to register advocate. Please try again."
        )
        setSuccessMessage("")
        setToast(true)
      }
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    // error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  return (
    <React.Fragment>
      {/* {error && (
      <div className="alert alert-danger mt-3" role="alert">
        {error}
      </div>
    )} */}
      {errors && (
        <>
          <div
            className="position-fixed top-10   "
            style={{ zIndex: "1005", left: "45%", padding: "1.25rem" }}
          >
            <Toast isOpen={toast}>
              <ToastBody> {errors}</ToastBody>
            </Toast>
          </div>
        </>
      )}

      {successMessage && (
        <>
          <div
            className="position-fixed top-10   "
            style={{ zIndex: "1005", left: "45%", padding: "1.25rem" }}
          >
            <Toast isOpen={toast}>
              <ToastBody> {successMessage}</ToastBody>
            </Toast>
          </div>
        </>
      )}

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="registration-container">
                  <div className="registration-header mt-4 d-flex justify-content-center align-items-center">
                    {/* <img src={logo} alt="" className="img-fluid" /> */}
                    <h2>Registration Form</h2>
                  </div>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle img-fluid"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          className="form-control"
                          placeholder="Enter First Name"
                          type="text"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              validation.touched.first_name &&
                              validation.errors.first_name
                                ? "pointer"
                                : "auto",
                          }}
                        />
                        {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          className="form-control"
                          placeholder="Enter Last Name"
                          autoCorrect="off"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              validation.touched.last_name &&
                              validation.errors.last_name
                                ? "pointer"
                                : "auto",
                          }}
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              validation.touched.email &&
                              validation.errors.email
                                ? "pointer"
                                : "auto",
                          }}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          autoComplete="off"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              validation.touched.password &&
                              validation.errors.password
                                ? "pointer"
                                : "auto",
                          }}
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AdvocateRegister)

AdvocateRegister.propTypes = {
  history: PropTypes.object,
}
