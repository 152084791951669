import React from "react"
import { Bar } from "react-chartjs-2"
import getChartColorsArray from "components/Common/ChartsDynamicColor"

const BarChart = ({ dataColors, open, close }) => {
  var barChartColor = getChartColorsArray(dataColors)
  const data = {
    labels: ["Open", "Close"],
    datasets: [
      {
        label: "Cases",
        backgroundColor: barChartColor[0],
        borderColor: barChartColor[0],
        borderWidth: 1,
        hoverBackgroundColor: barChartColor[1],
        hoverBorderColor: barChartColor[1],
        data: [open, close],
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1, // Adjust this value to control the width of the bars
        },
      ],
    },
  }

  return <Bar width={751} height={300} data={data} options={options} />
}

export default BarChart
