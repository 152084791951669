import React, { createContext, useContext, useState } from "react";

const DateContext = createContext();

export const useDateContext = () => useContext(DateContext);

export const DateProvider = ({ children }) => {
  const [DateStart, setDateStart] = useState('');
  const [DateEnd, setDateEnd] = useState('');
  

  const value = {
    DateStart,
    setDateStart,
    DateEnd,
    setDateEnd,
  };

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};
