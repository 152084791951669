import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

const ActivityComp = ({ data, advocate_name }) => {

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="text-[#1b242b] mb-3">Timeline</h4>
            <h5 className="text-[#1b242b] mb-3 md:mb-0">
              Advocate : {advocate_name}
            </h5>
          </div>
          <div
            className="activity-list-container"
            style={{
              maxHeight: "230px",
              overflowY: data.length >= 4 ? "scroll" : "hidden",
            }}
          >
            <ul className="verti-timeline list-unstyled">
              {data.map((item, index) => (
                <li
                  className={`event-list ${item.active && "active"}`}
                  key={index}
                >
                  <div className="event-timeline-dot">
                    <i
                      className={`bx bx-right-arrow-circle font-size-18 ${
                        item.active && "bx-fade-right"
                      }`}
                    />
                  </div>
                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3">
                      <h5 className="font-size-14">
                        {item?.date}
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                      </h5>
                    </div>
                    <div className="flex-grow-1">
                      <div>{item?.description}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActivityComp
