import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { dropRight, isEmpty } from "lodash"
import axios from "utils/api"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap" // Import the necessary components

import { Button, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
import {
  getAdvocatesLaw,
  getSingleCases,
  getSingleCases1,
  getSingleCases2,
} from "store/advocate/actions"
import Modal from "pages/AdvocatesDashboard/Modal"
import ModalStreatagy from "pages/AdvocatesDashboard/Modal2"
import Spinners from "components/Common/Spinner"
import { Base_API_KEY } from "config-global"
import { useNavigate } from "react-router-dom"
import CustomModal from "pages/AdvocatesDashboard/CustomModal"
// function format date

const LatestTransaction2 = props => {
  // use effect
  let { value } = props
  const [selectedOption, setSelectedOption] = useState("")

  const handleSelectOption = option => {
    setSelectedOption(option)
  }

  const [Response, setResponse] = useState("")
  const [selectoption, setSelectoption] = useState("")
  const [advocateDatas, setadvocateDatas] = useState("")
  const [loading, setLoading] = useState(true)

  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)

  const [modal1, setModal1] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal5, setModal5] = useState(false)

  const [modal4, setModal4] = useState(false)
  //style obj

  // steps dropdown working
  const [Lastvalue, setLastValue] = useState("")

  const [selectedOptions1, setSelectedOptions1] = useState([])
  const [dropdownStates, setDropdownStates] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  const toggleDropdown = index => {
    const updatedDropdownStates = [...dropdownStates]
    updatedDropdownStates[index] = !updatedDropdownStates[index]
    setDropdownStates(updatedDropdownStates)
  }
  const handleSelectOption3 = async (rowIndex, value, id) => {
    const updatedOptions = [...selectedOptions1]
    updatedOptions[rowIndex] = value
    setSelectedOptions1(updatedOptions)
    setLastValue(value)

    try {
      const token = sessionStorage.getItem("accessToken")

      // Replace `:{id}` with the actual identifier for your request
      const response = await fetch(
        `${Base_API_KEY}/advocate/complaint/add_case_step/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            step: value,
          }),
        }
      )

      if (!response.ok) {
        throw new Error("Failed to add case step")
      }

      // Additional logic if needed after successful API call
    } catch (error) {
      console.error("Error adding case step:", error)
      // Handle error as per your application's requirements
    }
  }

  // -------------------------------------//
  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }
  const toggleViewModal3 = clientData => {
    setSelectedClientData(clientData)

    setModal3(!modal3)
  }
  const toggleViewModal4 = clientData => {
    setSelectedClientData(clientData)

    setModal4(!modal4)
  }

  //nav
  const navigate = useNavigate()
  // data array
  const data = [
    "Initial Contact & Preliminary Assessments",
    "Intake Interview",
    "Records Request",
    "Strategy Lab",
    "Receipt of Records",
    "Execution",
    "Resolution & Closure",
  ]

  const handleLinkClick = (cellValue, data) => {
    // Navigate to the desired URL when the link is clicked, passing selectedClientData as state
    navigate(`/case-description/${cellValue}`, { state: { rowData: data } })
  }
  const [modal2, setModal2] = useState(false)
  // update case status by closed
  const dispatch = useDispatch()
  const role = sessionStorage.getItem("role")
  useEffect(() => {
    if (role != "super-admin") {
      dispatch(getSingleCases(value))
    }
  }, [dispatch, value, role]) // Include dependencies as needed

  const toggleViewModal2 = async clientData => {
    try {
      // Fetch data based on clientData.caseId
      const caseId = clientData?._id

      // Check if caseId is valid before making the API call
      if (caseId) {
      } else {
        // Handle the case where caseId is not valid
        console.error("Invalid caseId")
      }
    } catch (error) {
      // Handle errors if the API call fails
      console.error("Error fetching data:", error)
    }
  }
  function capitalizeFirstLetter(inputString) {
    if (inputString == "OPEN")
      return (
        inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
      )
    else return ""
  }

  // Example usage:
  let outputText = capitalizeFirstLetter(value)

  // useEffect(() => {
  //   const fetchAdvocateList = async () => {
  //     const accessToken = sessionStorage.getItem("accessToken")
  //     try {
  //       const response = await axios.get("/admin/advocate/list", {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       })

  //       const variable = response?.data?.content

  //       const firstNamesWithIds = variable.map(item => ({
  //         id: item?._id,
  //         firstName: item?.first_name,
  //       }))
  //       setDropdownOptions(firstNamesWithIds)

  //       const advocateId = variable?.map(item => item?._id)
  //       setid(advocateId)
  //       setadvocateDatas(variable)
  //     } catch (error) {
  //       console.error("Error fetching advocate list:", error)
  //     }
  //   }

  //   const fetchData = async () => {
  //     setLoadingOptions(true)
  //     await fetchAdvocateList()
  //     // await assignCaseToAdvocate();
  //     setLoadingOptions(false)
  //   }

  //   fetchData()
  // }, []) // Empty dependency array ensures the effect runs only once
  let advocateSingleData

  if (value === "") {
    advocateSingleData = useSelector(
      state => state?.advocate?.singleCases?.content
    )
  } else {
    advocateSingleData = useSelector(
      state => state?.advocate?.singleCases1?.content
    )
  }

  //advocate
  const [dropdownStates1, setDropdownStates1] = useState(
    Array(Response.length).fill(false)
  )
  const [selectedOptions01, setSelectedOptions01] = useState(
    Array(Response.length).fill("")
  )

  const toggleModal = clientData => {
    setModalVisible(!modalVisible)
    setSelectedClientData(clientData)
  }
  //get advocates
  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"
      case "isClosed":
        return "Update Status"
      case "referral":
        return "Urgency"
      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      case "isClosed":
        return "Updated Status"
      case "advocate_name":
        return "Advocate"
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!advocateSingleData || advocateSingleData.length === 0) return []

    const keysToDisplay = [
      "caseId",
      "referral",
      "date",
      "child_name",
      "isClosed",
      "advocate_name",
    ]

    // Function to toggle modal visibility

    const columns = keysToDisplay.map(key => ({
      Header: getCustomHeaderName(key),
      accessor: key, // Make sure accessor is defined for each column
      disableFilters: true,
      Cell: cellProps => {
        if (key === "caseId") {
          // Render Case ID as a button or link, or any other UI element you prefer
          return (
            <a
              className="link-primary override-text-decoration"
              style={{
                color: "blue",
                textDecoration: "underline !important",
              }}
              onClick={() =>
                handleLinkClick(cellProps.value, cellProps.row.original)
              }
            >
              {cellProps.value}
            </a>
          )
        }
        if (key === "date" && cellProps.row.original[key]) {
          const [datePart, timePart] = cellProps.row.original[key].split("T")
          const [year, month, day] = datePart.split("-")
          return <span>{`${day}-${month}-${year}`}</span>
        } else {
          return <span>{cellProps.row.original[key]}</span>
        }
      },
    }))
    columns.push({
      Header: "Timeline", // Customize the header name as needed
      disableFilters: true,
      accessor: "modalButton",
      Cell: cellProps => (
        <Button
          type="button"
          style={{ backgroundColor: "#024E80" }}
          className="btn-sm btn-rounded"
          onClick={() => {
            toggleModal(cellProps.row.original)
          }}
        >
          Add Record
        </Button>
      ),
    })
    columns.push({
      Header: "Case Step",
      disableFilters: true,
      accessor: "stat",
      Cell: cellProps => {
        const id = cellProps.row.original._id
        const rowIndex = cellProps.row.index

        return (
          <Dropdown
            isOpen={dropdownStates[rowIndex]}
            toggle={() => toggleDropdown(rowIndex)}
            className="mt-4 mt-sm-0 "
            style={{ maxHeight: "130px" }}
          >
            <DropdownToggle
              className=""
              color=""
              style={{
                backgroundColor: "#024E80",
                color: "white",
                width: "12.5rem",
                maxHeight: "130px",
              }}
              caret
            >
              {selectedOptions1[rowIndex] &&
              selectedOptions1[rowIndex].length > 30
                ? `${selectedOptions1[rowIndex].substring(0, 20)}...`
                : selectedOptions1[rowIndex] || "Select Step"}{" "}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>

            <DropdownMenu style={{ maxHeight: "130px", overflowY: "scroll" }}>
              {data.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleSelectOption3(rowIndex, option, id)}
                >
                  {option.length > 30
                    ? `${option.substring(0, 30)}...`
                    : option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )
      },
    })

    columns.push({
      Header: "View Document",
      disableFilters: true,
      accessor: "case",
      Cell: cellProps => {
        const complaintFiles = cellProps.row.original.complaintFile || [] // Assuming 'complaintFile' holds an array of documents
        const openPdfFile = complaintFile => {
          if (complaintFile && complaintFile !== "emptyFile") {
            window.open(`${Base_API_KEY}/documents/${complaintFile}`, "_blank")
          }
        }

        return (
          <div className="d-flex justify-content-center">
            <UncontrolledButtonDropdown>
              <DropdownToggle
                caret
                style={{
                  backgroundColor: "#024E80",
                  color: "white",
                }}
                disabled={complaintFiles.length === 0}
              >
                Select Document
              </DropdownToggle>
              <DropdownMenu>
                {complaintFiles.map((complaintFile, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => openPdfFile(complaintFile)}
                  >
                    Document {index + 1}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      },
    })
    if (value == "OPEN") {
      // columns.push({
      //   Header: "Case Active",
      //   disableFilters: true,
      //   accessor: "view2",
      //   Cell: cellProps => (
      //     <Button
      //       type="button"
      //       color="primary"
      //       className="btn-sm btn-rounded"
      //       onClick={() => toggleViewModal2(cellProps.row.original)}
      //     >
      //       Open Case
      //     </Button>
      //   ),
      // });

      columns.push({
        Header: "Closed Case",
        disableFilters: true,
        accessor: "view3",
        Cell: cellProps => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => toggleViewModal3(cellProps.row.original)}
          >
            Case Closed
          </Button>
        ),
      })

      // columns.push({
      //   Header: "StatusCase",
      //   accessor: "StatusCase",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     if (value === 'OPEN') {
      //       // Render PaymentStatus3 if value is OPEN
      //       return <PaymentStatus3 {...cellProps} />;
      //     } else {
      //       // Otherwise, render PaymentStatus2
      //       return <PaymentStatus2 {...cellProps} />;
      //     }
      //   },
      // });
    }

    return columns
  }, [advocateSingleData, value, dropdownStates, selectedOptions1])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />
      <Modal
        isOpen={modal3}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal3}
        toggleViewModal2={toggleViewModal2}
      />
      <ModalStreatagy
        isOpen={modal4}
        value={true}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal4}
        toggleViewModal2={toggleViewModal2}
      />
      <ModalData
        isOpen={modal2}
        toggle={toggleViewModal2}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        Id={Id}
        selectOption={handleSelectOption}
        advocateDatas={advocateDatas}
      />

      <CustomModal
        isOpen={modalVisible}
        selectedClientData={selectedClientData}
        toggle={toggleModal}
      />

      {advocateSingleData ? (
        advocateSingleData.length > 0 ? (
          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">{outputText} Cases</div>
              <TableContainer
                columns={dynamicColumns}
                data={advocateSingleData} // Pass your custom data here
                isGlobalFilter={true}
                isAddOptions={false}
                order="asc"
                isPagination={true}
                customPageSize={10}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            </CardBody>
          </Card>
        ) : (
          <div className="alert alert-warning mt-3">
            <p className="text-primary mb-0">No Cases found</p>
          </div>
        )
      ) : (
        // Loader component or loading message
        <Spinners setLoading={setLoading} />
      )}
    </React.Fragment>
  )
}

LatestTransaction2.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTransaction2)
