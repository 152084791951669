// saga.js
import { call, put, takeEvery } from "redux-saga/effects"
import axios from "utils/api"
import {
  GET_ADVOCATE,
  GET_ADVOCATE_SUCCESS,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES,
  GET_SINGLE_CASES1,
  GET_SINGLE_CASES2,
  GET_SINGLE_CASES_SUCCESS,
  GET_SINGLE_CASES_FAIL,
} from "./actionTypes"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getAdvocateFail,
  getAdvocateSuccess,
  getSingleCasesFail,
  getSingleCasesSuccess,
} from "./actions"

function* fetchSingleCases(action) {
  try {
    const isClosed = action.payload

    const accessToken = sessionStorage.getItem("accessToken")
    let response

    response = yield call(axios.get, `/advocate/complaint/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })

    yield put(getSingleCasesSuccess(response.data))
  } catch (error) {
    console.error("API Error:", error)
    yield put(getSingleCasesFail(error))
    // Handle error notification or other actions if needed
  }
}

export function* singleCasesSaga() {
  yield takeEvery(GET_SINGLE_CASES, fetchSingleCases)
}

export default singleCasesSaga
// export default singleCasesSaga;
