import React from "react";
import { Col, Input, Label } from "reactstrap";

const QuarterlyFilter = ({ selectedQuarter, handleQuarterChange }) => {
  const quarters = [1, 2, 3, 4];

  return (
      <Input
        type="select"
        placeholder="select Quarter"
        id="quarter-select"
        value={selectedQuarter || ""}
        onChange={(e) => handleQuarterChange(Number(e.target.value))}
      >
        <option value="">Select</option>
        {quarters.map((quarter) => (
          <option key={quarter} value={quarter}>
            Quarter {quarter}
          </option>
        ))}
      </Input>
  );
};

export default QuarterlyFilter;
