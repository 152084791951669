import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "utils/api"
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

const ModalCase = props => {
  const {
    isOpen,
    dropdownOptions,
    selectOption,
    toggle,
    selectedClientData,
    toggleViewModal2,
  } = props
  const accessToken = sessionStorage.getItem("accessToken")
  const [response, setResponse] = useState("")
  const [agencyName, setAgencyName] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("")
  const [successPopupVisible, setSuccessPopupVisible] = useState(false)
  const [modalSelectedOption, setModalSelectedOption] = useState("")

  const handleSelectOption = e => {
    setModalSelectedOption(e.target.value)
  }
  const handleStatusChange = event => {
    setSelectedStatus(event.target.value)
  }
  const handleSubmit = () => {
    // Replace this with your actual logic for closing the case
    // For example, showing a pop-up indicating case closed
    toggleViewModal2(selectedClientData)
    setSuccessPopupVisible(true)
    setTimeout(() => {
      window.location.reload()
    }, 2000)
    toggle() // Close the modal
  }
  const [detailText, setDetailText] = useState("")

  const handleDetailChange = event => {
    setDetailText(event.target.value)
  }
  useEffect(() => {
    // Reset selected option when the modal is opened
    setModalSelectedOption("")
  }, [isOpen])
  const handleCreatePdf = () => {
    let endpoint = ""
    let requestBody = {}
    const accessToken = sessionStorage.getItem("accessToken")

    // Check the selected status and set the endpoint and request body accordingly
    if (selectedStatus === "resolved") {
      endpoint = `advocate/complaint/close/?lang=${selectedValue}&caseId=${selectedClientData?._id}`
      requestBody = {
        case_close_summery: detailText,
      }
    } else if (selectedStatus === "referral") {
      endpoint = `/advocate/complaint/referral/${selectedClientData?._id}`
      // Include other fields specific to the referral status in the request body if needed
      if (modalSelectedOption === "OtherAgency") {
        requestBody = {
          case_closed_condition: agencyName,
        }
      } else {
        requestBody = {
          case_closed_condition: modalSelectedOption,
        }
      }
    }

    const httpMethod = selectedStatus === "resolved" ? "post" : "put"

    axios[httpMethod](endpoint, requestBody, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        //toggleViewModal2(selectedClientData)
        setSuccessPopupVisible(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        toggle()
      })
      .catch(error => {
        // Handle the error response
        console.error("Error closing case:", error)
      })
  }

  const renderCaseDetails = () => (
    <div className="d-flex justify-content-center">
      {/* Add your case details here */}
    </div>
  )

  const renderTableHead = () => (
    <thead>
      <tr>
        <th scope="col">Close Case Status</th>
      </tr>
    </thead>
  )
  // use state
  const [selectedValue, setSelectedValue] = useState("")

  const handleSelectChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
  }
  // function
  const renderTableBody = () => (
    <tbody className="d-flex flex-column ">
      <Row>
        <Col>
          <select
            className="form-select"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="resolved">Close-Resolved</option>
            <option value="referral">Close-Referral</option>
          </select>
        </Col>
      </Row>
      {selectedStatus == "resolved" && selectedStatus && (
        <>
          <Row className="mb-5">
            <Col>
              <select
                className="form-select"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Select form val
                </option>
                {selectedStatus === "resolved" && (
                  <option value="en">English</option>
                )}
                {selectedStatus === "resolved" && (
                  <option value="sp">Spanish</option>
                )}
              </select>
            </Col>
          </Row>
        </>
      )}
      {selectedStatus == "referral" && selectedStatus && (
        <>
          <Row className="mb-5">
            <Col>
              <select
                className="form-select"
                value={modalSelectedOption}
                onChange={e => handleSelectOption(e)}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value="CLASI">CLASI</option>
                <option value="PIC">PIC</option>
                <option value="OtherAgency">Other Agency</option>
              </select>
            </Col>
            <Col>
              {modalSelectedOption === "OtherAgency" && (
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Agency Name"
                  value={agencyName}
                  onChange={e => setAgencyName(e.target.value)}
                />
              )}
            </Col>
          </Row>
        </>
      )}
      {selectedStatus == "resolved" ? (
        <Row className="">
          <Col>
            <textarea
              className="form-control"
              id="Detail"
              rows={4}
              placeholder="Enter Detail"
              value={detailText} // Bind the value to the state
              onChange={handleDetailChange} // Handle changes and update the state
            ></textarea>
          </Col>
        </Row>
      ) : null}{" "}
    </tbody>
  )

  const renderModalBody = () => (
    <ModalBody>
      {renderCaseDetails()}
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap">
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </div>
    </ModalBody>
  )

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Case Details</ModalHeader>
          {renderModalBody()}
          <ModalFooter className="">
            <div className="d-flex justify-content-start">
              <Button type="button" color="primary" onClick={handleCreatePdf}>
                Submit
              </Button>
            </div>
            <div className="d-flex justify-content-end">
              <Button type="button" color="secondary" onClick={toggle}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={successPopupVisible}
        toggle={() => setSuccessPopupVisible(false)}
      >
        <Alert color="success">
          {/* Customize the success message as needed */}
          Case Closed successfully!
        </Alert>
      </Modal>
    </>
  )
}

ModalCase.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ModalCase
