// reducer.js
import {
  GET_ADVOCATE_SUCCESS,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES_SUCCESS,
  GET_SINGLE_CASES_FAIL,
  GET_SINGLE_CASES_SUCCESS1,
  GET_SINGLE_CASES_FAIL1,
  GET_SINGLE_CASES_SUCCESS2,
  GET_SINGLE_CASES_FAIL2,
  // ... other imports
} from "./actionTypes"
import * as types from "./actionTypes"

const INIT_STATE = {
  // ... other state properties
  advocate: [],
  notifications: [],
  singleCases: [],
  singleCases1: [],
  singleCases2: [],
  cases: [],
  error: null,
  // ... other state properties
  loading: false,
}

const Advocate = (state = INIT_STATE, action) => {
  switch (action.type) {
    // ... other cases

    case GET_ADVOCATE_SUCCESS:
      return {
        ...state,
        advocate: action.payload,
        loading: false,
      }

    case GET_ADVOCATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SINGLE_CASES_SUCCESS:
      return {
        ...state,
        singleCases: action.payload,
        // Handle loading or other state properties if needed
      }

    case GET_SINGLE_CASES_FAIL:
      return {
        ...state,
        // Handle error or other state properties if needed
      }
    case GET_SINGLE_CASES_SUCCESS1:
      return {
        ...state,
        singleCases1: action.payload,
        // Handle loading or other state properties if needed
      }

    case GET_SINGLE_CASES_FAIL1:
      return {
        ...state,
        // Handle error or other state properties if needed
      }
    case GET_SINGLE_CASES_SUCCESS2:
      return {
        ...state,
        singleCases2: action.payload,
        // Handle loading or other state properties if needed
      }

    case GET_SINGLE_CASES_FAIL2:
      return {
        ...state,
        // Handle error or other state properties if needed
      }
    // ... other cases new func

    case types.FETCH_CASES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case types.FETCH_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        cases: action.payload,
      }
    case types.FETCH_CASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Notification cases
    case types.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      }

    case types.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Advocate
