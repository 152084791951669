// formikConfig.js
import { useFormik } from "formik"
import { useState } from "react"
import { useParams } from "./import"
import axios from "utils/api"
import { validationSchema } from "./validation" // Import the validation schema
import {
  raceOptions,
  displayRaceOptions,
  educationOptions,
  programs,
  displayEducationOptions,
} from "./data" // Import data options
import {
  useClasssificationContext,
  useLinkContext,
  useNavigate,
} from "./import"
import { getSingleCases } from "store/Admin/actions"
import { useDispatch } from "react-redux"

// Initial values
export const initialValues = {
  primaryComplaint: "",
  parentName: "",
  programs: [],
  classification: [], // Initial value for the classification dropdown
  parentName2: "",
  schoolName: "",
  schoolDistrict: "",
  otherEducation: "",
  principal: "",
  education: [],
  childName: "",
  dob: "",
  county: "",
  gender: "",
  grade: "",
  pronoun: "",
  ethnicity: "",
  homeAddress: "",
  homePhone: "",
  cellPhone: "",
  street: "",
  city: "",
  zip: "",
  schoolPhone: "",
  email: "",
  checkbox1: false,
  checkbox2: false,
  checkbox3: false,
  specialEducation: false,
  generalEducation: false,
  mckinneyVento: false,
  bullying: false,
  iep: false,
  plan504: false,
  discipline: false,
  commentMessage: "",
  race: "",
  howDidYouHear: "",
  uplodeDoc: [], // Array to store uploaded documents
}

// Formik configuration
export const useFormikConfig = () => {
  const { linkId, setLinkId } = useLinkContext()
  const dispatch = useDispatch()
  const { Classsification } = useClasssificationContext()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState(null)

  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [selectedRace, setSelectedRace] = useState("")
  const [selectedPrimary, setSelectedPrimary] = useState("")

  const [selectedprograms, setselectedprograms] = useState([])
  const [otherRace, setOtherRace] = useState("")
  const [selectedEducation, setSelectedEducation] = useState([])
  const [otherEducation, setOtherEducation] = useState("")
  const { linkId: routeLinkId } = useParams() // Retrieve linkId from route params
  //use effect
  const [toast, setToast] = useState(false)
  const toggleToast = () => {
    setToast(!toast)
  }
  const [loading, setLoading] = useState(true)
  const [myDoc, setMyDoc] = useState({
    uplodeDoc: [], // Array to store uploaded documents
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Create FormData object to handle files
        const selectedEducationValues = values.education
          ? values.education.map(option => {
              if (option.value === "OTHER") {
                return values.otherEducation // Include the value of 'otherEducation' for 'OTHER' option
              }
              return option.value // Include the value for other education options
            })
          : []

        const selectedProgramValues = values.programs
          .map(option => {
            return option.value // Include the value for other education options
          })
          .filter(value => value !== null && value !== undefined) // Remove null and undefined values

        const formData = new FormData()
        // Append all form values except 'uplodeDoc'
        formData.append("date", values.dob)
        formData.append("parent_first_name", values.parentName)
        formData.append(
          "houseHoldProgram",
          selectedProgramValues?.length > 0 ? selectedProgramValues : null
        )
        formData.append("parent_last_name", values.parentName2)
        formData.append("home_address", values.homeAddress)
        formData.append("home_phone", values.homePhone)
        formData.append("cell_phone", values.cellPhone)
        formData.append("email", values.email)
        formData.append(
          "prefer_to_connect",
          JSON.stringify(values.prefer_to_connect)
        )
        formData.append("school_name", values.schoolName)
        formData.append("district", values.schoolDistrict)
        formData.append("principal_name", values.principal)
        formData.append("school_phone", values.schoolPhone)
        formData.append("school_address", JSON.stringify(values.school_address))
        formData.append("child_name", values.childName)
        formData.append("dob", values.dob)
        formData.append("gender", values.gender)
        formData.append("pronouns", values.pronoun)
        formData.append("ethnicity", values.ethnicity)
        formData.append("other_region", values.other)
        formData.append("region", values.race)
        formData.append("other_education", values.others)
        formData.append(
          "education",
          selectedEducationValues?.length > 0 ? selectedEducationValues : null
        )
        formData.append("briefly_message", values.commentMessage)
        formData.append("summery", values.howDidYouHear)
        formData.append("county", values.county)
        formData.append("student_grade", values.grade)

        // Append each file to FormData
        values.uplodeDoc.forEach(doc => {
          formData.append("uplodeDoc", doc.file)
        })

        // Send the API request with FormData
        const accessToken = sessionStorage.getItem("accessToken")
        const response = await axios.post("/admin/manualComplaint", formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // No need to set 'Content-Type' header explicitly, FormData will handle it
          },
        })
        dispatch(getSingleCases("", "", ""))

        setSubmissionSuccess(true)
        toggleToast()

        // Handle the response as needed
      } catch (error) {
        // Handle errors
        console.error("Error submitting form:", error)
      }
    },
  })

  // Handlers and other helper functions
  const handleFileChange = (event, setFieldValue) => {
    const files = event.target.files
    let newUplodeDoc = [...formik.values.uplodeDoc]

    // Iterate over each newly added file
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Create a URL for the preview
      const previewURL = URL.createObjectURL(file)
      // Push the file object and its preview URL to the array
      newUplodeDoc.push({ file, previewURL })
    }

    // Update Formik's form state with the new array of uploaded documents
    setFieldValue("uplodeDoc", newUplodeDoc)
  }

  const handleRaceChange = event => {
    const selectedValue = event.target.value
    setSelectedRace(selectedValue)

    if (selectedValue === "OTHER") {
      formik.setFieldValue("race", "") // Reset the race field value
    } else {
      formik.setFieldValue("race", selectedValue)
    }
  }
  const handlePrimary = event => {
    const selectedValue = event.target.value
    setSelectedPrimary(selectedValue)

    formik.setFieldValue("primaryComplaint", selectedValue)
  }

  const handleProgramChange = selectedOptions2 => {
    setselectedprograms(selectedOptions2)
  }

  const handleOtherRaceChange = event => {
    setOtherRace(event.target.value)
    formik.setFieldValue("race", "OTHER: " + event.target.value)
  }

  const handleCheckboxChange = name => {
    formik.setFieldTouched(name, false)
    formik.setFieldValue(name, !formik.values[name])
  }

  const handleEducationChange = selectedOptions => {
    setSelectedEducation(selectedOptions)
  }

  const isOtherSelected = selectedEducation.some(
    option => option.value === "OTHER"
  )

  const handleOtherEducationChange = event => {
    setOtherEducation(event.target.value)
    formik.setFieldValue("education", ["OTHER: " + event.target.value])
  }

  return {
    formik,
    selectedRace,
    selectedPrimary,
    handleFileChange,
    toggleToast,
    submissionSuccess,
    handlePrimary,
    toggleToast,
    toast, // Add this line
    handleRaceChange,
    handleProgramChange,
    handleOtherRaceChange,
    handleCheckboxChange,
    handleEducationChange,
    isOtherSelected,
    handleOtherEducationChange,
  }
}
