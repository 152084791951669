import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "../../../utils/api"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()
function* loginUser({ payload: { user, history } }) {
  try {
    // Make the API call using axios.post
    const response = yield call(axios.post, "/auth/login", {
      email: user.email,
      password: user.password,
      fcm_token: [
        {
          device_token: user?.fcm_token[0].device_token,
          device_type: user?.fcm_token[0].device_type,
        },
      ],
    })
    const save = response?.data
    const content = response?.data?.content
    const customer = response?.data?.content?.customer

    const accessToken = content?.accessToken
    const role = customer?.role

    if (accessToken) {
      sessionStorage.setItem("accessToken", accessToken)
      sessionStorage.setItem("role", role)

      // Dispatch the login success action with the API response payload
      yield put(loginSuccess(save))
      const Role = sessionStorage.getItem("role")

      // Only proceed to login if a token is received
      if (Role == "ADVOCATE") {
        history("/dashboard-advocates")
      } else {
        history("/dashboard")
      }

      // Return the token explicitly to mimic createAsyncThunk behavior
      return accessToken
    } else {
      // Handle the case where no token is received
      console.error("No token received")
      // You may want to dispatch an action or handle it in some way
      return null
    }
  } catch (error) {
    console.error("Error:", error)
    // Dispatch the API error action with the error payload
    yield put(apiError(error))
    // Throw the error to match the behavior of createAsyncThunk
    throw error
  }
}

// function* loginUser({ payload: { user, history } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.loginUser,
//         user.email,
//         user.password
//       );
//       yield put(loginSuccess(response));
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtLogin, {
//         email: user.email,
//         password: user.password,
//       });
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeLogin, {
//         email: user.email,
//         password: user.password,
//       });
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     }
//     history('/dashboard');
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
