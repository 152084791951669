// saga.js
import { call, put, takeEvery } from "redux-saga/effects"
import axios from "utils/api"
import {
  GET_ADVOCATE,
  GET_ADVOCATE_SUCCESS,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES_ADMIN,
  GET_SINGLE_CASES1,
  GET_SINGLE_CASES2,
  GET_SINGLE_CASES_SUCCESS,
  GET_SINGLE_CASES_FAIL,
} from "./actionTypes"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getAdvocateFail,
  getAdvocateSuccess,
  getSingleCasesFail,
  getSingleCasesSuccess,
  startLoading,
  stopLoading,
} from "./actions"

function* fetchSingleCasesAdmin(action) {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    yield put(startLoading()); // Dispatch action to start loading state

    let response;

    response = yield call(
      axios.get,
      `admin/complaint/list?start_date=${action?.payload?.val2}&end_date=${action?.payload?.val3}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(getSingleCasesSuccess(response?.data));
    
  } catch (error) {
    console.error('API Error:', error);
    yield put(getSingleCasesFail(error));
    
  } finally {
    yield put(stopLoading()); 
  }
}


export function* singleCasesSagaAdmin() {
  yield takeEvery(GET_SINGLE_CASES_ADMIN, fetchSingleCasesAdmin)
}

export default singleCasesSagaAdmin
// export default singleCasesSaga;
