import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import { useDateContext } from "Context/DateContext"
import { getSingleCases } from "store/Admin/actions"
import { Base_API_KEY } from "config-global"
import TableConatinerReport from "./TableContainerReport"

const AdvancedCaseModal = props => {
  const { isOpen, toggle, selectedClientData } = props
  const dispatch = useDispatch()
  const { DateStart, setDateStart, DateEnd, setDateEnd } = useDateContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getSingleCases("", DateStart, DateEnd))
      } catch (error) {
        console.error("Error fetching data:", error)
        // Handle error if needed
      }
    }

    fetchData()
  }, [dispatch, DateStart, DateEnd])

  const memoizedAdminSingleData = useSelector(state => {
    return state?.admin?.singleCasesAdmin?.content
  })

  const adminSingleData = useMemo(
    () => memoizedAdminSingleData,
    [memoizedAdminSingleData]
  )
  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"
      case "isClosed":
        return "Updated Status"
      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      case "advocate_name":
        return "Advocate"
      case "assign_date":
        return "Assign Date"
      case "referral":
        return "Urgency"
      case "student_grade":
        return "Grade"
      case "school_name":
        return "School Name"
      case "county":
        return "County"
      case "education":
        return "Education"

      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!adminSingleData || adminSingleData?.length === 0) return []

    const keysToDisplay = [
      "caseId",

      "child_name",
      "assign_date",
      "isClosed",
      "advocate_name",
      "referral",
      "school_name",
      "student_grade",
      "county",
      "education",
    ]
    const educationMappings = {
      SPECIAL_EDUCATION: "Special Education",
      GENERAL_EDUCATION: "General Education",
      MCKINNEY_VENTO: "McKinney Vento",
      BUYYING: "Bullying",
      EP: "IEP",
      PLAN_504: "Plan 504",
      DISCIPLINE: "Discipline",
      OTHER: "Other",
    }
    return [
      ...keysToDisplay.map(key => ({
        Header: getCustomHeaderName(key),
        accessor: key,
        disableFilters: true,
        Cell: cellProps => {
          if (key === "date" && cellProps.row.original[key]) {
            // Convert and format the date if the key is 'date'
            const [datePart, timePart] = cellProps.row.original[key].split("T")
            const [year, month, day] = datePart.split("-")

            return <span>{`${day}-${month}-${year}`}</span> // Adjust the formatting as needed
          } else if (key === "education" && cellProps.row.original[key]) {
            // Handle 'education' array
            const educationArray = cellProps.row.original[key]

            if (Array.isArray(educationArray)) {
              const formattedEducation = educationArray.map(
                value => educationMappings[value] || value
              )

              return <span>{formattedEducation.join(", ")}</span>
            } else {
              // Handle non-array values, or just return the original value
              return cellProps.row.original[key]
            }
          } else {
            // Display other properties as they are
            return <span>{cellProps.row.original[key]}</span>
          }
        },
      })),
    ]
  }, [adminSingleData])


  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal modal-fullscreen"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Case Details</ModalHeader>
        <ModalBody>
  {selectedClientData.length > 0 ? (
    <Card>
      <CardBody>
        <TableConatinerReport
          boolvalue={true}
          columns={dynamicColumns}
          data={selectedClientData}
          isGlobalFilter={true}
          isAddOptions={false}
          order="asc"
          isPagination={true}
          customPageSize={10}
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="pagination pagination-rounded justify-content-end mb-2"
        />
      </CardBody>
    </Card>
  ) : (
    <h4>No Data available</h4>
  )}
  <div className="d-flex justify-content-end">
    <Button type="button" color="secondary" onClick={toggle}>
      Close
    </Button>
  </div>
</ModalBody>{" "}

      </div>
    </Modal>
  )
}

AdvancedCaseModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AdvancedCaseModal
