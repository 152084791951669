import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../../utils/api"
// import axios from "axios"

// import { receiveToken } from 'src/authUtils';

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    try {
      const response = await axios.post("/auth/login", {
        email,
        password,
      })

      const token = response.data.token
      // receiveToken(token); // Assume receiveToken is a function to handle storing the token (e.g., in localStorage)
      return token
    } catch (error) {
      throw error
    }
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.token = action.payload
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message || "Login failed."
    })
  },
})

export const { startLoading, hasError } = authSlice.actions

export default authSlice.reducer
