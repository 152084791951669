// import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
// import { Link } from "react-router-dom"
// import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
// import SimpleBar from "simplebar-react"

// import { getToken, getMessaging } from "@firebase/messaging"
// //Import images
// import avatar3 from "../../../assets/images/users/avatar-3.jpg"
// import avatar4 from "../../../assets/images/users/avatar-4.jpg"
// import { messaging, onMessage } from "utils/firebase"
// //i18n
// import { withTranslation } from "react-i18next"
// import { useDispatch } from "react-redux"
// import { vapid } from "utils/vapid"
// // import { messaging } from "utils/firebase"

// const NotificationDropdown = props => {
//   const dispatch = useDispatch()

//   // useEffect(() => {
//   //   dispatch(getNotifications())
//   // }, [dispatch])
//   //firebase setup
//   const [menu, setMenu] = useState(false)
//   useEffect(() => {
//     const setupFirebase = async () => {
//       try {
//         const token = await getToken(messaging, {
//           vapidKey: vapid,
//         })
//         console.log("🚀 ~ setupFirebase ~ token:", token)

//         onMessage(messaging, payload => {
//           console.log("Message received. ", payload)

//           console.log("🚀 ~ setupFirebase ~ messaging:", messaging)
//           alert(payload?.notification?.body)
//         })
//       } catch (error) {
//         console.error("Error setting up Firebase:", error)
//       }
//     }

//     setupFirebase()
//   }, [])

//   return (
//     <React.Fragment>
//       <Dropdown
//         isOpen={menu}
//         toggle={() => setMenu(!menu)}
//         className="dropdown d-inline-block"
//         tag="li"
//       >
//         <DropdownToggle
//           className="btn header-item noti-icon position-relative"
//           tag="button"
//           id="page-header-notifications-dropdown"
//         >
//           <i className="bx bx-bell bx-tada" />
//           <span className="badge bg-danger rounded-pill">3</span>
//         </DropdownToggle>

//         <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
//           <div className="p-3">
//             <Row className="align-items-center">
//               <Col>
//                 <h6 className="m-0"> {props.t("Notifications")} </h6>
//               </Col>
//               <div className="col-auto">
//                 <a href="#" className="small">
//                   {" "}
//                   View All
//                 </a>
//               </div>
//             </Row>
//           </div>

//           <SimpleBar style={{ height: "230px", padding: "0px" }}>
//             <Link to="" className="text-reset  notification-item">
//               <div className="d-flex">
//                 <div className="avatar-xs me-3">
//                   <span className="avatar-title bg-primary rounded-circle font-size-16">
//                     <i className="bx bx-cart" />
//                   </span>
//                 </div>
//                 <div className="flex-grow-1">
//                   <h6 className="mt-0 mb-1">
//                     {props.t("Your order is placed")}
//                   </h6>
//                   <div className="font-size-12 text-muted">
//                     <p className="mb-1">
//                       {props.t("If several languages coalesce the grammar")}
//                     </p>
//                     <p className="mb-0">
//                       <i className="mdi mdi-clock-outline" />{" "}
//                       {props.t("3 min ago")}{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//             <Link to="" className="text-reset notification-item">
//               <div className="d-flex">
//                 <img
//                   src={avatar3}
//                   className="me-3 rounded-circle avatar-xs"
//                   alt="user-pic"
//                 />
//                 <div className="flex-grow-1">
//                   <h6 className="mt-0 mb-1">James Lemire</h6>
//                   <div className="font-size-12 text-muted">
//                     <p className="mb-1">
//                       {props.t("It will seem like simplified English") + "."}
//                     </p>
//                     <p className="mb-0">
//                       <i className="mdi mdi-clock-outline" />
//                       {props.t("1 hours ago")}{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//             <Link to="" className="text-reset notification-item">
//               <div className="d-flex">
//                 <div className="avatar-xs me-3">
//                   <span className="avatar-title bg-success rounded-circle font-size-16">
//                     <i className="bx bx-badge-check" />
//                   </span>
//                 </div>
//                 <div className="flex-grow-1">
//                   <h6 className="mt-0 mb-1">
//                     {props.t("Your item is shipped")}
//                   </h6>
//                   <div className="font-size-12 text-muted">
//                     <p className="mb-1">
//                       {props.t("If several languages coalesce the grammar")}
//                     </p>
//                     <p className="mb-0">
//                       <i className="mdi mdi-clock-outline" />{" "}
//                       {props.t("3 min ago")}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </Link>

//             <Link to="" className="text-reset notification-item">
//               <div className="d-flex">
//                 <img
//                   src={avatar4}
//                   className="me-3 rounded-circle avatar-xs"
//                   alt="user-pic"
//                 />
//                 <div className="flex-grow-1">
//                   <h6 className="mt-0 mb-1">Salena Layfield</h6>
//                   <div className="font-size-12 text-muted">
//                     <p className="mb-1">
//                       {props.t(
//                         "As a skeptical Cambridge friend of mine occidental"
//                       ) + "."}
//                     </p>
//                     <p className="mb-0">
//                       <i className="mdi mdi-clock-outline" />
//                       {props.t("1 hours ago")}{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           </SimpleBar>
//           <div className="p-2 border-top d-grid">
//             <Link
//               className="btn btn-sm btn-link font-size-14 text-center"
//               to="#"
//             >
//               <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
//               <span key="t-view-more">{props.t("View More..")}</span>
//             </Link>
//           </div>
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   )
// }

// export default withTranslation()(NotificationDropdown)

// NotificationDropdown.propTypes = {
//   t: PropTypes.any,
// }
// import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
// import { Link } from "react-router-dom"
// import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
// import SimpleBar from "simplebar-react"
// import { getToken, onMessage } from "firebase/messaging"
// import { messaging } from "utils/firebase" // Make sure to correctly import your Firebase configuration
// import { withTranslation } from "react-i18next"
// import { useDispatch, useSelector } from "react-redux"
// import { vapid } from "utils/vapid"
// import {
//   fetchNotificationsRequest,
//   setNotificationSeenRequest,
// } from "store/advocate/actions"
// import { formatDistanceToNow } from "date-fns"
// const { rowData } = location.state || {}
// console.log("🚀 ~ rowData:", rowData)
// const NotificationDropdown = props => {
//   const dispatch = useDispatch()
//   const notifications = useSelector(state => state.advocate.notifications)

//   const [menu, setMenu] = useState(false)

//   const handleNotificationClick = async notificationId => {
//     dispatch(setNotificationSeenRequest(notificationId))
//     dispatch(fetchNotificationsRequest())
//   }

//   // useEffect(() => {
//   //   const setupFirebase = async () => {
//   //     try {
//   //       dispatch(fetchNotificationsRequest())
//   //       const token = await getToken(messaging, {
//   //         vapidKey: vapid,
//   //       })
//   //       console.log("🚀 ~ setupFirebase ~ token:", token)

//   //       onMessage(messaging, payload => {
//   //         console.log("Message received. ", payload)

//   //         if (Notification.permission === "granted") {
//   //           const { title, body } = payload.notification
//   //           new Notification(title, {
//   //             body,
//   //           })
//   //         } else {
//   //           alert(payload.notification.body)
//   //         }
//   //       })
//   //     } catch (error) {
//   //       console.error("Error setting up Firebase:", error)
//   //     }
//   //   }

//   //   setupFirebase()
//   // }, [dispatch])

//   useEffect(() => {
//     const setupFirebase = async () => {
//       try {
//         dispatch(fetchNotificationsRequest())
//         // Request permission for notifications
//         const permission = await Notification.requestPermission()
//         if (permission === "granted") {
//           console.log("Notification permission granted.")

//           // Get FCM token
//           const token = await getToken(messaging, {
//             vapidKey: vapid,
//           })
//           console.log("FCM Token:", token)

//           // Listen for incoming messages
//           onMessage(messaging, payload => {
//             console.log("Message received. ", payload)
//             dispatch(fetchNotificationsRequest())
//             // Display notification if permission is granted
//             const { title, body } = payload.notification
//             new Notification(title, {
//               body,
//             })
//           })

//           // Fetch notifications after setup is complete
//         } else {
//           console.log("Notification permission denied.")
//         }
//       } catch (error) {
//         console.error("Error setting up Firebase:", error)
//       }
//     }

//     setupFirebase()
//   }, [dispatch])
//   return (
//     <React.Fragment>
//       <Dropdown
//         isOpen={menu}
//         toggle={() => setMenu(!menu)}
//         className="dropdown d-inline-block"
//         tag="li"
//       >
//         <DropdownToggle
//           className="btn header-item noti-icon position-relative"
//           tag="button"
//           id="page-header-notifications-dropdown"
//         >
//           <i className="bx bx-bell bx-tada" />
//           <span className="badge bg-danger rounded-pill">
//             {notifications?.count}
//           </span>
//         </DropdownToggle>

//         <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
//           <div className="p-3">
//             <Row className="align-items-center">
//               <Col>
//                 <h6 className="m-0"> {props.t("Notifications")} </h6>
//               </Col>
//               <div className="col-auto">
//                 <a href="#" className="small">
//                   {" "}
//                   {props.t("View All")}
//                 </a>
//               </div>
//             </Row>
//           </div>

//           <SimpleBar style={{ height: "230px", padding: "0px" }}>
//             {notifications?.notifications
//               ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort notifications by createdAt timestamp in descending order
//               .map(notification => (
//                 <Link
//                   key={notification._id}
//                   // to={{
//                   //   pathname: `/case-description/${notification.caseId}`,
//                   //   state: { rowData }, // Make sure to pass necessary state here
//                   // }}
//                   onClick={() => handleNotificationClick(notification._id)}
//                   className="text-reset notification-item"
//                 >
//                   <div className="d-flex">
//                     <div className="avatar-xs me-3">
//                       <span className="avatar-title bg-primary rounded-circle font-size-16">
//                         <i className="bx bx-bell" />
//                       </span>
//                     </div>
//                     <div className="flex-grow-1">
//                       <h6 className="mt-0 mb-1">{notification.caseId}</h6>
//                       <div className="font-size-12 text-muted">
//                         <p className="mb-1">{notification.message}</p>
//                         <p className="mb-0">
//                           <i className="mdi mdi-clock-outline" />{" "}
//                           {formatDistanceToNow(
//                             new Date(notification.createdAt),
//                             {
//                               addSuffix: true,
//                             }
//                           )}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               ))}
//           </SimpleBar>
//           <div className="p-2 border-top d-grid">
//             <Link
//               className="btn btn-sm btn-link font-size-14 text-center"
//               to="#"
//             >
//               <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
//               <span key="t-view-more">{props.t("View More..")}</span>
//             </Link>
//           </div>
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   )
// }

// NotificationDropdown.propTypes = {
//   t: PropTypes.any,
// }

// export default withTranslation()(NotificationDropdown)

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { getToken, onMessage } from "firebase/messaging"
import { messaging } from "utils/firebase" // Make sure to correctly import your Firebase configuration
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { vapid } from "utils/vapid"
import {
  fetchNotificationsRequest,
  setNotificationSeenRequest,
} from "store/advocate/actions"
import { formatDistanceToNow } from "date-fns"

const NotificationDropdown = props => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.advocate.notifications)

  const [menu, setMenu] = useState(false)

  const handleNotificationClick = async notificationId => {
    dispatch(setNotificationSeenRequest(notificationId))
    dispatch(fetchNotificationsRequest())
  }

  useEffect(() => {
    const setupFirebase = async () => {
      try {
        dispatch(fetchNotificationsRequest())
        // Request permission for notifications
        const permission = await Notification.requestPermission()
        if (permission === "granted") {
          console.log("Notification permission granted.")

          // Get FCM token
          const token = await getToken(messaging, {
            vapidKey: vapid,
          })
          console.log("FCM Token:", token)

          // Listen for incoming messages
          onMessage(messaging, payload => {
            console.log("Message received. ", payload)
            dispatch(fetchNotificationsRequest())
            // Display notification if permission is granted
            const { title, body } = payload.notification
            new Notification(title, {
              body,
            })
          })
        } else {
          console.log("Notification permission denied.")
        }
      } catch (error) {
        console.error("Error setting up Firebase:", error)
      }
    }

    setupFirebase()
  }, [dispatch])

  const sortedNotifications = notifications?.notifications?.sort((a, b) => {
    if (a.seen === b.seen) {
      return new Date(b.createdAt) - new Date(a.createdAt)
    }
    return a.seen ? 1 : -1
  })

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {notifications?.count}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                {/* <a href="#" className="small">
                  {" "}
                  {props.t("View All")}
                </a> */}
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px", padding: "0px" }}>
            {sortedNotifications?.map(notification => (
              <Link
                key={notification._id}
                onClick={() => handleNotificationClick(notification._id)}
                className="text-reset notification-item"
              >
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-bell" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{notification.caseId}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{notification.message}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {formatDistanceToNow(new Date(notification.createdAt), {
                          addSuffix: true,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            {/* <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link> */}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NotificationDropdown)
