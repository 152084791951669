// actions.js
import {
    GET_ADVOCATE,
    GET_ADVOCATE_SUCCESS_ADMIN,
    GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES_ADMIN,
  GET_SINGLE_CASES_SUCCESS_ADMIN,
  GET_SINGLE_CASES_FAIL,
  GET_SINGLE_CASES1,
  GET_SINGLE_CASES_SUCCESS1,
  GET_SINGLE_CASES_FAIL1,
  GET_SINGLE_CASES2,
  GET_SINGLE_CASES_SUCCESS2,
  GET_SINGLE_CASES_FAIL2,
  START_LOADING,
  STOP_LOADING,
  } from "./actionTypes";
  
  export const getAdvocatesLaw = () => ({
    type: GET_ADVOCATE,
  });
  
  export const getAdvocateSuccessAdmin = (advocate) => ({
    type: GET_ADVOCATE_SUCCESS_ADMIN,
    payload: advocate,
  });
  
  export const getAdvocateFail = (error) => ({
    type: GET_ADVOCATE_FAIL,
    payload: error,
  });
  // single cases
  export const getSingleCases = (val1,val2,val3) => ({
    type: GET_SINGLE_CASES_ADMIN,
    payload: {val1,val2,val3},
  });
  
  export const getSingleCasesSuccess = (cases) => ({
    type: GET_SINGLE_CASES_SUCCESS_ADMIN,
    payload: cases,
  });
  
  export const getSingleCasesFail = (error) => ({
    type: GET_SINGLE_CASES_FAIL,
    payload: error,
  })
  export const getSingleCases1 = (isClosed) => ({
    type: GET_SINGLE_CASES1,
    payload: isClosed,
  });
  
  export const getSingleCasesSuccess1 = (cases) => ({
    type: GET_SINGLE_CASES_SUCCESS1,
    payload: cases,
  });
  
  export const getSingleCasesFail1 = (error) => ({
    type: GET_SINGLE_CASES_FAIL1,
    payload: error,
  })
  export const getSingleCases2 = (isClosed) => ({
    type: GET_SINGLE_CASES2,
    payload: isClosed,
  });
  
  export const getSingleCasesSuccess2 = (cases) => ({
    type: GET_SINGLE_CASES_SUCCESS2,
    payload: cases,
  });
  
  export const getSingleCasesFail2 = (error) => ({
    type: GET_SINGLE_CASES_FAIL2,
    payload: error,
  })
  //loading
  export const startLoading = () => ({
    type: START_LOADING,
  });
  
  export const stopLoading = () => ({
    type: STOP_LOADING,
  });


  // new 
  import * as types from './actionTypes';

export const fetchCasesRequest = (status) => ({
  type: types.FETCH_CASES_REQUEST,
  payload: status,
});

export const fetchCasesSuccess = (cases) => ({
  type: types.FETCH_CASES_SUCCESS,
  payload: cases,
});

export const fetchCasesFailure = (error) => ({
  type: types.FETCH_CASES_FAILURE,
  payload: error,
});