import { createContext, useContext, useEffect, useState } from "react"
import axios from "utils/api"
import React from "react"
import { Base_API_KEY } from "config-global"
const ClasssificationContext = createContext()

export const ClasssificationProvider = ({ children }) => {
  const [Classsification, setClasssification] = useState([])

  useEffect(() => {
    // Your API endpoint
    const apiUrl = `${Base_API_KEY}/admin/classification
    `

    axios
      .get(apiUrl)
      .then(response => {
        // Handle the successful response here
        const extractedClasssification = response?.data?.content || []
        const formattedClassifications = extractedClasssification.map(item => ({
          label: item.label,
          options: item.options,
        }))
        setClasssification(formattedClassifications)
      })
      .catch(error => {
        // Handle the error here
        console.error("Error fetching data:", error)
      })
  }, []) //

  return (
    <ClasssificationContext.Provider value={{ Classsification }}>
      {children}
    </ClasssificationContext.Provider>
  )
}

export const useClasssificationContext = () => {
  return useContext(ClasssificationContext)
}
