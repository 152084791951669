const yearData = [
    {
        name: "Series A",
        data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
    },
    {
        name: "Series B",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
    },
    {
        name: "Series C",
        data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
    },
];

const monthData = [
    {
        name: "Series A",
        data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
    },
    {
        name: "Series B",
        data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
    },
    {
        name: "Series C",
        data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
    },
];

const weekData = [
    {
        name: "Series A",
        data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
    {
        name: "Series B",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
    {
        name: "Series C",
        data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
    },
];

const latestTransaction = [
    {
        orderId: "DPIP-009",
        billingName: "Jacob Hunter",
        orderdate: "04 Oct, 2019",
        total: "$392",
        paymentStatus: "Completed",
        methodIcon: "fab fa-cc-paypal",
        paymentMethod: "Paypal",
    },
    {
        orderId: "DPIP-006",
        billingName: "Ronald Taylor",
        orderdate: "04 Oct, 2019",
        total: "$404",
        paymentStatus: "Current",
        methodIcon: "fab fa-cc-visa",
        paymentMethod: "Visa",
    },
    {
        orderId: "DPIP-004",
        billingName: "Barry",
        orderdate: "05 Oct, 2019",
        paymentStatus: "Current",
        paymentMethod: "Mike Antheon",
    },
    {
        orderId: "DPIP-003",
        billingName: "Juan Mitchell",
        orderdate: "06 Oct, 2019",
        total: "$384",
        paymentStatus: "Current",
        paymentMethod: "John Smith",
    },
    {
        orderId: "DPIP-002",
        billingName: "Jamal Burnett",
        orderdate: "07 Oct, 2019",
        total: "$380",
        paymentStatus: "Completed",
        paymentMethod: "Eric Walton",
    },
    {
        orderId: "DPIP-001",
        billingName: "Neal Matthews",
        orderdate: "07 Oct, 2019",
        total: "$400",
        paymentStatus: "Pending",
        paymentMethod: "Adam Smith",
    },
];

const activityDataDashboard = [
    {
        id: 1,
        active: false,
        date: "22 Nov",
        activity: "Responded to need “Volunteer Activities”"
    },
    {
        id: 2,
        active: false,
        date: "17 Nov",
        activity: "Everyone realizes why a new common language would be desirable... Read More"
    },
    {
        id: 3,
        active: true,
        date: "15 Nov",
        activity: "Joined the group “Boardsmanship Forum”"
    },
    
]

const TopCitiesSelling = [
    {
        id: 1,
        location: "San Francisco",
        value: "1,456",
        progress: {
            value: "94",
            color: "primary"
        }
    },
    {
        id: 2,
        location: "Los Angeles",
        value: "1,123",
        progress: {
            value: "82",
            color: "success"
        }
    },
    {
        id: 3,
        location: "San Diego",
        value: "1,026",
        progress: {
            value: "70",
            color: "warning"
        }
    }
]

export { yearData, monthData, weekData, latestTransaction, activityDataDashboard, TopCitiesSelling };