import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/Final.png"
import Select from "react-select"

import {
  Container,
  Button,
  Col,
  Label,
  Input,
  Form,
  Row,
  ToastHeader,
  ToastBody,
  CardBody,
  Card,
  Toast,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useLinkContext } from "../../Context/LinkContext"
// class contxt
import { useClasssificationContext } from "Context/ClassificationContext"
import { Link, useParams } from "react-router-dom"
import { useFormik } from "formik"
import Spinners from "components/Common/Spinner"
import * as Yup from "yup"
import axios from "utils/api"
import { useSchoolContext } from "Context/SchoolContext"
import CustomSelect from "pages/Admin/Form/CustomSelect"
// import logo from "../../assets/images/logo.svg"

const ClientForm = () => {
  //meta title
  document.title = "Client Intake | dpeopconnect"
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  }

  //use State //use context
  const { linkId, setLinkId } = useLinkContext()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState(null)
  const [selectedPrimary, setSelectedPrimary] = useState("")

  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [selectedRace, setSelectedRace] = useState("")
  const [otherRace, setOtherRace] = useState("")
  const [selectedEducation, setSelectedEducation] = useState([])
  const [selectedprograms, setselectedprograms] = useState([])

  const [otherEducation, setOtherEducation] = useState("")
  const { linkId: routeLinkId } = useParams() // Retrieve linkId from route params

  //use effect
  const [loading, setLoading] = useState(true)
  const { SchoolDistrict, schoolNames, setSelectedDistrict } =
    useSchoolContext()
  const { Classsification } = useClasssificationContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true) // Set loading to true when starting the API call
        const response = await axios.get(`/complaint/${routeLinkId}`)
        const apiResponses = response.data?.success
        setApiResponse(apiResponses)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoading(false) // Set loading to false regardless of success or error
      }
    }
    // Only make the API call if linkId is available and apiResponse is not set
    fetchData()
  }, [linkId, Classsification])

  // validation schema
  //
  const raceOptions = [
    "RACE",
    "AFRICAN_AMERICAN",
    "CAUCASIAN",
    "HISPANIC_LATINO",
    "ASIAN",
    "OTHER",
  ]
  const classifications = [
    {
      label: "Physical Disabilities",
      options: [
        "Autism",
        "Orthopedic Impairment",
        "Deafness",
        "Multiple Disabilities",
        "Traumatic Brain Injury",
        "Visual Impairment",
      ],
    },
    {
      label: "Developmental Disorders",
      options: [
        "Language Disorder",
        "Behavior",
        "Developmental Delay",
        "Communication Impairment",
        "ID",
        "LD",
        "Dyslexia",
        "Dyscalculia",
        "Written Expression Disorder",
      ],
    },
    {
      label: "Other Health Conditions",
      options: ["Other Health Impairment", "ADHD", "ODD", "Executive Function"],
    },
    {
      label: "Emotional and Behavioral Disorders",
      options: [
        "Emotional Disturbance",
        "Anxiety Disorder",
        "Schizophrenia",
        "Bipolar Disorder",
        "Obsessive-Compulsive Disorder",
        "Depression",
      ],
    },
  ]
  const displayRaceOptions = {
    RACE: "Race",
    AFRICAN_AMERICAN: "African American",
    CAUCASIAN: "Caucasian",
    HISPANIC_LATINO: "Hispanic/Latino",
    ASIAN: "Asian",
    OTHER: "Other",
  }
  const primaryComplaint = [
    "Bullying_and_Harassment",
    "Safety",
    "Transportation",
    "Special_Education",
    "Suspension",
    "Attendance",
    "Placement",
    "Truancy",
    "Academic_Progress",
  ]
  const displayOptionsWithUnderscores = {
    Bullying_and_Harassment: "Bullying and Harassment",
    Safety: "Safety",
    Transportation: "Transportation",
    Special_Education: "Special Education",
    Suspension: "Suspension",
    Attendance: "Attendance",
    Placement: "Placement",
    Truancy: "Truancy",
    Academic_Progress: "Academic Progress",
  }

  const educationOptions = [
    "SPECIAL_EDUCATION",
    "GENERAL_EDUCATION",
    "MCKINNEY_VENTO",
    "BUYYING",
    "EP",
    "PLAN_504",
    "DISCIPLINE",
    "OTHER",
  ]
  const programs = [
    "Medicaid",
    "SNAP (Food Stamps)",
    "TANF",
    "Free or Reduced Lunch Program",
    "Reduced Lunch Program",
    "None",
    "Prefer not to say",
  ]
  const displayEducationOptions = {
    SPECIAL_EDUCATION: "Special Education",
    GENERAL_EDUCATION: "General Education",
    MCKINNEY_VENTO: "McKinney Vento",
    BUYYING: "Bullying",
    EP: "IEP",
    PLAN_504: "Plan 504",
    DISCIPLINE: "Discipline",
    OTHER: "Other",
  }
  const handleRaceChange = event => {
    const selectedValue = event.target.value
    setSelectedRace(selectedValue)

    if (selectedValue === "OTHER") {
      formik.setFieldValue("race", "") // Reset the race field value
    } else {
      formik.setFieldValue("race", selectedValue)
    }
  }
  const handlePrimary = event => {
    const selectedValue = event.target.value
    setSelectedPrimary(selectedValue)

    formik.setFieldValue("primaryComplaint", selectedValue)
  }

  const handleOtherRaceChange = event => {
    setOtherRace(event.target.value)
    formik.setFieldValue("race", "OTHER: " + event.target.value)
  }
  const validationSchema = Yup.object().shape({
    parentName: Yup.string()
      .required("Parent's First Name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Parent's First Name"
      ),
    // classification: Yup.string().required("Classification is required"),
    race: Yup.string().required("Race is required"),
    primaryComplaint: Yup.string().required("Primary Complaint is required"),
    parentName2: Yup.string()
      .required("Parent's Last Name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Parent's Last Name"
      ),
    homeAddress: Yup.string()
      .required("Home Address is required")
      .matches(
        /^[a-zA-Z0-9\s/-]+$/,
        "Only letters, numbers, spaces, hyphens, and slashes are allowed in Home address"
      ),
    homePhone: Yup.string()
      .required("Home Phone is required")
      .matches(
        /^[0-9#+*.,-]+$/,
        "Only numbers and specific special characters are allowed in Home Phone"
      )
      .min(10, "Home Phone must be at least 10 characters")
      .max(15, "Home Phone must be at most 15 characters"),
    cellPhone: Yup.string()
      .required("Cell Phone is required")
      .matches(
        /^[0-9#+*.,-]+$/,
        "Only numbers and specific special characters are allowed in Cell Phone"
      )
      .min(10, "Cell Phone must be at least 10 characters")
      .max(15, "Cell Phone must be at most 15 characters"),
    schoolName: Yup.string().required("School Name is required"),
    schoolDistrict: Yup.string().required("School District is required"),
    street: Yup.string()
      .required("Street is required")
      .matches(
        /^[a-zA-Z0-9\s/-]+$/,
        "Only letters, numbers, spaces, hyphens, and slashes are allowed in Street"
      ),
    city: Yup.string()
      .required("City is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Name of City"
      ),
    zip: Yup.string()
      .required("Zip Code is required")
      .matches(
        /^[a-zA-Z0-9\s-]+$/,
        "Only letters, numbers, spaces, and hyphens are allowed in Zip"
      ),
    schoolPhone: Yup.string()
      .required("School Phone is required")
      .matches(
        /^[0-9#+*.,-]+$/,
        "Only numbers and specific special characters are allowed in School Phone"
      )
      .min(10, "School Phone must be at least 10 characters")
      .max(15, "School Phone must be at most 15 characters"),
    principal: Yup.string()
      .required("Principal is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Principal"
      ),
    childName: Yup.string()
      .required("Child Name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in child Name"
      ),
    dob: Yup.string()
      .matches(
        /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
        "Invalid date format. Please use MM-DD-YYYY"
      )
      .required("Date of Birth is required"),
    education: Yup.array()
      .min(1, "Select at least 1 educational issues")
      .required("Please select at least 1 educational issues"),
    programs: Yup.array()
      .min(1, "Select at least 1 household")
      .required("Please select at least 1 household"),
    otherEducation: Yup.string().when(["education"], (education, schema) => {
      return education && education.some(option => option.value === "OTHER")
        ? schema.required("Please enter Other Education Issue")
        : schema.notRequired()
    }),
    grade: Yup.string()
      .required("Grade is required")
      .oneOf(
        [
          "Pre-K",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "Other",
        ],
        "Invalid Grade"
      ),
    gender: Yup.string()
      .required("Grade is required")
      .oneOf(
        ["MALE", "FEMALE", "OTHER"],
        "Invalid grade. Accepted values are "
      ),
    pronoun: Yup.string()
      .required("Pronouns are required")
      .oneOf(
        ["he", "she", "other", "they"],
        "Invalid pronouns. Accepted values are he/him or she/her they/them or other"
      ),
    ethnicity: Yup.string()
      .required("Ethnicity is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Ethincity"
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // Add validations for other fields as needed
    checkbox1: Yup.boolean(),
    checkbox2: Yup.boolean(),
    checkbox3: Yup.boolean(),
    atLeastOneCheckbox: Yup.boolean().test(
      "at-least-one-checkbox",
      "At least one checkbox must be selected",
      function (value) {
        const { checkbox1, checkbox2, checkbox3 } = this.parent
        return checkbox1 || checkbox2 || checkbox3
      }
    ),
    county: Yup.string().required("Please select any of the option"),
    commentMessage: Yup.string().required("Please provide a brief message"),
    howDidYouHear: Yup.string().matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in How did you hear"
    ),
  })

  const handleCheckboxChange = name => {
    formik.setFieldTouched(name, false)
    formik.setFieldValue(name, !formik.values[name])
  }
  // const handleEducationChange = event => {
  //   const selectedValue = event.target.value
  //   setSelectedEducation(selectedValue)

  //   if (selectedValue === "OTHER") {
  //     formik.setFieldValue("education", []) // Reset the education field value
  //   } else {
  //     formik.setFieldValue("education", [selectedValue])
  //   }
  // }
  const handleEducationChange = selectedOptions => {
    setSelectedEducation(selectedOptions)
  }
  const handleProgramChange = selectedOptions2 => {
    setselectedprograms(selectedOptions2)
  }
  const isOtherSelected = selectedEducation.some(
    option => option.value === "OTHER"
  )

  const handleOtherEducationChange = event => {
    setOtherEducation(event.target.value)
    formik.setFieldValue("education", ["OTHER: " + event.target.value])
  }
  const initialValues = {
    primaryComplaint: "",

    parentName: "",
    classification: [], // Initial value for the classification dropdown
    programs: [],
    parentName2: "",
    schoolDistrict: "",
    schoolName: "",
    otherEducation: "",
    principal: "",
    education: [],
    childName: "",
    dob: "",
    county: "",
    gender: "",
    grade: "",
    pronoun: "",
    ethnicity: "",
    homeAddress: "",
    homePhone: "",
    cellPhone: "",
    street: "",
    city: "",
    zip: "",
    schoolPhone: "",
    email: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    specialEducation: false,
    generalEducation: false,
    mckinneyVento: false,
    bullying: false,
    iep: false,
    plan504: false,
    discipline: false,
    commentMessage: "",
    race: "",
    howDidYouHear: "",

    // Add initial values for other fields
  }

  useEffect(() => {
    let timeout

    if (submissionSuccess) {
      // Set a timeout to hide the success message after 3000 milliseconds (3 seconds)
      timeout = setTimeout(() => {
        window.close()
        // setSubmissionSuccess(false)
      }, 5000)
    }

    // Clear the timeout if the component unmounts or if submissionSuccess changes
    return () => clearTimeout(timeout)
  }, [submissionSuccess])
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: values => {
  //     try {
  //       const selectedEducationValues = values.education

  //         .map(option => {
  //           if (option.value === "OTHER") {
  //             return values.otherEducation // Include the value of 'otherEducation' for 'OTHER' option
  //           }
  //           return option.value // Include the value for other education options
  //         })
  //         .filter(value => value !== null && value !== undefined) // Remove null and undefined values
  //       const selectedProgramValues = values.programs

  //         .map(option => {
  //           return option.value // Include the value for other education options
  //         })
  //         .filter(value => value !== null && value !== undefined) // Remove null and undefined values

  //       // Convert the form values to the desired API request format
  //       const apiRequestBody = {
  //         date: values.dob, // You can customize the date format as needed
  //         parent_first_name: values.parentName,
  //         parent_last_name: values.parentName2,
  //         home_address: values.homeAddress,
  //         home_phone: values.homePhone,
  //         cell_phone: values.cellPhone,
  //         email: values.email,
  //         prefer_to_connect: {
  //           home_phone: values.checkbox1,
  //           cell_phone: values.checkbox2,
  //           email: values.checkbox3,
  //         },
  //         school_name: values.schoolName,
  //         district: values.schoolDistrict,
  //         principal_name: values.principal,
  //         school_phone: values.schoolPhone,
  //         school_address: {
  //           street: values.street,
  //           city: values.city,
  //           state: values.state,
  //           zip: values.zip,
  //         },
  //         child_name: values.childName,
  //         dob: values.dob,
  //         gender: values.gender,
  //         pronouns: values.pronoun,
  //         ethnicity: values.ethnicity,
  //         other_region: values.other,
  //         region: values.race,
  //         other_education: values.others,
  //         education:
  //           selectedEducationValues.length > 0 ? selectedEducationValues : null,
  //         houseHoldProgram:
  //           selectedProgramValues?.length > 0 ? selectedProgramValues : null,
  //         briefly_message: values.commentMessage,
  //         summery: values.howDidYouHear,
  //         county: values.county,
  //         student_grade: values.grade,
  //         classification: values.classification,
  //       }

  //       console.log("🚀 ~ ClientForm ~ apiRequestBody:", apiRequestBody)
  //       const selectedRace = values.race

  //       // Rest of your form submission logic
  //       const response = axios.post(`/complaint/${routeLinkId}`, apiRequestBody)

  //       toggleToast()
  //       setSubmissionSuccess(true)
  //       setTimeout(() => {
  //         window.close()
  //         // navigate("/dashboard");
  //       }, 5000) // 2000 milliseconds (2 seconds) delay

  //       // Handle the response as needed
  //     } catch (error) {
  //       // Handle errors
  //     }
  //   },

  //   // You can perform your submit logic here, e.g., make an API call
  // })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        const selectedEducationValues = values.education
          .map(option => {
            if (option.value === "OTHER") {
              return values.otherEducation
            }
            return option.value
          })
          .filter(value => value !== null && value !== undefined)

        const selectedProgramValues = values.programs
          .map(option => option.value)
          .filter(value => value !== null && value !== undefined)

        const apiRequestBody = {
          date: values.dob,
          parent_first_name: values.parentName,
          parent_last_name: values.parentName2,
          home_address: values.homeAddress,
          home_phone: values.homePhone,
          cell_phone: values.cellPhone,
          email: values.email,
          prefer_to_connect: {
            home_phone: values.checkbox1,
            cell_phone: values.checkbox2,
            email: values.checkbox3,
          },
          school_name: values.schoolName,
          district: values.schoolDistrict,
          principal_name: values.principal,
          school_phone: values.schoolPhone,
          school_address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zip: values.zip,
          },
          child_name: values.childName,
          dob: values.dob,
          gender: values.gender,
          pronouns: values.pronoun,
          ethnicity: values.ethnicity,
          other_region: values.other,
          region: values.race,
          other_education: values.others,
          education:
            selectedEducationValues.length > 0 ? selectedEducationValues : null,
          houseHoldProgram:
            selectedProgramValues.length > 0 ? selectedProgramValues : null,
          briefly_message: values.commentMessage,
          summery: values.howDidYouHear,
          county: values.county,
          student_grade: values.grade,
          classification: values.classification,
        }

        console.log("🚀 ~ ClientForm ~ apiRequestBody:", apiRequestBody)

        const response = await axios.post(
          `/complaint/${routeLinkId}`,
          apiRequestBody
        )

        toggleToast()
        setSubmissionSuccess(true)
        setTimeout(() => {
          window.close()
          // navigate("/dashboard")
        }, 5000) // 5000 milliseconds (5 seconds) delay
      } catch (error) {
        console.error("Error submitting the form:", error)
      }
    },
  })

  const [toast, setToast] = useState(false)
  const toggleToast = () => {
    setToast(!toast)
  }
  const saved = apiResponse?.success
  //schoolName data
  useEffect(() => {
    setSelectedDistrict(formik.values.schoolDistrict) // Set selectedDistrict when schoolDistrict changes
  }, [formik.values.schoolDistrict])
  return (
    <React.Fragment>
      {loading ? (
        <Spinners setLoading={setLoading} />
      ) : apiResponse ? (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center ">
            {/* <img src={profile} alt="" className="img-fluid" /> */}
            <img
              src={logo}
              alt=""
              className="img-fluid"
              style={{ height: "10rem" }}
            />
          </div>

          {submissionSuccess ? (
            <div className="page-content" style={{ paddingTop: "0" }}>
              {/* Success message goes here */}
              <Row>
                <Col xl={12}>
                  <Card>
                    <div>
                      <div
                        className="position-fixed top-50 start-50 translate-middle p-3"
                        style={{ zIndex: "1005" }}
                      >
                        <Toast isOpen={toast}>
                          <ToastBody>
                            Form has been Submitted Successfully
                          </ToastBody>
                        </Toast>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>{" "}
            </div>
          ) : (
            <div className="page-content pt-0">
              <Container
                style={{
                  backgroundColor: "white",
                  padding: "1px",
                  background: "#FFFFFF",
                  padding: "20px",
                  bordeRadius: " 8px",
                  border: "1px solid #efefef",
                  paddingBottom: "10px",
                }}
              >
                <div className="container mt-3">
                  <Form onSubmit={formik.handleSubmit}>
                    {/* ... other form elements ... */}
                    <div className="mb-3 d-flex justify-content-center fw-bold">
                      <strong className="fs-4">
                        CLIENT INTAKE INFORMATION{" "}
                      </strong>
                    </div>
                    <div className="row mt-5 mb-3">
                      <h4 className="fw-bold">Personal Bio</h4>

                      <div className="col-md-6 mb-3">
                        <Label htmlFor="parentName">Parents First Name:</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="parentName"
                          placeholder="Enter Your Parent's First Name"
                          onChange={formik.handleChange}
                          value={formik.values.parentName}
                        />
                        {formik.errors.parentName &&
                          formik.touched.parentName && (
                            <div className="text-danger">
                              {formik.errors.parentName}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="parentName">Parents Last Name:</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="parentName2"
                          placeholder="Enter Your Parent's Last Name"
                          onChange={formik.handleChange}
                          value={formik.values.parentName2}
                        />
                        {formik.errors.parentName2 &&
                          formik.touched.parentName2 && (
                            <div className="text-danger">
                              {formik.errors.parentName2}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="homeAddress">Home Address:</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="homeAddress"
                          placeholder="Enter Your Home Address"
                          onChange={formik.handleChange}
                          value={formik.values.homeAddress}
                        />
                        {formik.errors.homeAddress &&
                          formik.touched.homeAddress && (
                            <div className="text-danger">
                              {formik.errors.homeAddress}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="email">Email:</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Enter Your Email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="homePhone">Home Phone:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="homePhone"
                            placeholder="Enter Your Home Phone"
                            onChange={formik.handleChange}
                            value={formik.values.homePhone}
                          />
                          {formik.errors.homePhone &&
                            formik.touched.homePhone && (
                              <div className="text-danger">
                                {formik.errors.homePhone}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="cellPhone">Cell Phone:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="cellPhone"
                            placeholder="Enter Your Cell Phone"
                            onChange={formik.handleChange}
                            value={formik.values.cellPhone}
                          />
                          {formik.errors.cellPhone &&
                            formik.touched.cellPhone && (
                              <div className="text-danger">
                                {formik.errors.cellPhone}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <div className="row mb-3">
                      <label className="col-form-label col-sm-4">
                        I Prefer to be contacted via:
                      </label>
                      <div className="col-sm-10">
                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox1"
                            onChange={formik.handleChange}
                            checked={formik.values.checkbox1}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox1"
                          >
                            Home Phone
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox2"
                            onChange={formik.handleChange}
                            checked={formik.values.checkbox2}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox2"
                          >
                            Cell Phone
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox3"
                            onChange={formik.handleChange}
                            checked={formik.values.checkbox3}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox3"
                          >
                            Email
                          </label>
                        </div>

                        {formik.errors.atLeastOneCheckbox &&
                          formik.touched.checkbox1 &&
                          formik.touched.checkbox2 &&
                          formik.touched.checkbox3 && (
                            <div className="text-danger">
                              {formik.errors.atLeastOneCheckbox}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 fw-bold mt-4">
                      <h4 className="fw-bold"> School Info</h4>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="county">Select County:</Label>
                          <select
                            className="form-control"
                            id="county"
                            name="county"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.county}
                          >
                            <option
                              value=""
                              label="Select County"
                              disabled
                              selected
                            />
                            <option value="Sussex">Sussex</option>
                            <option value="New Castle">New Castle</option>
                            <option value="Kent">Kent</option>
                          </select>
                          {formik.errors.county && formik.touched.county && (
                            <div className="text-danger">
                              {formik.errors.county}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="schoolDistrict">
                            Select District:
                          </Label>
                          <Input
                            className="form-control"
                            type="select"
                            id="schoolDitrict"
                            name="schoolDistrict"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.schoolDistrict}
                          >
                            <option
                              value=""
                              label="Select District"
                              default
                              hidden
                            />
                            <option value="Appoquinimink School District">
                              Appoquinimink School District
                            </option>
                            <option value="Brandywine School District">
                              Brandywine School District
                            </option>
                            <option value="Caesar Rodney School District">
                              Caesar Rodney School District
                            </option>
                            <option value="Cape Henlopen School District">
                              Cape Henlopen School District
                            </option>
                            <option value="Capital School District">
                              Capital School District
                            </option>
                            <option value="Christina School District">
                              Christina School District
                            </option>
                            <option value="Colonial School District">
                              Colonial School District
                            </option>
                            <option value="Delmar School District">
                              Delmar School District
                            </option>
                            <option value="Indian River School District">
                              Indian River School District
                            </option>
                            <option value="Lake Forest School District">
                              Lake Forest School District
                            </option>
                            <option value="Laurel School District">
                              Laurel School District
                            </option>
                            <option value="Milford School District">
                              Milford School District
                            </option>
                            <option value="New Castle County Vocational-Technical School District">
                              New Castle County Vocational-Technical School
                              District
                            </option>
                            <option value="Polytech School District">
                              Polytech School District
                            </option>
                            <option value="Red Clay Consolidated School District">
                              Red Clay Consolidated School District
                            </option>
                            <option value="Seaford School District">
                              Seaford School District
                            </option>
                            <option value="Smyrna School District">
                              Smyrna School District
                            </option>
                            <option value="Sussex Technical School District">
                              Sussex Technical School District
                            </option>
                            <option value="Woodbridge School District">
                              Woodbridge School District
                            </option>
                            {/* Add more options for other school districts */}
                          </Input>
                          {formik.errors.schoolDistrict &&
                            formik.touched.schoolDistrict && (
                              <div className="text-danger">
                                {formik.errors.schoolDistrict}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/* ... (existing code) */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="schoolDistrict">Select School:</Label>
                          <Input
                            className="form-control"
                            type="select"
                            id="schoolName"
                            name="schoolName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.schoolName}
                          >
                            <option
                              value=""
                              label="Select School"
                              default
                              hidden
                            />
                            {schoolNames.map((schoolName, index) => (
                              <option key={index} value={schoolName}>
                                {schoolName}
                              </option>
                            ))}
                          </Input>
                          {formik.errors.schoolName &&
                            formik.touched.schoolName && (
                              <div className="text-danger">
                                {formik.errors.schoolName}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="principal">Principal:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="principal"
                            autoComplete="off"
                            placeholder="Enter Name"
                            onChange={formik.handleChange}
                            value={formik.values.principal}
                          />
                          {formik.errors.principal &&
                            formik.touched.principal && (
                              <div className="text-danger">
                                {formik.errors.principal}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="street">Street:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="street"
                            placeholder="School Street"
                            onChange={formik.handleChange}
                            value={formik.values.street}
                          />
                          {formik.errors.street && formik.touched.street && (
                            <div className="text-danger">
                              {formik.errors.street}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="city">City:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="School City"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                          />
                          {formik.errors.city && formik.touched.city && (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="zip">Zip:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="zip"
                            placeholder="Enter Zip Code"
                            onChange={formik.handleChange}
                            value={formik.values.zip}
                          />
                          {formik.errors.zip && formik.touched.zip && (
                            <div className="text-danger">
                              {formik.errors.zip}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="schoolPhone">School Phone:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="schoolPhone"
                            placeholder="School Phone Number"
                            onChange={formik.handleChange}
                            value={formik.values.schoolPhone}
                          />
                          {formik.errors.schoolPhone &&
                            formik.touched.schoolPhone && (
                              <div className="text-danger">
                                {formik.errors.schoolPhone}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3 fw-bold mt-4">
                      <h4 className="fw-bold">Student Info</h4>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="childName">Child Name:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="childName"
                            placeholder="Enter Child Name"
                            onChange={formik.handleChange}
                            value={formik.values.childName}
                          />
                          {formik.errors.childName &&
                            formik.touched.childName && (
                              <div className="text-danger">
                                {formik.errors.childName}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="dob">Date of birth:</Label>

                          <Input
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            type="date"
                            id="dob"
                            name="dob"
                            onChange={formik.handleChange}
                            value={formik.values.dob}
                          />
                          {formik.errors.dob && formik.touched.dob && (
                            <div className="text-danger">
                              {formik.errors.dob}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="gender">Gender:</Label>
                          <Input
                            type="select"
                            className="form-control"
                            id="gender"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                          >
                            <option value="" disabled>
                              Select Gender
                            </option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">FEMALE</option>
                            <option value="OTHER">Other</option>
                          </Input>
                          {formik.errors.gender && formik.touched.gender && (
                            <div className="text-danger">
                              {formik.errors.gender}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="pronoun">Pronouns:</Label>
                          <Input
                            type="select"
                            className="form-control"
                            id="pronoun"
                            onChange={formik.handleChange}
                            value={formik.values.pronoun}
                          >
                            <option value="">Select Pronouns</option>
                            <option value="he">He/Him</option>
                            <option value="she">She/Her</option>
                            <option value="other">OTHER</option>
                            <option value="they">They/Them</option>

                            {/* Add additional pronoun options as needed */}
                          </Input>
                          {formik.errors.pronoun && formik.touched.pronoun && (
                            <div className="text-danger">
                              {formik.errors.pronoun}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="ethnicity">Ethnicity:</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="ethnicity"
                            placeholder="Enter Ethnicity"
                            onChange={formik.handleChange}
                            value={formik.values.ethnicity}
                          />
                          {formik.errors.ethnicity &&
                            formik.touched.ethnicity && (
                              <div className="text-danger">
                                {formik.errors.ethnicity}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="grade">Grade:</Label>
                          <Input
                            type="select"
                            className="form-control"
                            id="grade"
                            onChange={formik.handleChange}
                            value={formik.values.grade}
                          >
                            <option value="" disabled selected>
                              Select Grade
                            </option>
                            <option value="Pre-K">Pre-K</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="Other">Other</option>
                          </Input>
                          {formik.errors.grade && formik.touched.grade && (
                            <div className="text-danger">
                              {formik.errors.grade}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="" style={{ marginBottom: "30px" }}>
                      <Row className="mb-3">
                        <Col sm={6}>
                          <Label
                            htmlFor="race"
                            className="col-sm-4 col-form-label"
                          >
                            Race:
                          </Label>
                          <select
                            id="race"
                            name="race"
                            className="form-select"
                            value={selectedRace}
                            onChange={handleRaceChange}
                          >
                            {raceOptions.map(option => (
                              <option key={option} value={option}>
                                {displayRaceOptions[option]}
                              </option>
                            ))}
                          </select>
                          {formik.touched.race && formik.errors.race && (
                            <div className="text-danger">
                              {formik.errors.race}
                            </div>
                          )}
                          {selectedRace === "OTHER" && (
                            <Input
                              type="text"
                              id="otherRace"
                              name="otherRace"
                              className="mt-2 form-control"
                              placeholder="Enter Other Race"
                              value={otherRace}
                              onChange={handleOtherRaceChange}
                            />
                          )}
                        </Col>
                        <Col sm={6}>
                          <Label
                            htmlFor="primaryComplaint"
                            className="col-sm-4 col-form-label"
                          >
                            Primary Complaint:
                          </Label>
                          <Input
                            id="primaryComplaint"
                            type="select"
                            name="primaryComplaint"
                            className="form-select"
                            value={selectedPrimary}
                            onChange={handlePrimary}
                          >
                            <option value="" disabled>
                              Select Primary Complaint
                            </option>
                            {primaryComplaint.map(option => (
                              <option key={option} value={option}>
                                {displayOptionsWithUnderscores[option]}
                              </option>
                            ))}
                          </Input>
                          {formik.touched.primaryComplaint &&
                            formik.errors.primaryComplaint && (
                              <div className="text-danger">
                                {formik.errors.primaryComplaint}
                              </div>
                            )}
                        </Col>
                      </Row>
                    </div>
                    <CustomSelect formik={formik} />

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-6">
                        Please check the type(s) of educational issues that
                        apply:
                      </Label>
                      <Col sm={6}>
                        <Select
                          id="education"
                          name="education"
                          isMulti
                          value={selectedEducation}
                          styles={customStyles}
                          onChange={selectedOptions => {
                            handleEducationChange(selectedOptions)
                            formik.setFieldValue("education", selectedOptions)
                            formik.handleBlur("education")
                          }}
                          options={educationOptions?.map(option => ({
                            value: option,
                            label: displayEducationOptions[option],
                          }))}
                        />
                        {formik.touched.education &&
                          formik.errors.education && (
                            <div className="text-danger">
                              {formik.errors.education}
                            </div>
                          )}

                        {selectedEducation.some(
                          option => option.value === "OTHER"
                        ) && (
                          <>
                            <Label className="col-form-label col-sm-4 ">
                              Enter Other Education Issue:
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                id="otherEducation"
                                name="otherEducation"
                                className="mb-4 form-control"
                                placeholder="Enter Other Education Issue"
                                value={formik.values.otherEducation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.otherEducation &&
                                formik.errors.otherEducation && (
                                  <div className="text-danger">
                                    {formik.errors.otherEducation}
                                  </div>
                                )}
                            </Col>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Label className="col-form-label col-sm-6">
                        Are you or your household currently participating in any
                        of the following programs? (Please select all that
                        apply)
                      </Label>
                      <Col sm={6}>
                        <Select
                          id="programs"
                          name="programs"
                          isMulti
                          value={selectedprograms}
                          styles={customStyles}
                          onChange={selectedOptions2 => {
                            handleProgramChange(selectedOptions2)
                            formik.setFieldValue("programs", selectedOptions2)
                            formik.handleBlur("programs")
                          }}
                          options={programs?.map(option => ({
                            value: option,
                            label: option,
                          }))}
                        />

                        {formik.touched.programs && formik.errors.programs && (
                          <div className="text-danger">
                            {formik.errors.programs}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Label htmlFor="commentMessage-input">
                        Please briefly state the nature of the problem you wish
                        to discuss with this office.
                      </Label>
                      <textarea
                        className={`form-control ${
                          formik.touched.commentMessage &&
                          formik.errors.commentMessage
                            ? "is-invalid"
                            : ""
                        }`}
                        id="commentMessage-input"
                        name="commentMessage"
                        value={formik.values.commentMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Your message..."
                        rows="8"
                      ></textarea>
                      {formik.touched.commentMessage &&
                      formik.errors.commentMessage ? (
                        <div className="invalid-feedback">
                          {formik.errors.commentMessage}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="howDidYouHear-input">
                        How did you hear about us?
                      </Label>
                      <Input
                        type="text"
                        className={`form-control ${
                          formik.touched.howDidYouHear &&
                          formik.errors.howDidYouHear
                            ? "is-invalid"
                            : ""
                        }`}
                        id="howDidYouHear-input"
                        name="howDidYouHear"
                        value={formik.values.howDidYouHear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter text"
                      />
                      {formik.touched.howDidYouHear &&
                      formik.errors.howDidYouHear ? (
                        <div className="invalid-feedback">
                          {formik.errors.howDidYouHear}
                        </div>
                      ) : null}
                    </div>
                    <div className="hstack gap-2 justify-content-center mb-0">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </Container>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p style={{ fontSize: "1.5rem", color: "blue", fontWeight: "bold" }}>
            Link is Expired.
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default ClientForm
