import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import advocateSaga from "./advocate/saga"
import AdminSaga from "./Admin/saga"

import advocateSaga2 from "./advocate/saga2"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import { singleCasesSaga } from "./advocate/saga2"
import { watchCasesSaga } from "./advocate/caseSaga"
import { watchAdminCasesSaga } from "./Admin/casesSaga"
import singleCasesSagaAdmin from "./Admin/saga2"
import { watchNotificationsSaga } from "./advocate/notifisage"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    //Advocate
    fork(advocateSaga),
    fork(AdminSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(advocateSaga2),
    //Advocate

    fork(singleCasesSaga),
    fork(watchCasesSaga),

    //Admin
    fork(singleCasesSagaAdmin),
    // new Admin
    fork(watchAdminCasesSaga),
    //notifications
    fork(watchNotificationsSaga),

    fork(dashboardSaga),
    fork(dashboardSaasSaga),
  ])
}
