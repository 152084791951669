import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { login, startLoading, hasError } from "../../redux/Slices/login"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
// import profile from "assets/images/profile-img.png";
import logo from "assets/images/Final.png"
import store from "../../store/index"
import { vapid } from "utils/vapid"
import { getMessaging, getToken } from "firebase/messaging"
import { messaging } from "utils/firebase"
const Login = props => {
  //meta title

  document.title = "Login | dpeopconnect"

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [save, setSave] = useState("")
  // const isLoading = useSelector(state => state.auth.startLoading())
  // const errors = useSelector(state => state.auth.error)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || "",
      email: "",
      // password: "123456" || "",
      password: "",
      fcm_token: [
        {
          device_token: save,
          device_type: "browser",
        },
      ],
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))

      // try {
      //   dispatch(startLoading()) // Set loading to true
      //   console.log("data", values.email)
      //   const token = dispatch(
      //     login({ email: values.email, password: values.password })
      //   )
      //   console.log("Token", token)
      //   navigate("/dashboard")
      // } catch (error) {
      //   console.log("error", error)
      //   navigate("/dashboard")
      //   dispatch(hasError()) // Set loading to true
      // }
    },
  })
  const [successMessage, setSuccessMessage] = useState("")

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    // error: login.error,
  }))

  // console.log(window.location.href)

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     if (permissionRequested) return // Check if permission has already been requested
  //     permissionRequested = true // Set flag to indicate permission has been requested

  //     const permission = await Notification.requestPermission()
  //     if (permission === "granted") {
  //       const token = await getToken(messaging, {
  //         vapidKey: vapid,
  //       })

  //       console.log(token)

  //       setSave(token)

  //       return token
  //     } else {
  //       alert("No granted permission for notifications")
  //     }
  //   }

  //   requestPermission()
  // }, []) // Empty dependency array ensures useEffect runs only once
  // // Call the function
  const [saveToken, setSaveToken] = useState(null)

  let permissionRequested = false
  useEffect(() => {
    const requestPermission = async () => {
      if (permissionRequested) return // Check if permission has already been requested
      permissionRequested = true // Set flag to indicate permission has been requested

      try {
        // Register the service worker
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        )
        console.log("Service Worker registered with scope:", registration.scope)

        // Wait until the service worker is active
        const activeServiceWorker = await waitForActiveServiceWorker(
          registration
        )
        console.log("Active Service Worker:", activeServiceWorker)

        // Request notification permission from the user
        const permission = await Notification.requestPermission()
        if (permission === "granted") {
          // Get the Firebase Cloud Messaging token
          const token = await getToken(messaging, {
            vapidKey: vapid,
            serviceWorkerRegistration: activeServiceWorker, // Pass the active service worker registration
          })

          console.log("FCM Token:", token)

          // Save the token to state or backend
          setSave(token)
          setSaveToken(token)
          return token
        } else {
          alert("No granted permission for notifications")
        }
      } catch (error) {
        console.error(
          "Service Worker registration or push subscription failed:",
          error
        )
      }
    }

    requestPermission()
  }, []) // Empty dependency array ensures useEffect runs only once

  // Function to wait until the service worker is active
  const waitForActiveServiceWorker = registration => {
    return new Promise((resolve, reject) => {
      if (registration.active) {
        console.log("Service Worker is already active")
        resolve(registration)
      } else if (registration.installing) {
        console.log("Service Worker is installing")
        registration.installing.addEventListener("statechange", event => {
          console.log("Service Worker state changed to:", event.target.state)
          if (event.target.state === "activated") {
            resolve(registration)
          } else if (event.target.state === "redundant") {
            reject(
              new Error("Service Worker became redundant during installation")
            )
          }
        })
      } else if (registration.waiting) {
        console.log("Service Worker is waiting")
        registration.waiting.addEventListener("statechange", event => {
          console.log("Service Worker state changed to:", event.target.state)
          if (event.target.state === "activated") {
            resolve(registration)
          } else if (event.target.state === "redundant") {
            reject(new Error("Service Worker became redundant while waiting"))
          }
        })
      } else {
        reject(new Error("No active Service Worker found"))
      }
    })
  }

  //upper firebase work
  function isMobileWindow() {
    const userAgent = navigator.userAgent
    const windowWidth = window.innerWidth
    const isMobileUserAgent =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    const isSmallWindow = windowWidth <= 768 // Adjust this threshold as needed

    return isMobileUserAgent || isSmallWindow
  }

  // if (isMobileWindow()) {
  //   console.log("User is on a mobile-sized window or a mobile device.")
  // } else {
  //   console.log("User on Browser")
  // }

  const { error } = useSelector(LoginProperties)

  // const signIn = type => {
  //   dispatch(socialLogin(type, props.router.navigate))
  // }

  //for facebook and google authentication
  // const socialResponse = type => {
  //   signIn(type)
  // }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  // Access the logindetails from the login state

  // Now you can use logindetails in your component
  // console.log('Login Details:', loginState);
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="">
                  {/* <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col> */}
                  <div className="d-flex justify-content-center align-items-center ">
                    {/* <img src={profile} alt="" className="img-fluid" /> */}
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle img-fluid"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          {/*<li className="list-inline-item">*/}
                      {/*  <TwitterLogin*/}
                      {/*    loginUrl={*/}
                      {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                      {/*    }*/}
                      {/*    onSuccess={this.twitterResponse}*/}
                      {/*    onFailure={this.onFailure}*/}
                      {/*    requestTokenUrl={*/}
                      {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                      {/*    }*/}
                      {/*    showIcon={false}*/}
                      {/*    tag={"div"}*/}
                      {/*  >*/}
                      {/*    <a*/}
                      {/*      href=""*/}
                      {/*      className="social-list-item bg-info text-white border-info"*/}
                      {/*    >*/}
                      {/*      <i className="mdi mdi-twitter"/>*/}
                      {/*    </a>*/}
                      {/*  </TwitterLogin>*/}
                      {/*</li>*/}
                      {/* <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
