import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { dropRight, isEmpty } from "lodash"
import axios from "utils/api"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap" // Import the necessary components

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  PaymentStatus2,
  PaymentStatus3,
  PaymentStatus4,
} from "../../pages/Dashboard/LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
import {
  fetchCasesRequest,
  getAdvocatesLaw,
  getSingleCases,
  getSingleCases1,
  getSingleCases2,
} from "store/advocate/actions"
import Modal from "pages/AdvocatesDashboard/Modal"
import ModalStreatagy from "pages/AdvocatesDashboard/Modal2"
import Spinners from "components/Common/Spinner"
import { useNavigate } from "react-router-dom"
// function format date

const LatestTransaction2 = props => {
  // use effect
  const { value } = props

  const handleSelectOption = (rowIndex, value) => {
    const updatedOptions = [...selectedOptions]
    updatedOptions[rowIndex] = value
    setSelectedOptions1(updatedOptions)
    toggleViewModal4(rowIndex, value)

    // Additional logic if needed
  }
  const accessToken = sessionStorage.getItem("accessToken")
  const dispatch = useDispatch()

  const [Response, setResponse] = useState("")
  const [selectoption, setSelectoption] = useState("")
  const [advocateDatas, setadvocateDatas] = useState("")

  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)

  useEffect(() => {
    // Fetch OPEN cases
    dispatch(fetchCasesRequest({ status: "OPEN" }))
  }, [dispatch])
  const [responseData, setResponseData] = useState(null)
  const singleCases1 = useSelector(state => state?.advocate?.cases)
  const storeState = useSelector(state => state)
  const valuesaved = useSelector(state => state?.advocate?.loading)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(valuesaved)

    setResponseData(singleCases1)
  }, [singleCases1, valuesaved])

  // useEffect(() => {
  //   // Function to make the API call
  //   const fetchData = async () => {
  //     try {
  //       // Get the access token from wherever it is stored (e.g., sessionStorage)
  //       const accessToken = sessionStorage.getItem("accessToken")
  //       // Make the API call with the access token in the headers
  //       const response = await axios.get("advocate/complaint/list?cases=OPEN", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       })

  //       // Set the API response data in the component's state
  //       setResponseData(response?.data?.content)
  //     } catch (error) {
  //       console.error("Error fetching data:", error)
  //       // Handle error if needed
  //     }
  //   }

  //   // Call the function to fetch data
  //   fetchData()
  // }, []) // Empty
  const [selectedOptions, setSelectedOptions] = useState([])

  const [modal1, setModal1] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [dropdownStates, setDropdownStates] = useState(
    Array(Response.length).fill(false)
  )
  const [selectedOptions1, setSelectedOptions1] = useState(
    Array(Response.length).fill("")
  )
  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }
  const toggleViewModal3 = clientData => {
    setSelectedClientData(clientData)

    setModal3(!modal3)
  }
  const toggleViewModal4 = clientData => {
    setSelectedClientData(clientData)

    setModal4(!modal4)
  }
  useEffect(() => {
    // Dispatch the action to fetch advocate data when the component mounts
    dispatch(getAdvocatesLaw())
  }, [dispatch]) // Include dependencies as needed
  const advocate1 = useSelector(state => {
    return state?.advocate?.advocate?.content
  })

  const [modal2, setModal2] = useState(false)
  // update case status by closed
  const toggleViewModal2 = async clientData => {
    try {
      // Fetch data based on clientData.caseId
      const caseId = clientData?._id

      // Check if caseId is valid before making the API call
      if (caseId) {
        const accessToken = sessionStorage.getItem("accessToken")
        const response = await axios.get(
          `/advocate/complaint/close/${caseId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )

        // Assuming you have actions to handle the API response

        // Now you can set the modal state or do any other logic with the fetched data
      } else {
        // Handle the case where caseId is not valid
        console.error("Invalid caseId")
      }
    } catch (error) {
      // Handle errors if the API call fails
      console.error("Error fetching data:", error)
    }
  }
  function capitalizeFirstLetter(inputString) {
    if (inputString == "OPEN")
      return (
        inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
      )
    else return ""
  }

  // Example usage:
  let outputText = capitalizeFirstLetter(value)
  //nav
  const navigate = useNavigate()
  const handleLinkClick = (cellValue, data) => {
    // Navigate to the desired URL when the link is clicked, passing selectedClientData as state
    navigate(`/case-description/${cellValue}`, { state: { rowData: data } })
  }
  const toggleDropdown = rowIndex => {
    const updatedStates = [...dropdownStates]
    updatedStates[rowIndex] = !updatedStates[rowIndex]
    setDropdownStates(updatedStates)
  }

  useEffect(() => {
    const fetchAdvocateList = async () => {
      const accessToken = sessionStorage.getItem("accessToken")
      try {
        const response = await axios.get("/admin/advocate/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })

        const variable = response?.data?.content

        const firstNamesWithIds = variable?.map(item => ({
          id: item?._id,
          firstName: item?.first_name,
        }))
        setDropdownOptions(firstNamesWithIds)

        const advocateId = variable?.map(item => item?._id)
        setid(advocateId)
        setadvocateDatas(variable)
      } catch (error) {
        console.error("Error fetching advocate list:", error)
      }
    }

    const fetchData = async () => {
      setLoadingOptions(true)
      await fetchAdvocateList()
      // await assignCaseToAdvocate();
      setLoadingOptions(false)
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once

  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"
      case "isClosed":
        return "Update Status"

      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      case "isClosed":
        return "Updated Status"
      case "referral":
        return "Urgency"
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!singleCases1 || singleCases1.length === 0) return []

    const keysToDisplay = [
      "caseId",
      "date",
      "child_name",
      "isClosed",
      "referral",
    ]

    const columns = keysToDisplay.map(key => ({
      Header: getCustomHeaderName(key),
      accessor: key, // Make sure accessor is defined for each column
      disableFilters: true,
      Cell: cellProps => {
        if (key === "caseId") {
          // Render Case ID as a button or link, or any other UI element you prefer
          return (
            <a
              className="link-primary override-text-decoration"
              style={{
                color: "blue",
                textDecoration: "underline !important",
              }}
              onClick={() =>
                handleLinkClick(cellProps.value, cellProps.row.original)
              }
            >
              {cellProps.value}
            </a>
          )
        }
        if (key === "date" && cellProps.row.original[key]) {
          const [datePart, timePart] = cellProps.row.original[key].split("T")
          const [year, month, day] = datePart.split("-")
          return <span>{`${day}-${month}-${year}`}</span>
        } else {
          return <span>{cellProps.row.original[key]}</span>
        }
      },
    }))

    // columns.push({
    //   Header: "Details",
    //   disableFilters: true,
    //   accessor: "view",
    //   Cell: cellProps => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={() => toggleViewModal(cellProps.row.original)}
    //     >
    //       View Details
    //     </Button>
    //   ),
    // })

    if (value == "OPEN") {
      // columns.push({
      //   Header: "Case Active",
      //   disableFilters: true,
      //   accessor: "view2",
      //   Cell: cellProps => (
      //     <Button
      //       type="button"
      //       color="primary"
      //       className="btn-sm btn-rounded"
      //       onClick={() => toggleViewModal2(cellProps.row.original)}
      //     >
      //       Open Case
      //     </Button>
      //   ),
      // });
      columns.push({
        Header: "Status",
        disableFilters: true,
        accessor: "view0",
        Cell: cellProps => {
          const rowIndex = cellProps.row.index
          return (
            <Dropdown
              isOpen={dropdownStates[rowIndex]}
              toggle={() => toggleDropdown(rowIndex)}
              className="mt-4 mt-sm-0"
            >
              <DropdownToggle className="" color="primary" caret>
                {selectedOptions1[rowIndex] || "Select Value"}{" "}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption(cellProps.row.original, "Strategy lab")
                  }
                >
                  Strategy lab
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    handleSelectOption(cellProps.row.original, "Others")
                  }
                >
                  Others
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        },
      })

      columns.push({
        Header: "Close Case",
        disableFilters: true,
        accessor: "view00",
        Cell: cellProps => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => toggleViewModal3(cellProps.row.original)}
          >
            Close Case
          </Button>
        ),
      })
      // columns.push({
      //   Header: "StatusCase",
      //   accessor: "StatusCase",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     if (value === 'OPEN') {
      //       // Render PaymentStatus3 if value is OPEN
      //       return <PaymentStatus3 {...cellProps} />;
      //     } else {
      //       // Otherwise, render PaymentStatus2
      //       return <PaymentStatus2 {...cellProps} />;
      //     }
      //   },
      // });
    }

    return columns
  }, [singleCases1, value, dropdownStates, selectedOptions1])
  useEffect(() => {
    const dropdownOptions = advocate1?.map(item => ({
      value: item?._id,
      label: `${item?.first_name} ${item?.last_name}`,
    }))

    setDropdownOptions(dropdownOptions)

    const advocateIds = advocate1?.map(item => item?._id)
    setid(advocateIds)
    setadvocateDatas(advocate1)

    console.error("Advocate data is not in the expected format:", advocate1)
    // You might want to set default values or handle this case according to your needs
  }, [advocate1])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />
      <Modal
        isOpen={modal3}
        selectOption={handleSelectOption}
        toggle={toggleViewModal3}
        toggleViewModal2={toggleViewModal2}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
      />
      <ModalStreatagy
        isOpen={modal4}
        value={true}
        Id={Id}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal4}
        toggleViewModal2={toggleViewModal2}
      />
      <ModalData
        isOpen={modal2}
        toggle={toggleViewModal2}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        Id={Id}
        selectOption={handleSelectOption}
        advocateDatas={advocateDatas}
      />

      {singleCases1 && valuesaved == false ? (
        singleCases1.length > 0 ? (
          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">{outputText} Cases</div>
              <TableContainer
                columns={dynamicColumns}
                data={singleCases1} // Pass your custom data here
                isGlobalFilter={true}
                isAddOptions={false}
                order="asc"
                isPagination={true}
                customPageSize={10}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            </CardBody>
          </Card>
        ) : (
          <div className="alert alert-warning mt-3">
            <p className="text-primary mb-0">No Cases found</p>
          </div>
        )
      ) : (
        // Loader component or loading message
        <Spinners setLoading={setLoading} />
      )}
    </React.Fragment>
  )
}

LatestTransaction2.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTransaction2)
