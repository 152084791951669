import React from "react"
import { useLocation, useParams } from "react-router-dom"
import EnForm from "./EnForm"
import SpForm from "./SpForm"

const LanguageFormComponent = () => {
  // const { lang, caseId } = useParams();
  // console.log("🚀 ~ LanguageFormComponent ~ lang, caseId:", lang, caseId)
  const location = useLocation();

  // Use URLSearchParams to extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang');
  const caseId = queryParams.get('caseId');

  return (
    <div>
    {lang && caseId ? (
      lang === "en" ? (
        <EnForm lang={lang} caseId={caseId} />
      ) : (
        <SpForm lang={lang} caseId={caseId} />
      )
    ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p style={{ fontSize: "1.5rem", color: "blue", fontWeight: "bold" }}>
          Not Allowed
        </p>
      </div>
    )}
  </div>
  
  )
}

export default LanguageFormComponent
