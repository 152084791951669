import React from "react"

import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
//Import Breadcrumb

// import chartJs
import { useSelector } from "react-redux"
import BarChart from "components/AllCharts/chartjs/barchart"
import DountChart from "components/AllCharts/chartjs/dountchart"
const ChartjsChart = ({val,open,close}) => {
  const chartHeight = 350 // Set the desired height for both charts
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <Row className="justify-content-center">
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{val}</h5>
                  <p className="text-muted text-truncate">Cases</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{open}</h5>
                  <p className="text-muted text-truncate">Open</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{close}</h5>
                  <p className="text-muted text-truncate">Close</p>
                </div>
              </Col>
            </Row>
            <BarChart
              dataColors='["--bs-success", "#ebeff2"]'
              height={chartHeight}
              open={open}
              close={close}
            />{" "}
          </CardBody>
        </Card>
      </Col>

      <Col lg={6}>
        <Card>
          <CardBody style={{padding:'37px'}}>
            <Row className="justify-content-center">
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{val}</h5>
                  <p className="text-muted text-truncate">Cases</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{open}</h5>
                  <p className="text-muted text-truncate">Open</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <h5 className="mb-0">{close}</h5>
                  <p className="text-muted text-truncate">Close</p>
                </div>
              </Col>
            </Row>

            <DountChart dataColors='["--bs-primary", "#ebeff2"]' open={open} close={close}/>
          </CardBody>
        </Card>
      </Col>
      
    </React.Fragment>
  )
}

export default ChartjsChart
