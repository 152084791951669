// actions.js

import {
  GET_ADVOCATE,
  GET_ADVOCATE_SUCCESS,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES,
  GET_SINGLE_CASES_SUCCESS,
  GET_SINGLE_CASES_FAIL,
  GET_SINGLE_CASES1,
  GET_SINGLE_CASES_SUCCESS1,
  GET_SINGLE_CASES_FAIL1,
  GET_SINGLE_CASES2,
  GET_SINGLE_CASES_SUCCESS2,
  GET_SINGLE_CASES_FAIL2,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from "./actionTypes"

export const getAdvocatesLaw = () => ({
  type: GET_ADVOCATE,
})

export const getAdvocateSuccess = advocate => ({
  type: GET_ADVOCATE_SUCCESS,
  payload: advocate,
})

export const getAdvocateFail = error => ({
  type: GET_ADVOCATE_FAIL,
  payload: error,
})
// single cases
export const getSingleCases = isClosed => ({
  type: GET_SINGLE_CASES,
  payload: isClosed,
})

export const getSingleCasesSuccess = cases => ({
  type: GET_SINGLE_CASES_SUCCESS,
  payload: cases,
})

export const getSingleCasesFail = error => ({
  type: GET_SINGLE_CASES_FAIL,
  payload: error,
})
export const getSingleCases1 = isClosed => ({
  type: GET_SINGLE_CASES1,
  payload: isClosed,
})

export const getSingleCasesSuccess1 = cases => ({
  type: GET_SINGLE_CASES_SUCCESS1,
  payload: cases,
})

export const getSingleCasesFail1 = error => ({
  type: GET_SINGLE_CASES_FAIL1,
  payload: error,
})
export const getSingleCases2 = isClosed => ({
  type: GET_SINGLE_CASES2,
  payload: isClosed,
})

export const getSingleCasesSuccess2 = cases => ({
  type: GET_SINGLE_CASES_SUCCESS2,
  payload: cases,
})

export const getSingleCasesFail2 = error => ({
  type: GET_SINGLE_CASES_FAIL2,
  payload: error,
})

// cond
import * as types from "./actionTypes"

export const fetchCasesRequest = status => ({
  type: types.FETCH_CASES_REQUEST,
  payload: status,
})

export const fetchCasesSuccess = cases => ({
  type: types.FETCH_CASES_SUCCESS,
  payload: cases,
})

export const fetchCasesFailure = error => ({
  type: types.FETCH_CASES_FAILURE,
  payload: error,
})

///notifications
export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
})

export const fetchNotificationsSuccess = notifications => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const fetchNotificationsFailure = error => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
})
export const setNotificationSeenRequest = (notificationId) => ({
  type: types.SET_NOTIFICATION_SEEN_REQUEST,
  payload: { notificationId },
});