import { initializeApp } from "firebase/app"
import { getMessaging, onMessage } from "firebase/messaging"
const firebaseConfig = {
  apiKey: "AIzaSyArc01pMmzP87OlomRH1Os58oOfhoMHkyE",
  authDomain: "dpeop-push-notification.firebaseapp.com",
  projectId: "dpeop-push-notification",
  storageBucket: "dpeop-push-notification.appspot.com",
  messagingSenderId: "364594062494",
  appId: "1:364594062494:web:56e0ca8c436b6d530b878f",
  measurementId: "G-EDC3ZSZQYX",
}
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export { app, messaging, onMessage }
