import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { useDispatch, useSelector } from "react-redux"
import axios from "utils/api"
import { Base_API_KEY } from "config-global"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const Role = sessionStorage.getItem("role")
  const loginState = useSelector(
    state => state.Login?.logindetails?.content?.customer?.first_name
  )
  const loginState2 = useSelector(
    state => state.Login?.logindetails?.content?.customer?.last_name
  )
  const fullName = `${loginState} ${loginState2}`

  // Access the logindetails from the login state

  // Now you can use logindetails in your component
  const [isRole, setisRole] = useState("")

  const dispatch = useDispatch()
  const [username, setusername] = useState(loginState)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'YOUR_TOKEN_HERE' with your actual token
        const token = sessionStorage.getItem("accessToken")

        setisRole(Role)

        // axios request configuration
        const axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }

        // Make the API request
        const response = await axios.get("/auth/ping", axiosConfig)
        console.log(
          "🚀 ~ fetchData ~ response?.data?.content:",
          response?.data?.content
        )
        // Handle successful response
        setResp(response?.data?.content)
        if (Role == "ADVOCATE") {
          setisDashboardLawyers(true)
          dispatch(clearTopbarTheme("light"))
          dispatch(clearSidebarTheme("light"))
        } else if (Role != "ADVOCATE") {
          dispatch(clearTopbarTheme("dark"))
          dispatch(clearSidebarTheme("dark"))
        }
      } catch (error) {
        // Handle error
        console.error("Error:", error)
      }
    }

    fetchData() // Call the async function immediately
  }, [dispatch, isRole])

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear()

    // You can also redirect the user to the logout page if needed
    // window.location.href = "/logout";
  }
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")
    const Role = sessionStorage.getItem("role")

    // axios request configuration
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    // Make a GET request using Axios within useEffect
    if (Role != "ADVOCATE") {
      axios
        .get("admin/profile", axiosConfig)
        .then(response => {
          // Handle the successful response here
          setProfileData(response?.data?.content)
        })
        .catch(error => {
          // Handle errors here
          console.error("Error:", error)
        })
    } else {
      axios
        .get("advocate/profile", axiosConfig)
        .then(response => {
          // Handle the successful response here
          setProfileData(response?.data?.content)
        })
        .catch(error => {
          // Handle errors here
          console.error("Error:", error)
        })
    }
  }, []) // The empty dependency array ensures that this effect runs once, similar to componentDidMount

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])
  const username1 = sessionStorage.getItem("role")
  const profileFirstName = profileData?.first_name
  const profileLastName = profileData?.last_name
  const profileImage = profileData?.profileImage

  const Name = `${profileFirstName} ${profileLastName}`
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={`${Base_API_KEY}/profile/${profileData?.profile_picture}`}
            alt="Head"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{Name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/" className="dropdown-item" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
