import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/Final.png"
import Select from "react-select"
import { useClasssificationContext } from "Context/ClassificationContext"

import { Container, Col, Label, Input, Row } from "reactstrap"
import { useLinkContext } from "../../Context/LinkContext"
import { Link, useParams } from "react-router-dom"
import { useFormik } from "formik"

import axios from "utils/api"
import { useSchoolContext } from "Context/SchoolContext"
import AdvancedCaseModal from "./AdnancedCaseModal"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"

const AdvancedForm = () => {
  //meta title
  document.title = "Client Intake | dpeopconnect"
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  }

  //use State //use context
  const { linkId, setLinkId } = useLinkContext()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState(null)
  const [modal1, setModal1] = useState(false)
  const [selectedClientData, setSelectedClientData] = useState([])
  const [DataValue, setDatavalue] = useState([{}])
  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [selectedRace, setSelectedRace] = useState("")
  const [selectedprograms, setselectedprograms] = useState([])

  const [otherRace, setOtherRace] = useState("")
  const [selectedEducation, setSelectedEducation] = useState([])
  const [otherEducation, setOtherEducation] = useState("")
  const { linkId: routeLinkId } = useParams() // Retrieve linkId from route params
  //use effect
  const [loading, setLoading] = useState(true)
  const [myDoc, setMyDoc] = useState(null)

  const toggleViewModal = clientData => {
    setModal1(!modal1)
  }
  const { SchoolDistrict, schoolNames, setSelectedDistrict } =
    useSchoolContext()

  const { Classsification } = useClasssificationContext()

  // validation schema
  //
  const raceOptions = [
    "RACE",
    "AFRICAN_AMERICAN",
    "CAUCASIAN",
    "HISPANIC_LATINO",
    "ASIAN",
    "OTHER",
  ]
  const displayRaceOptions = {
    RACE: "Race",
    AFRICAN_AMERICAN: "African American",
    CAUCASIAN: "Caucasian",
    HISPANIC_LATINO: "Hispanic/Latino",
    ASIAN: "Asian",
    OTHER: "Other",
  }

  const educationOptions = [
    "SPECIAL_EDUCATION",
    "GENERAL_EDUCATION",
    "MCKINNEY_VENTO",
    "BUYYING",
    "EP",
    "PLAN_504",
    "DISCIPLINE",
    "OTHER",
  ]
  const programs = [
    "Medicaid",
    "SNAP (Food Stamps)",
    "TANF",
    "Free or Reduced Lunch Program",
    "Reduced Lunch Program",
    "None",
    "Prefer not to say",
  ]
  const displayEducationOptions = {
    SPECIAL_EDUCATION: "Special Education",
    GENERAL_EDUCATION: "General Education",
    MCKINNEY_VENTO: "McKinney Vento",
    BUYYING: "Bullying",
    EP: "IEP",
    PLAN_504: "Plan 504",
    DISCIPLINE: "Discipline",
    OTHER: "Other",
  }
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  // Function to toggle button based on form values
  const toggleButton = () => {
    // Check if any field in formik.values has a value
    const hasValue = Object.values(formik.values).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0
      } else if (typeof value === "string") {
        return value.trim() !== ""
      } else if (typeof value === "boolean") {
        return value
      }
      return false
    })

    // Enable or disable the button based on the result
    setIsButtonDisabled(!hasValue)
  }
  const handleRaceChange = event => {
    const selectedValue = event.target.value
    setSelectedRace(selectedValue)

    if (selectedValue === "OTHER") {
      formik.setFieldValue("race", "") // Reset the race field value
    } else {
      formik.setFieldValue("race", selectedValue)
    }
  }
  const handleProgramChange = selectedOptions2 => {
    setselectedprograms(selectedOptions2)
  }
  const handleOtherRaceChange = event => {
    setOtherRace(event.target.value)
    formik.setFieldValue("race", "OTHER: " + event.target.value)
  }

  const handleCheckboxChange = name => {
    formik.setFieldTouched(name, false)
    formik.setFieldValue(name, !formik.values[name])
  }

  const handleEducationChange = selectedOptions => {
    setSelectedEducation(selectedOptions)
  }
  const isOtherSelected = selectedEducation.some(
    option => option.value === "OTHER"
  )

  const handleOtherEducationChange = event => {
    setOtherEducation(event.target.value)
    formik.setFieldValue("education", ["OTHER: " + event.target.value])
  }

  const initialValues = {
    parentName: "",
    classification: "", // Initial value for the classification dropdown
    programs: [],
    parentName2: "",
    schoolName: "",
    schoolDistrict: "",
    otherEducation: "",
    principal: "",
    education: [],
    childName: "",
    StartDate: "",
    lastDate: "",
    county: "",
    gender: "",
    grade: "",
    pronoun: "",
    ethnicity: "",
    homeAddress: "",
    homePhone: "",
    cellPhone: "",
    street: "",
    city: "",
    zip: "",
    schoolPhone: "",
    email: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    specialEducation: false,
    generalEducation: false,
    mckinneyVento: false,
    bullying: false,
    iep: false,
    plan504: false,
    discipline: false,
    commentMessage: "",
    race: "",
    howDidYouHear: "",
    uplodeDoc: "",

    // Add initial values for other fields
  }
  const handleSubmit = async () => {
    try {
      const selectedEducationValues = formik.values.education
        .map(option => {
          if (option.value === "OTHER") {
            return formik.values.otherEducation // Include the value of 'otherEducation' for 'OTHER' option
          }
          return option.value // Include the value for other education options
        })
        .filter(value => value !== null && value !== undefined && value !== "") // Remove null, undefined, and empty string values

      const selectedProgramValues = formik.values.programs
        .map(option => {
          return option.value // Include the value for other education options
        })
        .filter(value => value !== null && value !== undefined && value !== "") // Remove null, undefined, and empty string values
      const formattedSchoolName = formik.values.schoolName.replace(/\+/g, " ")

      // Convert the form values to query parameters
      const queryParams = new URLSearchParams()
      if (formik.values.StartDate)
        queryParams.append("classification", formik.values.lastDate)
      if (formik.values.classification)
        queryParams.append("classification", formik.values.classification)
      if (formik.values.lastDate)
        queryParams.append("from", formik.values.StartDate)

      if (formik.values.gender !== undefined && formik.values.gender !== "")
        queryParams.append("gender", formik.values.gender)
      if (
        formik.values.schoolDistrict !== undefined &&
        formik.values.schoolDistrict !== ""
      )
        queryParams.append("district", formik.values.schoolDistrict)
      if (formik.values.pronoun !== undefined && formik.values.pronoun !== "")
        queryParams.append("pronouns", formik.values.pronoun)
      if (formik.values.other !== undefined && formik.values.other !== "")
        queryParams.append("other_region", formik.values.other)
      if (formik.values.race !== undefined && formik.values.race !== "")
        queryParams.append("region", formik.values.race)
      if (
        formik.values.schoolName !== undefined &&
        formik.values.schoolName !== ""
      )
        queryParams.append("school_name", formik.values.schoolName)
      if (formik.values.others !== undefined && formik.values.others !== "")
        queryParams.append("other_education", formik.values.others)
      if (selectedEducationValues.length > 0)
        queryParams.append("education", selectedEducationValues.join(","))
      if (selectedProgramValues.length > 0)
        queryParams.append("houseHoldProgram", selectedProgramValues.join(","))
      if (formik.values.county !== undefined && formik.values.county !== "")
        queryParams.append("county", formik.values.county)
      if (formik.values.grade !== undefined && formik.values.grade !== "")
        queryParams.append("student_grade", formik.values.grade)
      if (
        formik.values.checkbox1 !== null &&
        formik.values.checkbox1 !== undefined &&
        formik.values.checkbox1 !== false
      ) {
        queryParams.append("home_phone", formik.values.checkbox1)
      }
      if (
        formik.values.checkbox2 !== null &&
        formik.values.checkbox2 !== undefined &&
        formik.values.checkbox2 !== false
      ) {
        queryParams.append("cell_phone", formik.values.checkbox2)
      }
      if (
        formik.values.checkbox3 !== null &&
        formik.values.checkbox3 !== undefined &&
        formik.values.checkbox3 !== false
      ) {
        queryParams.append("emailCon", formik.values.checkbox3)
      }

      // Rest of your form submission logic
      const accessToken = sessionStorage.getItem("accessToken")
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }

      // Make sure to encode the query parameters properly
      const queryString = queryParams.toString()

      // Send the request with query parameters
      const response = await axios.get(
        `/admin/searchCase?${queryString}`,
        { headers: headers } // Pass the headers directly here
      )
      if (response?.data?.content.length > 0) {
        setSelectedClientData(response.data.content)
        toggleViewModal()
      } else {
        toast.info("No data Found")
      }

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error)
    }
  }

  const formik = useFormik({
    initialValues,
  })

  const saved = apiResponse?.success
  //school Name data
  useEffect(() => {
    setSelectedDistrict(formik.values.schoolDistrict) // Set selectedDistrict when schoolDistrict changes
  }, [formik.values.schoolDistrict])
  useEffect(() => {
    toggleButton()
  }, [formik.values])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="container-custom">
          {/* ... other form elements ... */}
          <div className=" d-flex justify-content-center fw-bold">
            <strong className="fs-4">Advanced Report</strong>
          </div>

          <div className="row mb-3">
            <label className="col-form-label col-sm-4">
              I Prefer to be contacted via:
            </label>
            <div className="col-sm-10">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="checkbox1"
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="checkbox1">
                  Home Phone
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="checkbox2"
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="checkbox2">
                  Cell Phone
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="checkbox3"
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="checkbox3">
                  Email
                </label>
              </div>

              {formik.errors.atLeastOneCheckbox &&
                formik.touched.checkbox1 &&
                formik.touched.checkbox2 &&
                formik.touched.checkbox3 && (
                  <div className="text-danger">
                    {formik.errors.atLeastOneCheckbox}
                  </div>
                )}
            </div>
          </div>
          <Row>
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="county">Select County:</Label>
                <select
                  className="form-control"
                  id="county"
                  name="county"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.county}
                >
                  <option value="" label="Select County" disabled selected />
                  <option value="Sussex">Sussex</option>
                  <option value="New Castle">New Castle</option>
                  <option value="Kent">Kent</option>
                </select>
                {formik.errors.county && formik.touched.county && (
                  <div className="text-danger">{formik.errors.county}</div>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="schoolDistrict">Select District:</Label>
                <Input
                  className="form-control"
                  type="select"
                  id="schoolDistrict"
                  name="schoolDistrict"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.schoolDistrict}
                >
                  <option value="" label="Select District" default hidden />
                  <option value="Appoquinimink School District">
                    Appoquinimink School District
                  </option>
                  <option value="Brandywine School District">
                    Brandywine School District
                  </option>
                  <option value="Caesar Rodney School District">
                    Caesar Rodney School District
                  </option>
                  <option value="Cape Henlopen School District">
                    Cape Henlopen School District
                  </option>
                  <option value="Capital School District">
                    Capital School District
                  </option>
                  <option value="Christina School District">
                    Christina School District
                  </option>
                  <option value="Colonial School District">
                    Colonial School District
                  </option>
                  <option value="Delmar School District">
                    Delmar School District
                  </option>
                  <option value="Indian River School District">
                    Indian River School District
                  </option>
                  <option value="Lake Forest School District">
                    Lake Forest School District
                  </option>
                  <option value="Laurel School District">
                    Laurel School District
                  </option>
                  <option value="Milford School District">
                    Milford School District
                  </option>
                  <option value="New Castle County Vocational-Technical School District">
                    New Castle County Vocational-Technical School District
                  </option>
                  <option value="Polytech School District">
                    Polytech School District
                  </option>
                  <option value="Red Clay Consolidated School District">
                    Red Clay Consolidated School District
                  </option>
                  <option value="Seaford School District">
                    Seaford School District
                  </option>
                  <option value="Smyrna School District">
                    Smyrna School District
                  </option>
                  <option value="Sussex Technical School District">
                    Sussex Technical School District
                  </option>
                  <option value="Woodbridge School District">
                    Woodbridge School District
                  </option>
                  {/* Add more options for other school districts */}
                </Input>
                {formik.errors.schoolDistrict &&
                  formik.touched.schoolDistrict && (
                    <div className="text-danger">
                      {formik.errors.schoolDistrict}
                    </div>
                  )}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="schoolName">Select School:</Label>
                <Input
                  className="form-control"
                  type="select"
                  id="schoolName"
                  name="schoolName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.schoolName}
                >
                  <option value="" label="Select School" default hidden />
                  {schoolNames.map((schoolName, index) => (
                    <option key={index} value={schoolName}>
                      {schoolName}
                    </option>
                  ))}
                </Input>
                {formik.errors.schoolName && formik.touched.schoolName && (
                  <div className="text-danger">{formik.errors.schoolName}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row></Row>

          <div className="mb-3 fw-bold">
            <strong>Student Info:</strong>
          </div>
          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="StartDate">Start Date:</Label>

                <Input
                  className="form-control"
                  placeholder="MM/DD/YYYY"
                  type="date"
                  id="StartDate"
                  name="StartDate"
                  onChange={formik.handleChange}
                  value={formik.values.StartDate}
                />
                {formik.errors.StartDate && formik.touched.StartDate && (
                  <div className="text-danger">{formik.errors.StartDate}</div>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="lastDate">EndDate:</Label>

                <Input
                  className="form-control"
                  placeholder="MM/DD/YYYY"
                  type="date"
                  id="lastDate"
                  name="lastDate"
                  onChange={formik.handleChange}
                  value={formik.values.lastDate}
                />
                {formik.errors.lastDate && formik.touched.lastDate && (
                  <div className="text-danger">{formik.errors.lastDate}</div>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="gender">Gender:</Label>
                <Input
                  type="select"
                  className="form-control"
                  id="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">Other</option>
                </Input>
                {formik.errors.gender && formik.touched.gender && (
                  <div className="text-danger">{formik.errors.gender}</div>
                )}
              </div>
            </Col>

            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="pronoun">Pronouns:</Label>
                <Input
                  type="select"
                  className="form-control"
                  id="pronoun"
                  onChange={formik.handleChange}
                  value={formik.values.pronoun}
                >
                  <option value="">Select Pronouns</option>
                  <option value="he">He/Him</option>
                  <option value="she">She/Her</option>
                  <option value="other">OTHER</option>
                  <option value="they">They/Them</option>

                  {/* Add additional pronoun options as needed */}
                </Input>
                {formik.errors.pronoun && formik.touched.pronoun && (
                  <div className="text-danger">{formik.errors.pronoun}</div>
                )}
              </div>
            </Col>

            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="grade">Grade:</Label>
                <Input
                  type="select"
                  className="form-control"
                  id="grade"
                  onChange={formik.handleChange}
                  value={formik.values.grade}
                >
                  <option value="" disabled selected>
                    Select Grade
                  </option>
                  <option value="Pre-K">Pre-K</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="Other">Other</option>
                </Input>
                {formik.errors.grade && formik.touched.grade && (
                  <div className="text-danger">{formik.errors.grade}</div>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <Row className="">
                <Label htmlFor="race" className="">
                  Race:
                </Label>
              </Row>

              <select
                id="race"
                name="race"
                className="form-select"
                value={selectedRace}
                onChange={handleRaceChange}
              >
                {raceOptions.map(option => (
                  <option key={option} value={option}>
                    {displayRaceOptions[option]}
                  </option>
                ))}
              </select>
              {selectedRace === "OTHER" && (
                <Input
                  type="text"
                  id="otherRace"
                  name="otherRace"
                  className="mt-2 form-control"
                  placeholder="Enter Other Race"
                  value={otherRace}
                  onChange={handleOtherRaceChange}
                />
              )}
            </Col>
          </Row>

          <>
            <Row className="mb-3 d-flex flex-column" lg={12}>
              <Col lg={12}>
                <Label className="">
                  Please check the type(s) of educational issues that apply:
                </Label>
              </Col>
              <Col lg={8}>
                <Select
                  id="education"
                  name="education"
                  isMulti
                  value={selectedEducation}
                  styles={customStyles}
                  onChange={selectedOptions => {
                    handleEducationChange(selectedOptions)
                    formik.setFieldValue("education", selectedOptions)
                    formik.handleBlur("education")
                  }}
                  options={educationOptions?.map(option => ({
                    value: option,
                    label: displayEducationOptions[option],
                  }))}
                />
                {formik.touched.education && formik.errors.education && (
                  <div className="text-danger">{formik.errors.education}</div>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              {selectedEducation.some(option => option.value === "OTHER") && (
                <>
                  <Label className="col-lg-3">
                    Enter Other Education Issue:
                  </Label>
                  <Col lg={6}>
                    <Input
                      type="text"
                      id="otherEducation"
                      name="otherEducation"
                      className="mb-4 form-control"
                      placeholder="Enter Other Education Issue"
                      value={formik.values.otherEducation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.otherEducation &&
                      formik.errors.otherEducation && (
                        <div className="text-danger">
                          {formik.errors.otherEducation}
                        </div>
                      )}
                  </Col>
                </>
              )}
            </Row>
          </>

          <Row className="mb-3 d-flex flex-column" lg={6}>
            <Col lg={8}>
              <Label className="">
                Are you or your household currently participating in any of the
                following programs? (Please select all that apply)
              </Label>
            </Col>
            <Col lg={8}>
              <Select
                id="programs"
                name="programs"
                isMulti
                value={selectedprograms}
                styles={customStyles}
                onChange={selectedOptions2 => {
                  handleProgramChange(selectedOptions2)
                  formik.setFieldValue("programs", selectedOptions2)
                  formik.handleBlur("programs")
                }}
                options={programs?.map(option => ({
                  value: option,
                  label: option,
                }))}
              />

              {formik.touched.programs && formik.errors.programs && (
                <div className="text-danger">{formik.errors.programs}</div>
              )}
            </Col>
          </Row>
          <Row className="mb-3 d-flex flex-column" lg={6}>
            <Col lg={8}>
              <label htmlFor="classification" className="form-label">
                Classification:
              </label>
              <select
                className={`form-control ${
                  formik.errors.classification && formik.touched.classification
                    ? "is-invalid"
                    : ""
                }`}
                id="classification"
                name="classification"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.classification}
              >
                <option value="">Select Classification</option>
                {Classsification.map((group, index) => (
                  <optgroup label={group.label} key={index}>
                    {group.options.map((option, idx) => (
                      <option value={option} key={idx}>
                        {option}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              {formik.errors.classification &&
                formik.touched.classification && (
                  <div className="invalid-feedback">
                    {formik.errors.classification}
                  </div>
                )}
            </Col>
          </Row>

          <div
            className="hstack gap-2 justify-content-start mb-0"
            style={{ paddingBottom: "150px", marginTop: "30px" }}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              Generate Advance Report
            </button>
          </div>

          <ToastContainer />

          <AdvancedCaseModal
            isOpen={modal1}
            selectedClientData={selectedClientData}
            toggle={toggleViewModal}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdvancedForm
