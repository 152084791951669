// sagas.js
import { takeLatest, put, call } from "redux-saga/effects"
import * as types from "./actionTypes"
import axios from "utils/api"
import { fetchNotificationsSuccess, fetchNotificationsFailure } from "./actions"

function* fetchNotificationsSaga() {
  try {
    const accessToken = sessionStorage.getItem("accessToken") // Adjust as needed
    const response = yield call(
      axios.get,
      `advocate/notification`, // Adjust the endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    yield put(fetchNotificationsSuccess(response.data.content))
    console.log(
      "🚀 ~ function*fetchNotificationsSaga ~ response.data.content:",
      response.data.content
    )
  } catch (error) {
    yield put(fetchNotificationsFailure(error.message))
  }
}
function* setNotificationSeenSaga(action) {
  try {
    const accessToken = sessionStorage.getItem("accessToken") // Adjust as needed
    const { notificationId } = action.payload
    yield call(
      axios.put,
      `/advocate/setSeen/${notificationId}`, // Adjust the endpoint as needed
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    // After successfully setting the notification as seen, fetch notifications again
    yield put(fetchNotificationsRequest())
  } catch (error) {
    console.error("Error setting notification as seen:", error)
    // Optionally handle the error by dispatching an action
  }
}
export function* watchNotificationsSaga() {
  yield takeLatest(types.FETCH_NOTIFICATIONS_REQUEST, fetchNotificationsSaga)
  yield takeLatest(types.SET_NOTIFICATION_SEEN_REQUEST, setNotificationSeenSaga)
}
