import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import logo from "../../assets/images/Final.png"
import Select from "react-select"

import {
  Container,
  Button,
  Col,
  Label,
  Input,
  Form,
  Row,
  ToastHeader,
  ToastBody,
  CardBody,
  Card,
  Toast,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLinkContext } from "../../Context/LinkContext"
import { Link, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import * as Yup from "yup"
import axios from "utils/api"
import { getSingleCases } from "store/Admin/actions"
import { useDateContext } from "Context/DateContext"
import { useSchoolContext } from "Context/SchoolContext"
// class contxt
import { useClasssificationContext } from "Context/ClassificationContext"
import { init } from "i18next"
import CustomSelect from "./Form/CustomSelect"

const Viewdetails = () => {
  //meta title
  document.title = "Client Intake | dpeopconnect"
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  }

  //use State //use context
  const { linkId, setLinkId } = useLinkContext()
  const [isFormInitialized, setFormInitialized] = useState(false)

  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState(null)
  const [rowData, setRowData] = useState(null)
  const location = useLocation()

  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [selectedRace, setSelectedRace] = useState(rowData?.region)
  const [otherRace, setOtherRace] = useState("")
  const [selectedprograms, setselectedprograms] = useState([])

  const [selectedEducation, setSelectedEducation] = useState(
    rowData?.education || []
  )
  const [myDoc, setMyDoc] = useState({ uplodeDoc: [] })

  const [otherEducation, setOtherEducation] = useState("")
  const { linkId: routeLinkId } = useParams() // Retrieve linkId from route params
  const { DateStart, setDateStart, DateEnd, setDateEnd } = useDateContext()

  //use effect
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  // dates working
  const formattedDateStart = DateStart ? DateStart : ""
  const formattedDateEnd = DateEnd ? DateEnd : ""
  const { SchoolDistrict, schoolNames, setSelectedDistrict } =
    useSchoolContext()
  // classification
  const { Classsification } = useClasssificationContext()

  useEffect(() => {
    // Access the state data from the location object
    setRowData(location?.state?.rowData)
    setFormInitialized(false)
  }, [location.state])
  console.log("first", rowData?.houseHoldProgram)
  // validation schema
  //
  const raceOptions = [
    "RACE",
    "AFRICAN_AMERICAN",
    "CAUCASIAN",
    "HISPANIC_LATINO",
    "ASIAN",
    "OTHER",
  ]
  const displayRaceOptions = {
    RACE: "Race",
    AFRICAN_AMERICAN: "African American",
    CAUCASIAN: "Caucasian",
    HISPANIC_LATINO: "Hispanic/Latino",
    ASIAN: "Asian",
    OTHER: "Other",
  }
  const programs = [
    "Medicaid",
    "SNAP (Food Stamps)",
    "TANF",
    "Free or Reduced Lunch Program",
    "Reduced Lunch Program",
    "None",
    "Prefer not to say",
  ]

  const educationOptions = [
    "SPECIAL_EDUCATION",
    "GENERAL_EDUCATION",
    "MCKINNEY_VENTO",
    "BUYYING",
    "EP",
    "PLAN_504",
    "DISCIPLINE",
    "OTHER",
  ]
  const displayEducationOptions = {
    SPECIAL_EDUCATION: "Special Education",
    GENERAL_EDUCATION: "General Education",
    MCKINNEY_VENTO: "McKinney Vento",
    BUYYING: "Bullying",
    EP: "IEP",
    PLAN_504: "Plan 504",
    DISCIPLINE: "Discipline",
    OTHER: "Other",
  }
  const handleRaceChange = event => {
    const selectedValue = event.target.value
    setSelectedRace(selectedValue)

    if (selectedValue === "OTHER") {
      formik.setFieldValue("race", "") // Reset the race field value
    } else {
      formik.setFieldValue("race", selectedValue)
    }
  }
  const handleFileChange = event => {
    const files = Array.from(event.target.files)
    setMyDoc(prevDoc => ({
      ...prevDoc,
      uplodeDoc: [...prevDoc.uplodeDoc, ...files],
    }))
  }
  const handleOtherRaceChange = event => {
    setOtherRace(event.target.value)
    formik.setFieldValue("race", "OTHER: " + event.target.value)
  }
  const handleProgramChange = selectedOptions2 => {
    setselectedprograms(selectedOptions2)
  }
  const validationSchema = Yup.object().shape({
    parentName: Yup.string()
      .required("Parent's First Name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Parent's First Name"
      ),
    classification: Yup.array().required("Classification is required"),

    parentName2: Yup.string()
      .required("Parent's Last Name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Only letters and spaces are allowed in Parent's Last Name"
      ),

    checkbox1: Yup.boolean(),
    checkbox2: Yup.boolean(),
    checkbox3: Yup.boolean(),
    atLeastOneCheckbox: Yup.boolean().test(
      "at-least-one-checkbox",
      "At least one checkbox must be selected",
      function (value) {
        const { checkbox1, checkbox2, checkbox3 } = this.parent
        return checkbox1 || checkbox2 || checkbox3
      }
    ),
    county: Yup.string().required("Please select any of the option"),
    commentMessage: Yup.string().required("Please provide a brief message"),
    // howDidYouHear: Yup.string().matches(
    //   /^[a-zA-Z\s]+$/,
    //   "Only letters and spaces are allowed in How did you hear"
    // ),
  })
  const handleCheckboxChange = name => {
    formik.setFieldValue(name, !formik.values[name])
  }

  const handleEducationChange = selectedOptions => {
    setSelectedEducation(selectedOptions)
  }
  const isOtherSelected = selectedEducation.some(
    option => option.value === "OTHER"
  )

  const handleOtherEducationChange = event => {
    setOtherEducation(event.target.value)
    formik.setFieldValue("education", ["OTHER: " + event.target.value])
  }
  const initialValues = {
    parentName: rowData?.parent_first_name || "",
    classification: [], // Initial value for the classification dropdown

    programs: rowData?.houseHoldProgram
      ? rowData.houseHoldProgram.map(program => ({
          value: program,
          label: program,
        }))
      : [],
    parentName2: "",
    schoolName: "",
    schoolDistrict: rowData?.district,
    otherEducation: "",
    principal: rowData?.principal_name,
    education: rowData?.education
      ? rowData.education.map(ed => ({ value: ed, label: ed }))
      : [],
    childName: "",
    dob: "",
    county: "",
    gender: "",
    grade: "",
    pronoun: "",
    ethnicity: "",
    homeAddress: "",
    homePhone: "",
    cellPhone: "",
    street: "",
    city: "",
    zip: "",
    schoolPhone: "",
    email: "",
    checkbox1: true,
    checkbox2: false,
    checkbox3: false,
    specialEducation: false,
    generalEducation: false,
    mckinneyVento: false,
    bullying: false,
    iep: false,
    plan504: false,
    discipline: false,
    district: rowData?.district,
    commentMessage: "",
    schoolName: rowData?.school_name,
    race: rowData?.region,
    howDidYouHear: "",
    classification: rowData?.classification,
    uplodeDoc: rowData?.complaintFile
      ? rowData.complaintFile.map(filename => `'${filename}'`).join(", ")
      : "", // Populate uplodeDoc field with the uploaded document names

    // Add initial values for other fields
  }
  const [toast, setToast] = useState(false)
  const toggleToast = () => {
    setToast(!toast)
  }

  const handleNavigate = () => {
    navigate("/manual-form")
  }
  useEffect(() => {
    let timeout
    if (rowData) {
      const createFileObjectsFromNames = async () => {
        const fileObjects = await Promise.all(
          rowData.complaintFile.map(async fileName => {
            const response = await fetch(`/path/to/file/${fileName}`)
            const blob = await response.blob()
            return new File([blob], fileName, { type: blob.type })
          })
        )
        setMyDoc(prevDoc => ({ ...prevDoc, uplodeDoc: fileObjects }))
      }

      createFileObjectsFromNames()
      const initialPrograms =
        rowData.houseHoldProgram?.map(program => ({
          value: program,
          label: program,
        })) || [] // Handle potential "undefined" values
      setselectedprograms(initialPrograms)
      const initialEducation =
        rowData.education?.map(ed => ({
          value: ed,
          label: ed,
        })) || [] // Handle potential "undefined" values
      setselectedprograms(initialPrograms)
      setSelectedEducation(initialEducation)
      formik.setValues({
        ...initialValues,
        parentName: rowData?.parent_first_name,
        programs: rowData?.houseHoldProgram
          ? rowData.houseHoldProgram.map(program => ({
              value: program,
              label: program,
            }))
          : [],
        parentName2: rowData?.parent_last_name,
        classification: rowData?.classification,
        schoolName: rowData?.school_name,
        district: rowData?.district,
        otherEducation: "",
        principal: rowData?.principal_name,
        education: rowData?.education
          ? rowData.education.map(ed => ({ value: ed, label: ed }))
          : [],
        childName: rowData?.child_name,
        dob: rowData?.dob,
        county: rowData?.county,
        gender: rowData?.gender,
        grade: rowData?.student_grade,
        pronoun: rowData?.pronouns,
        ethnicity: rowData?.ethnicity,
        homeAddress: rowData?.home_address,
        homePhone: rowData?.school_phone,
        cellPhone: rowData?.cell_phone,
        street: rowData?.school_address?.street,
        city: rowData?.school_address?.city,
        zip: rowData?.school_address?.zip,
        schoolPhone: rowData?.school_phone,
        email: rowData?.email,
        checkbox1: rowData?.prefer_to_connect?.home_phone || true,
        checkbox2: rowData?.prefer_to_connect?.cell_phone || false,
        checkbox3: rowData?.prefer_to_connect?.email || false,
        specialEducation: false,
        generalEducation: false,
        mckinneyVento: false,
        bullying: false,
        iep: false,
        plan504: false,
        discipline: false,
        commentMessage: rowData?.briefly_message,

        race: rowData?.region,
        howDidYouHear: "",
        myDoc: rowData?.complaintFile
          ? rowData.complaintFile.map(filename => `'${filename}'`).join(", ")
          : "", // Populate uplodeDoc field with the uploaded document names
        // update other fields as needed
      })
      setFormInitialized(true)
    }

    // Clear the timeout if the component unmounts or if submissionSuccess changes
    return () => clearTimeout(timeout)
  }, [submissionSuccess, rowData, isFormInitialized])
  let response

  const formik = useFormik({
    initialValues,

    onSubmit: async (values, { setSubmitting }) => {
      const selectedEducationValues = values.education
        ? values.education.map(option => {
            if (option.value === "OTHER") {
              return values.otherEducation // Include the value of 'otherEducation' for 'OTHER' option
            }
            return option.value // Include the value for other education options
          })
        : []

      const selectedProgramValues = values.programs
        .map(option => {
          return option.value // Include the value for other education options
        })
        .filter(value => value !== null && value !== undefined)

      const formData = new FormData()

      // Append other form fields to the FormData object
      formData.append("date", values.dob)
      formData.append("parent_first_name", values.parentName)
      // formData.append(
      //   "houseHoldProgram",
      //   selectedProgramValues?.length > 0 ? selectedProgramValues.map((data)=>{data}) : null
      // )

      if (selectedProgramValues && selectedProgramValues.length > 0) {
        selectedProgramValues.forEach((programValue, index) => {
          formData.append(`houseHoldProgram[${index}]`, programValue)
        })
      } else {
        formData.append("houseHoldProgram", null)
      }
      formData.append("parent_last_name", values.parentName2)
      formData.append("home_address", values.homeAddress)
      formData.append("home_phone", values.homePhone)
      formData.append("cell_phone", values.cellPhone)
      formData.append("email", values.email)
      formData.append(
        "prefer_to_connect",
        JSON.stringify(values.prefer_to_connect)
      )
      formData.append("school_name", values.schoolName)
      formData.append("district", values.district)
      formData.append("principal_name", values.principal)
      formData.append("school_phone", values.schoolPhone)
      formData.append("school_address", JSON.stringify(values.school_address))
      formData.append("child_name", values.childName)
      formData.append("dob", values.dob)
      formData.append("gender", values.gender)
      formData.append("pronouns", values.pronoun)
      formData.append("ethnicity", values.ethnicity)
      formData.append("other_region", values.other)
      formData.append("region", values.race)
      formData.append("other_education", values.others)
      // formData.append(
      //   "education",
      //   selectedEducationValues?.length > 0 ? selectedEducationValues : null
      // )

      // Append each education value separately
      if (selectedEducationValues && selectedEducationValues.length > 0) {
        selectedEducationValues.forEach((educationValue, index) => {
          formData.append(`education[${index}]`, educationValue)
        })
      } else {
        formData.append("education", null)
      }
      formData.append("briefly_message", values.commentMessage)
      formData.append("summery", values.howDidYouHear)
      formData.append("county", values.county)
      formData.append("student_grade", values.grade)
      formData.append("classifications", values.classification)

      // Append file data to the FormData object
      myDoc.uplodeDoc.forEach(file => {
        formData.append("uplodeDoc", file)
      })

      try {
        const authToken = sessionStorage.getItem("accessToken")

        const headers = {
          Authorization: `Bearer ${authToken}`,
        }

        const response = await axios.post(
          `/admin/update/complaint/${rowData?._id}`,
          formData,

          { headers }
        )

        dispatch(getSingleCases("", formattedDateStart, formattedDateEnd))
        toggleToast()

        setSubmissionSuccess(true)
        // Wait for 3 seconds before redirecting
        setTimeout(() => {
          window.location.href = "/dashboard"
        }, 1000)
      } catch (error) {
        console.error("Error submitting form:", error)
      }

      setSubmitting(false)
    },
  })
  //schoolName data
  useEffect(() => {
    setSelectedDistrict(formik.values.schoolDistrict) // Set selectedDistrict when schoolDistrict changes
  }, [formik.values.schoolDistrict])
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="d-flex justify-content-center align-items-center ">
          {/* <img src={profile} alt="" className="img-fluid" /> */}
        </div>

        {submissionSuccess ? (
          <div className="page-content" style={{ paddingTop: "0" }}>
            {/* Success message goes here */}
            <Row>
              <Col xl={12}>
                <Card>
                  <div>
                    <div
                      className="position-fixed top-50 start-50 translate-middle p-3"
                      style={{ zIndex: "1005" }}
                    >
                      <Toast isOpen={toast}>
                        <ToastBody>Form has been edited Successfully</ToastBody>
                      </Toast>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>{" "}
          </div>
        ) : (
          <div className="page-content">
            <Container className="container-custom">
              <Form onSubmit={formik.handleSubmit}>
                {/* ... other form elements ... */}
                <Row>
                  <Col
                    lg={9}
                    className="d-flex justify-content-end align-items-center mt-10 fw-bold"
                  >
                    <strong className="fs-4 ">
                      Student and Family Background or Information
                    </strong>
                  </Col>
                  <Col
                    lg={3}
                    className="d-flex justify-content-end align-items-center mt-10"
                  >
                    <button
                      type="button"
                      onClick={() => handleNavigate()}
                      className="btn btn-primary"
                    >
                      New child case
                    </button>
                  </Col>
                </Row>
                <div className="mb-3 mt-4 mt-0">
                  <h4 className="fw-bold">Personal Bio</h4>
                </div>
                <div className="row mb-3" style={{ marginTop: "20px" }}>
                  <div className="col">
                    <Label htmlFor="parentName">Parents First Name:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="parentName"
                      placeholder="Enter Your Parent's First Name"
                      onChange={formik.handleChange}
                      value={formik.values.parentName}
                    />
                    {formik.errors.parentName && formik.touched.parentName && (
                      <div className="text-danger">
                        {formik.errors.parentName}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <Label htmlFor="parentName2">Parents Last Name:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="parentName2"
                      placeholder="Enter Your Parent's Last Name"
                      onChange={formik.handleChange}
                      value={formik.values.parentName2}
                    />
                    {formik.errors.parentName2 &&
                      formik.touched.parentName2 && (
                        <div className="text-danger">
                          {formik.errors.parentName2}
                        </div>
                      )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col">
                    <Label htmlFor="homeAddress">Home Address:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="homeAddress"
                      placeholder="Enter Your Home Address"
                      onChange={formik.handleChange}
                      value={formik.values.homeAddress}
                    />
                  </div>
                  <div className="col">
                    <Label htmlFor="email">Email:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="homePhone">Home Phone:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="homePhone"
                        placeholder="Enter Your Home Phone"
                        onChange={formik.handleChange}
                        value={formik.values.homePhone}
                      />
                      {/* {formik.errors.homePhone &&
                          formik.touched.homePhone && (
                            <div className="text-danger">
                              {formik.errors.homePhone}
                            </div>
                          )} */}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="cellPhone">Cell Phone:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="cellPhone"
                        placeholder="Enter Your Cell Phone"
                        onChange={formik.handleChange}
                        value={formik.values.cellPhone}
                      />
                      {formik.errors.cellPhone && formik.touched.cellPhone && (
                        <div className="text-danger">
                          {formik.errors.cellPhone}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="row mb-3">
                  <label className="col-form-label col-sm-4">
                    I Prefer to be contacted via:
                  </label>
                  <div className="col-sm-10">
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox1"
                        onChange={() => handleCheckboxChange("checkbox1")}
                      />
                      <label className="form-check-label" htmlFor="checkbox1">
                        Home Phone
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox2"
                        onChange={() => handleCheckboxChange("checkbox2")}
                      />
                      <label className="form-check-label" htmlFor="checkbox2">
                        Cell Phone
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox3"
                        onChange={() => handleCheckboxChange("checkbox3")}
                      />
                      <label className="form-check-label" htmlFor="checkbox3">
                        Email
                      </label>
                    </div>

                    {formik.errors.atLeastOneCheckbox &&
                      formik.touched.checkbox1 &&
                      formik.touched.checkbox2 &&
                      formik.touched.checkbox3 && (
                        <div className="text-danger">
                          {formik.errors.atLeastOneCheckbox}
                        </div>
                      )}
                  </div>
                </div>

                <Row>
                  <div className="mb-3 fw-bold mt-3">
                    <h4 className="fw-bold"> School Info</h4>
                  </div>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="county">Select County:</Label>
                      <select
                        className="form-control"
                        id="county"
                        name="county"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.county}
                      >
                        <option
                          value=""
                          label="Select County"
                          disabled
                          selected
                        />
                        <option value="Sussex">Sussex</option>
                        <option value="New Castle">New Castle</option>
                        <option value="Kent">Kent</option>
                      </select>
                      {/* {formik.errors.county && formik.touched.county && (
                          <div className="text-danger">
                            {formik.errors.county}
                          </div>
                        )} */}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="schoolDistrict">Select District:</Label>
                      <Input
                        className="form-control"
                        type="select"
                        id="schoolDistrict"
                        name="schoolDistrict"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.schoolDistrict}
                      >
                        <option
                          value=""
                          label="Select district"
                          default
                          hidden
                        />
                        <option value="Appoquinimink School District">
                          Appoquinimink School District
                        </option>
                        <option value="Brandywine School District">
                          Brandywine School District
                        </option>
                        <option value="Caesar Rodney School District">
                          Caesar Rodney School District
                        </option>
                        <option value="Cape Henlopen School District">
                          Cape Henlopen School District
                        </option>
                        <option value="Capital School District">
                          Capital School District
                        </option>
                        <option value="Christina School District">
                          Christina School District
                        </option>
                        <option value="Colonial School District">
                          Colonial School District
                        </option>
                        <option value="Delmar School District">
                          Delmar School District
                        </option>
                        <option value="Indian River School District">
                          Indian River School District
                        </option>
                        <option value="Lake Forest School District">
                          Lake Forest School District
                        </option>
                        <option value="Laurel School District">
                          Laurel School District
                        </option>
                        <option value="Milford School District">
                          Milford School District
                        </option>
                        <option value="New Castle County Vocational-Technical School District">
                          New Castle County Vocational-Technical School District
                        </option>
                        <option value="Polytech School District">
                          Polytech School District
                        </option>
                        <option value="Red Clay Consolidated School District">
                          Red Clay Consolidated School District
                        </option>
                        <option value="Seaford School District">
                          Seaford School District
                        </option>
                        <option value="Smyrna School District">
                          Smyrna School District
                        </option>
                        <option value="Sussex Technical School District">
                          Sussex Technical School District
                        </option>
                        <option value="Woodbridge School District">
                          Woodbridge School District
                        </option>
                        {/* Add more options for other school districts */}
                      </Input>
                      {/* {formik.errors.schoolName &&
                          formik.touched.schoolName && (
                            <div className="text-danger">
                              {formik.errors.schoolName}
                            </div>
                          )} */}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="schoolName">Select School:</Label>
                      <Input
                        className="form-control"
                        type="select"
                        id="schoolName"
                        name="schoolName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.schoolName}
                      >
                        <option value="" label="Select School" default hidden />
                        {schoolNames.map((schoolName, index) => (
                          <option key={index} value={schoolName}>
                            {schoolName}
                          </option>
                        ))}
                      </Input>

                      {/* {formik.errors.schoolName &&
                          formik.touched.schoolName && (
                            <div className="text-danger">
                              {formik.errors.schoolName}
                            </div>
                          )} */}
                    </div>
                  </Col>
                </Row>
                <Row>{/* ... (existing code) */}</Row>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="street">Street:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="street"
                        placeholder="School Street"
                        onChange={formik.handleChange}
                        value={formik.values.street}
                      />
                      {/* {formik.errors.street && formik.touched.street && (
                          <div className="text-danger">
                            {formik.errors.street}
                          </div>
                        )} */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="city">City:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="School City"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                      />
                      {/* {formik.errors.city && formik.touched.city && (
                          <div className="text-danger">
                            {formik.errors.city}
                          </div>
                        )} */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="zip">Zip:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="zip"
                        placeholder="Enter Zip Code"
                        onChange={formik.handleChange}
                        value={formik.values.zip}
                      />
                      {/* {formik.errors.zip && formik.touched.zip && (
                          <div className="text-danger">{formik.errors.zip}</div>
                        )} */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="schoolPhone">School Phone:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="schoolPhone"
                        placeholder="School Phone Number"
                        onChange={formik.handleChange}
                        value={formik.values.schoolPhone}
                      />
                      {/* {formik.errors.schoolPhone &&
                          formik.touched.schoolPhone && (
                            <div className="text-danger">
                              {formik.errors.schoolPhone}
                            </div>
                          )} */}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="principal">Principal:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="principal"
                        autoComplete="off"
                        placeholder="Enter Name"
                        onChange={formik.handleChange}
                        value={formik.values.principal}
                      />
                      {/* {formik.errors.principal &&
                          formik.touched.principal && (
                            <div className="text-danger">
                              {formik.errors.principal}
                            </div>
                          )} */}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <div className="mb-3 mt-4 fw-bold">
                    <h4 className="fw-bold">Student Info</h4>
                  </div>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="childName">Child Name:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="childName"
                        placeholder="Enter Child Name"
                        onChange={formik.handleChange}
                        value={formik.values.childName}
                      />
                      {/* {formik.errors.childName &&
                          formik.touched.childName && (
                            <div className="text-danger">
                              {formik.errors.childName}
                            </div>
                          )} */}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="dob">Date of birth:</Label>

                      <Input
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                        type="date"
                        id="dob"
                        name="dob"
                        onChange={formik.handleChange}
                        value={formik.values.dob}
                      />
                      {/* {formik.errors.dob && formik.touched.dob && (
                          <div className="text-danger">{formik.errors.dob}</div>
                        )} */}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="gender">Gender:</Label>
                      <Input
                        type="select"
                        className="form-control"
                        id="gender"
                        onChange={formik.handleChange}
                        value={formik.values.gender}
                      >
                        <option value="" disabled>
                          Select Gender
                        </option>
                        <option value="MALE">Male</option>
                        <option value="Female">FEMALE</option>
                        <option value="OTHER">Other</option>
                      </Input>
                      {/* {formik.errors.gender && formik.touched.gender && (
                          <div className="text-danger">
                            {formik.errors.gender}
                          </div>
                        )} */}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="pronoun">Pronouns:</Label>
                      <Input
                        type="select"
                        className="form-control"
                        id="pronoun"
                        onChange={formik.handleChange}
                        value={formik.values.pronoun}
                      >
                        <option value="">Select Pronouns</option>
                        <option value="he">He/Him</option>
                        <option value="she">She/Her</option>
                        <option value="other">OTHER</option>
                        <option value="they">They/Them</option>

                        {/* Add additional pronoun options as needed */}
                      </Input>
                      {/* {formik.errors.pronoun && formik.touched.pronoun && (
                          <div className="text-danger">
                            {formik.errors.pronoun}
                          </div>
                        )} */}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="ethnicity">Ethnicity:</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="ethnicity"
                        placeholder="Enter Ethnicity"
                        onChange={formik.handleChange}
                        value={formik.values.ethnicity}
                      />
                      {/* {formik.errors.ethnicity &&
                          formik.touched.ethnicity && (
                            <div className="text-danger">
                              {formik.errors.ethnicity}
                            </div>
                          )} */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="grade">Grade:</Label>
                      <Input
                        type="select"
                        className="form-control"
                        id="grade"
                        onChange={formik.handleChange}
                        value={formik.values.grade}
                      >
                        <option value="" disabled selected>
                          Select Grade
                        </option>
                        <option value="Pre-K">Pre-K</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="Other">Other</option>
                      </Input>
                      {/* {formik.errors.grade && formik.touched.grade && (
                          <div className="text-danger">
                            {formik.errors.grade}
                          </div>
                        )} */}
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <Row className="">
                    <Label htmlFor="race" className="col-sm-2 col-form-label">
                      Race
                    </Label>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      <select
                        id="race"
                        name="race"
                        className="form-select"
                        value={selectedRace}
                        onChange={handleRaceChange}
                      >
                        {raceOptions.map(option => (
                          <option key={option} value={option}>
                            {displayRaceOptions[option]}
                          </option>
                        ))}
                      </select>
                      {selectedRace === "OTHER" && (
                        <Input
                          type="text"
                          id="otherRace"
                          name="otherRace"
                          className="mt-2 form-control"
                          placeholder="Enter Other Race"
                          value={otherRace}
                          onChange={handleOtherRaceChange}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
                <CustomSelect formik={formik} />

                <Row>
                  <Label className="col-form-label col-sm-6">
                    Please check the type(s) of educational issues that apply:
                  </Label>
                  <Col md={6}>
                    <Select
                      id="education"
                      name="education"
                      isMulti
                      value={selectedEducation}
                      styles={customStyles}
                      onChange={selectedOptions => {
                        handleEducationChange(selectedOptions)
                        formik.setFieldValue("education", selectedOptions)
                        formik.handleBlur("education")
                      }}
                      options={educationOptions?.map(option => ({
                        value: option,
                        label: displayEducationOptions[option],
                      }))}
                    />
                    {/* {formik.touched.education && formik.errors.education && (
                        <div className="text-danger">
                          {formik.errors.education}
                        </div>
                      )} */}

                    {selectedEducation.some(
                      option => option.value === "OTHER"
                    ) && (
                      <>
                        <Label className="col-form-label col-sm-4 ">
                          Enter Other Education Issue:
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            id="otherEducation"
                            name="otherEducation"
                            className="mb-4 form-control"
                            placeholder="Enter Other Education Issue"
                            value={formik.values.otherEducation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.otherEducation &&
                              formik.errors.otherEducation && (
                                <div className="text-danger">
                                  {formik.errors.otherEducation}
                                </div>
                              )} */}
                        </Col>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Label className="col-form-label col-sm-6">
                    Are you or your household currently participating in any of
                    the following programs? (Please select all that apply)
                  </Label>
                  <Col sm={6}>
                    <Select
                      id="programs"
                      name="programs"
                      isMulti
                      value={selectedprograms}
                      styles={customStyles}
                      onChange={selectedOptions2 => {
                        handleProgramChange(selectedOptions2)
                        formik.setFieldValue("programs", selectedOptions2)
                        formik.handleBlur("programs")
                      }}
                      options={programs?.map(option => ({
                        value: option,
                        label: option,
                      }))}
                    />

                    {formik.touched.programs && formik.errors.programs && (
                      <div className="text-danger">
                        {formik.errors.programs}
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="mb-3 mt-3">
                  <Label htmlFor="commentMessage-input">
                    Please briefly state the nature of the problem you wish to
                    discuss with this office.
                  </Label>
                  <textarea
                    className={`form-control ${
                      formik.touched.commentMessage &&
                      formik.errors.commentMessage
                        ? "is-invalid"
                        : ""
                    }`}
                    id="commentMessage-input"
                    name="commentMessage"
                    value={formik.values.commentMessage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Your message..."
                    rows="8"
                  ></textarea>
                  {formik.touched.commentMessage &&
                  formik.errors.commentMessage ? (
                    <div className="invalid-feedback">
                      {formik.errors.commentMessage}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="howDidYouHear-input">
                    How did you hear about us?
                  </Label>
                  <Input
                    type="text"
                    className={`form-control ${
                      formik.touched.howDidYouHear &&
                      formik.errors.howDidYouHear
                        ? "is-invalid"
                        : ""
                    }`}
                    id="howDidYouHear-input"
                    name="howDidYouHear"
                    value={formik.values.howDidYouHear}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter text"
                  />
                  {/* {formik.touched.howDidYouHear &&
                    formik.errors.howDidYouHear ? (
                      <div className="invalid-feedback">
                        {formik.errors.howDidYouHear}
                      </div>
                    ) : null} */}
                </div>
                <Col sm={6}>
                  <div className="mt-4">
                    <div>
                      <Label className="form-label">Upload documents</Label>
                      <div className="input-group">
                        <Input
                          type="file"
                          className="form-control"
                          id="document"
                          multiple
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                  {myDoc?.uplodeDoc?.map((file, index) => (
                    <div key={index} className="mt-3">
                      <div className="d-flex align-items-center">
                        <p className="mb-0">
                          <i className="mdi mdi-file mr-2" /> {file.name}
                        </p>
                        <i
                          className="mdi mdi-delete font-size-18 mr-4"
                          id="deletetooltip"
                          onClick={() => {
                            const updatedDocs = myDoc.uplodeDoc.filter(
                              (_, i) => i !== index
                            )
                            setMyDoc({ ...myDoc, uplodeDoc: updatedDocs })
                          }}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    </div>
                  ))}
                </Col>
                <div className="hstack gap-2 justify-content-end mt-5">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </Container>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default Viewdetails
