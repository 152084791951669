import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch advocate data
const getAdvocate = createAsyncThunk('advocate/getAdvocate', async () => {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    const response = await axios.get(`/admin/advocate/list`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create the initial state
const initialState = {
  advocate: null,
  loading: false,
  error: null,
};

// Create the advocate slice
const advocateSlice = createSlice({
  name: 'advocate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdvocate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdvocate.fulfilled, (state, action) => {
        state.loading = false;
        state.advocate = action.payload;
      })
      .addCase(getAdvocate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch advocate data';
      });
  },
});

// Export the async thunk for use in components
export { getAdvocate };

// Export the reducer
export default advocateSlice.reducer;
