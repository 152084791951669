import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import advocate from "./advocate/reducer";
import AdvocateSaga from "redux/Slices/AdvocateSaga";
//E-commerce
import ecommerce from "./e-commerce/reducer";
import admin from "./Admin/reducer";





//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";



const rootReducer = combineReducers({
  // public
  Layout,
  advocate,
  //  AdvocateSaga,
  //Admin
  admin,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  Dashboard,
  DashboardSaas,
});

export default rootReducer;
