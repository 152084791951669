import React from 'react';
import { Col, Row, Card, CardBody, Input, Label } from 'reactstrap';

const CheckboxGroup = ({ options, onChange }) => {
  return (
    <Col lg="12">
      {options.map((option, index) => (
        <div key={index} className="form-check mt-3">
          <Input
            type="checkbox"
            id={`customRadioInline${index + 1}`}
            name={`customRadioInline${index + 1}`}
            className="form-check-input"
            onChange={(e) => onChange(option, e.target.checked)}
          />
          <Label
            className="form-check-label"
            htmlFor={`customRadioInline${index + 1}`}
          >
            {option}
          </Label>
        </div>
      ))}
    </Col>
  );
};
export default CheckboxGroup;
