import React, { useState, useEffect } from "react"
import Select from "react-select"
import { useClasssificationContext } from "./import"

const CustomSelect = ({ formik }) => {
  const { Classsification } = useClasssificationContext()

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  }

  // Ensure selectedOptions is always initialized as an array
  const initialSelectedOptions = Array.isArray(formik.values.classification)
    ? formik.values.classification
    : []
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  const [primaryOption, setPrimaryOption] = useState(null)

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions)
  }, [initialSelectedOptions])

  const handleChange = selectedOptions => {
    // Ensure selectedOptions is always an array
    selectedOptions = Array.isArray(selectedOptions) ? selectedOptions : []
    const updatedOptions = selectedOptions.map(option => option.value)

    if (primaryOption) {
      const primaryIndex = updatedOptions.indexOf(primaryOption)
      if (primaryIndex > -1) {
        updatedOptions.splice(primaryIndex, 1)
        updatedOptions.unshift(primaryOption)
      }
    }

    setSelectedOptions(updatedOptions)
    formik.setFieldValue("classification", updatedOptions)
    formik.setFieldTouched("classification", true)
  }

  const handleSetAsPrimary = option => {
    if (primaryOption === option) {
      // If the clicked option is already the primary, deselect it
      setPrimaryOption(null)
    } else {
      // Set the clicked option as primary
      setPrimaryOption(option)
    }
  }

  return (
    <div className="mb-3">
      <label htmlFor="classification" className="form-label">
        Classification:
      </label>
      <Select
        id="classification"
        name="classification"
        styles={customStyles}
        isMulti
        value={selectedOptions.map(option => ({
          value: option,
          label: option,
        }))}
        onChange={selectedOptions =>
          handleChange(selectedOptions.map(option => option))
        }
        options={Classsification.flatMap(group =>
          group.options.map(option => ({
            value: option,
            label: option,
            group: group.label,
          }))
        )}
      />
      {Array.isArray(selectedOptions) &&
        selectedOptions.length > 0 &&
        selectedOptions.map(option => (
          <div key={option} className="form-check mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="primaryOption"
              onChange={() => handleSetAsPrimary(option)}
            />
            <label className="form-check-label">
              {option}
              {primaryOption === option && (
                <span style={{ color: "red" }}> (Primary)</span>
              )}
            </label>
          </div>
        ))}
        
    </div>
  )
}

export default CustomSelect
