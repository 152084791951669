import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "../Ecommerce/EcommerceOrders/EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
// import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import LawyersDetailModal from "pages/DashboardLawyers/LawyersDetailModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "utils/api";
import { use } from "i18next";
import { getAdvocatesLaw } from "store/advocate/actions";
function LawyersList() {
  const dispatch=useDispatch();
  const advocate1 = useSelector((state) => state.admin.advocateAdmin.content); // Assuming you have a property named 'advocate' in 
  useEffect(() => {
    // Dispatch the action to fetch advocate data when the component mounts
    dispatch(getAdvocatesLaw());
  }, [dispatch]); // Ensure that dispatch is added as a dependency to avoid warnings


  document.title = "Advocates | dpeopconnect";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [advocate, setAdvocate] = useState(null)

  const [order, setOrder] = useState(null);


  

  const getAdvocate= async()=> {
    // try {
    //   const response = yield call(getOrders);
    //   yield put(getOrdersSuccess(response));
    // } catch (error) {
    //   yield put(getOrdersFail(error));
    // }
    const accessToken = sessionStorage.getItem("accessToken")
  
    try {
      const response = await axios.get(`/admin/advocate/list`,{
          headers: {
            "Content-Type": "application/json", // Adjust the content type as needed
            Authorization: `Bearer ${accessToken}`,
          },
        })
        
        setAdvocate(response.data)
    } catch (error) {
      console.error("error", error)
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Order Id"
        ).required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);


  const selectEcommerceState = (state) => state.ecommerce;
  const EcommerceOrderProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      orders: Ecommerce.orders,
      loading: Ecommerce.loading
    })
  );

  const { orders, loading } = useSelector(EcommerceOrderProperties);

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
     getAdvocate()
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

 

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  // const handleDeleteOrder = () => {
  //   if (order && order.id) {
  //     dispatch(onDeleteOrder(order.id));
  //     setDeleteModal(false);
  //   }
  // };
  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "first_name":
        return "First Name"

      case "last_name":
        return "Last Name"
        case 'email':
        return 'Email'
    
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const handleOrderClicks = () => {
    setIsEdit(false);
    toggle();
  };
  
  const dynamicColumns = useMemo(() => {
    if (!advocate1 || advocate1.length === 0) return []

    const keysToDisplay = ["first_name", "last_name",'email']

    return [
      ...keysToDisplay?.map(key => ({
        Header: getCustomHeaderName(key),
        accessor: key,
        disableFilters: true,
        Cell: cellProps => {
          // Display other properties as they are
          return <span>{cellProps?.row?.original[key]}</span>
        },
      })),
      
    

      // DropdownColumn,
    ]
  }, [advocate1])


  return (
    <React.Fragment>
      <LawyersDetailModal isOpen={modal1} toggle={toggleViewModal} />
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Advocates" breadcrumbItem="List" /> */}
          {
            advocate?.success ?<Row>
            <Col xs="12">
              <Card>
                <CardBody>
               
      <div className="mb-4 h4 card-title">Advocates </div>

                  <TableContainer
                    columns={dynamicColumns}
                    data={advocate1}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    isPagination={true}
                    // iscustomPageSizeOptions={false}
                    customPageSize={10}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>:
             <Spinners setLoading={setLoading} />
              
              
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Case Details" : "Add Case"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Case Id</Label>
                      <Input
                        name="Case Id"
                        type="text"
                        placeholder="Insert Order Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ""}
                        invalid={
                          validation.touched.orderId && validation.errors.orderId ? true : false
                        }
                      />
                      {validation.touched.orderId && validation.errors.orderId ? (
                        <FormFeedback type="invalid">{validation.errors.orderId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Advocate Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("orderdate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate && validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.orderdate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Completed</option>
                        <option>Pending</option>
                        <option>Current</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
LawyersList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default LawyersList;