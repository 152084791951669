import * as Yup from "yup"
export const validationSchema = Yup.object().shape({
  parentName: Yup.string()
    .required("Parent's First Name is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in Parent's First Name"
    ),
  uplodeDoc: Yup.array().min(1, "Select at least one file"), // Validation for at least one file

  // classification: Yup.string().required("Classification is required"),
  classification: Yup.array()
    .min(1, "Select at least 1 classification")
    .required("Please select at least 1 classification"),

  // Add other validations as needed
  parentName2: Yup.string()
    .required("Parent's Last Name is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in Parent's Last Name"
    ),
  homeAddress: Yup.string()
    .required("Home Address is required")
    .matches(
      /^[a-zA-Z0-9\s/-]+$/,
      "Only letters, numbers, spaces, hyphens, and slashes are allowed in Home address"
    ),
  homePhone: Yup.string()
    .required("Home Phone is required")
    .matches(
      /^[0-9#+*.,-]+$/,
      "Only numbers and specific special characters are allowed in Home Phone"
    )
    .min(10, "Home Phone must be at least 10 characters")
    .max(15, "Home Phone must be at most 15 characters"),

  cellPhone: Yup.string()
    .required("Cell Phone is required")
    .matches(
      /^[0-9#+*.,-]+$/,
      "Only numbers and specific special characters are allowed in Cell Phone"
    )
    .min(10, "Cell Phone must be at least 10 characters")
    .max(15, "Cell Phone must be at most 15 characters"),

  schoolName: Yup.string().required("School Name is required"),
  schoolDistrict: Yup.string().required("School District is required"),

  street: Yup.string()
    .required("Street is required")
    .matches(
      /^[a-zA-Z0-9\s/-]+$/,
      "Only letters, numbers, spaces, hyphens, and slashes are allowed in Street"
    ),

  city: Yup.string()
    .required("City is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in Name of City"
    ),

  zip: Yup.string()
    .required("Zip Code is required")
    .matches(
      /^[a-zA-Z0-9\s-]+$/,
      "Only letters, numbers, spaces, and hyphens are allowed in Zip"
    ),
  schoolPhone: Yup.string()
    .required("School Phone is required")
    .matches(
      /^[0-9#+*.,-]+$/,
      "Only numbers and specific special characters are allowed in School Phone"
    )
    .min(10, "School Phone must be at least 10 characters")
    .max(15, "School Phone must be at most 15 characters"),
  principal: Yup.string()
    .required("Principal is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in Principal"
    ),
  childName: Yup.string()
    .required("Child Name is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in child Name"
    ),
  dob: Yup.string()
    .matches(
      /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
      "Invalid date format. Please use MM-DD-YYYY"
    )
    .required("Date of Birth is required"),

  education: Yup.array()
    .min(1, "Select at least 1 educational issues")
    .required("Please select at least 1 educational issues"),
  programs: Yup.array()
    .min(1, "Select at least 1 programs")
    .required("Please select at least 1 programs"),

  otherEducation: Yup.string().when(["education"], (education, schema) => {
    return education && education.some(option => option.value === "OTHER")
      ? schema.required("Please enter Other Education Issue")
      : schema.notRequired()
  }),

  grade: Yup.string()
    .required("Grade is required")
    .oneOf(
      [
        "Pre-K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "Other",
      ],
      "Invalid Grade"
    ),
  gender: Yup.string()
    .required("Grade is required")
    .oneOf(["MALE", "FEMALE", "OTHER"], "Invalid grade. Accepted values are "),
  race: Yup.string().required("Race is required"),
  primaryComplaint: Yup.string().required("Primary Complaint is required"),

  pronoun: Yup.string()
    .required("Pronouns are required")
    .oneOf(
      ["he", "she", "other", "they"],
      "Invalid pronouns. Accepted values are he/him or she/her they/them or other"
    ),

  ethnicity: Yup.string()
    .required("Ethnicity is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Only letters and spaces are allowed in Ethincity"
    ),

  email: Yup.string()

    .email("Invalid email address")
    .required("Email is required"),
  // Add validations for other fields as needed
  checkbox1: Yup.boolean(),
  checkbox2: Yup.boolean(),
  checkbox3: Yup.boolean(),
  atLeastOneCheckbox: Yup.boolean().test(
    "at-least-one-checkbox",
    "At least one checkbox must be selected",
    function (value) {
      const { checkbox1, checkbox2, checkbox3 } = this.parent
      return checkbox1 || checkbox2 || checkbox3
    }
  ),
  county: Yup.string().required("Please select any of the option"),
  commentMessage: Yup.string().required("Please provide a brief message"),
  howDidYouHear: Yup.string().matches(
    /^[a-zA-Z\s]+$/,
    "Only letters and spaces are allowed in How did you hear"
  ),
})
