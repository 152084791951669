import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown"
import React from "react"
import { useFormik } from "formik"
import axios from "utils/api"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { useState } from "react"
import CheckboxGroup from "./CheckboxGroup"
import RadioGroup from "./RadioGroup"
const SpForm = ({lang,caseId}) => {
  const [disDefault, setdisDefault] = useState(0)
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }
  const formik = useFormik({
    initialValues: {
      disagreementResolved: [],
      appropriateServices: [],
      advocateKnowledge: '',
      meetingPerformance: '',
      communicationSatisfaction: [],
      attendedMeetings: [],
      comfortableAdvocating: [],
      overallExperienceRating: '',
      recommendToFriend: [],
      additionalComments: "",
    },
    validationSchema: Yup.object({
      // Define validation rules for your form fields here
    }),
    onSubmit: async values => {
        // Handle form submission here
        try {
          const apiData = {
            disagreement_resolved: values.disagreementResolved,
            appropriate_services: values.appropriateServices,
            advocate_knowledge: values.advocateKnowledge,
            meeting_performance: values.meetingPerformance,
            communication_satisfaction: values.communicationSatisfaction,
            attended_meetings: values.attendedMeetings,
            comfortable_advocating: values.comfortableAdvocating,
            overall_experience_rating: values.overallExperienceRating,
            recommend_to_friend: values.recommendToFriend,
            additional_comments: values.additionalComments,
          }
          const response = await axios.post(
            `/feedback/case_form?lang=${lang}&caseId=${caseId}`,
            apiData
          )
        } catch (error) {
          // Handle errors
          console.error("API Error:", error)
        }
      },
  })
  const options2 = ["Sí", "No"]
  const options = ["Sí", "No", "No aplica"]
  const ratingOptions = [
    { label: "5 Muy bien informado", value: 5 },
    { label: "4", value: 4 },
    { label: "3 Algo bien informado", value: 3 },
    { label: "2", value: 2 },
    { label: "1 No informado ", value: 1 },
  ]
  const ratingOptions2 = [
    { label: "5  Excelente", value: 5 },
    { label: "4", value: 4 },
    { label: "3 Algo bien informado", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Medicore ", value: 1 },
    { label: "No applic ", value: 0 },
  ]
  const ratingOptions3 = [
    { label: "5  Excelente", value: 5 },
    { label: "4", value: 4 },
    { label: "3", value: 3 },
    { label: "2", value: 2 },
    { label: "1 Malo", value: 1 },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="">
              <Col lg="12">
                <Row className="d-flex justify-content-center ">
                  <Col lg="6">
                    <Card>
                      <CardBody
                        style={{
                          borderTop: "solid 10px #e8c428",
                          borderRadius: "10px",
                        }}
                      >
                        <h1 className=" text-center">
                          {" "}
                          Encuesta para padres de DPEOP{" "}
                        </h1>
                        <p className="card-title-desc ">
                          Gracias por comunicarse con el Programa del Defensor
                          del Pueblo de Educación Pública de Delaware (DPEOP).
                          Complete la encuesta a continuación y háganos saber
                          cómo nos ha ido en la prestación de servicios de
                          defensa a su familia. Sus respuestas nos ayudarán a
                          mejorar nuestro programa. Esta encuesta es anónima. Su
                          dirección de correo electrónico y cualquier otra
                          información de identificación personal no se
                          registrarán.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          La información y el apoyo que recibí de DPEOP ayudaron
                          a resolver un desacuerdo con la escuela o el distrito
                          de mi hijo.
                        </h6>
                        <CheckboxGroup
                          options={options2}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [...formik.values.disagreementResolved, option]
                              : formik.values.disagreementResolved.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "disagreementResolved",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          Mi hijo ha recibido servicios y/o apoyos más
                          apropiados como resultado de la información y el apoyo
                          que recibí de DPEOP.
                        </h6>
                        <CheckboxGroup
                          options={options}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [...formik.values.appropriateServices, option]
                              : formik.values.appropriateServices.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "appropriateServices",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          En una escala del 1 al 5, ¿qué tan bien informado cree
                          que estaba el defensor de DPEOP con respecto a su
                          problema educativo?
                        </h6>

                        <div className="mb-3">
                          <RadioGroup
                            question="advocateKnowledge"
                            options={ratingOptions}
                            selectedValue={formik.values.advocateKnowledge}
                            onChange={value =>
                              formik.setFieldValue("advocateKnowledge", value)
                            }
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          ¿Cómo calificaría nuestro desempeño en la reunión a la
                          que asistimos en nombre de su hijo?
                        </h6>

                        <div className="mb-3 mt-3">
                          <RadioGroup
                            question="meetingPerformance"
                            options={ratingOptions}
                            selectedValue={formik.values.meetingPerformance}
                            onChange={value =>
                              formik.setFieldValue("meetingPerformance", value)
                            }
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg="12">
                <Row className="d-flex justify-content-center">
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <h6 className="">
                          ¿Está satisfecho con el nivel de comunicación que
                          recibió mientras trabajaba con DPEOP?
                        </h6>
                        <CheckboxGroup
                          options={options2}
                          onChange={(option, checked) => {
                            const newOptions = checked
                              ? [
                                  ...formik.values.communicationSatisfaction,
                                  option,
                                ]
                              : formik.values.communicationSatisfaction.filter(
                                  item => item !== option
                                )

                            formik.setFieldValue(
                              "communicationSatisfaction",
                              newOptions
                            )
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              ¿Asistimos a alguna reunión de la escuela o del
                              distrito con usted en nombre de su hijo?
                            </h6>
                            <CheckboxGroup
                              options={options2}
                              onChange={(option, checked) => {
                                const newOptions = checked
                                  ? [...formik.values.attendedMeetings, option]
                                  : formik.values.attendedMeetings.filter(
                                      item => item !== option
                                    )

                                formik.setFieldValue(
                                  "attendedMeetings",
                                  newOptions
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              La información y el apoyo que recibí de DPEOP me
                              ayudaron a sentirme más cómodo al abogar por una
                              necesidad crítica relacionada con la educación de
                              mi hijo.
                            </h6>
                            <CheckboxGroup
                              options={options2}
                              onChange={(option, checked) => {
                                const newOptions = checked
                                  ? [
                                      ...formik.values.comfortableAdvocating,
                                      option,
                                    ]
                                  : formik.values.comfortableAdvocating.filter(
                                      item => item !== option
                                    )

                                formik.setFieldValue(
                                  "comfortableAdvocating",
                                  newOptions
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              Califique su experiencia general con DPEOP.
                            </h6>

                            <div className="mb-3 mt-3">
                              <RadioGroup
                                question=""
                                options={ratingOptions3}
                                selectedValue={
                                  formik.values.overallExperienceRating
                                }
                                onChange={value =>
                                  formik.setFieldValue(
                                    "overallExperienceRating",
                                    value
                                  )
                                }
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>{" "}
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              Recomendarías DPEOP a un amigo?
                            </h6>
                            <CheckboxGroup
                              options={options2}
                              onChange={(option, checked) => {
                                const newOptions = checked
                                  ? [...formik.values.recommendToFriend, option]
                                  : formik.values.recommendToFriend.filter(
                                      item => item !== option
                                    )

                                formik.setFieldValue(
                                  "recommendToFriend",
                                  newOptions
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12">
                    <Row className="d-flex justify-content-center">
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h6 className="">
                              Por favor, comparta cualquier comentario o
                              sugerencia adicional?
                            </h6>

                            <div className="mb-3">
                              <Input
                                type="text"
                                className="form-control"
                                value={formik.values.additionalComments}
                                placeholder="Your Answer"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderRadius: "0px",
                                  borderBottom: isClicked
                                    ? "2px solid #e8c428"
                                    : "",
                                  transition: "border-color 0.6s ease-in-out",
                                }}
                                onChange={e =>
                                  formik.setFieldValue(
                                    "additionalComments",
                                    e.target.value
                                  )
                                }
                                onClick={handleClick}
                              ></Input>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col lg={12}>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#1b242b" }}
                    className="d-flex justify-content-center m-auto"
                  >
                    Submit
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SpForm
