import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { Form, FormGroup, Input, Label, TabContent, TabPane } from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { useSelector } from "react-redux"
import { Base_API_KEY } from "config-global"
import { useDispatch } from "react-redux"
import { getSingleCases as getSingleCasesAdmin } from "store/Admin/actions"
import { fetchCasesRequest, getSingleCases } from "store/advocate/actions"
import { useDateContext } from "Context/DateContext"
import ActivityComp from "./ActivityComp"
const CaseDetailsPage = () => {
  const location = useLocation()
  const [passedSteps, setPassedSteps] = useState([1])
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  // const { rowData } = location.state
  const { rowData } = location.state || {}
  const { DateStart, DateEnd } = useDateContext()

  // usestate
  const [complaintFile, setComplaintFile] = useState(
    rowData?.complaintFile || []
  )

  const navigation = useNavigate()
  const educationWithSpaces = rowData?.education?.map(edu =>
    edu ? edu.replace(/_/g, " ") : edu
  )

  const formatEducation = education => {
    if (!education || !Array.isArray(education)) return ""

    return education
      .map(edu =>
        edu
          ?.toLowerCase()
          .split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(", ")
  }
  const truncateMessage = (message, maxLength = 100) => {
    if (message && message.length > maxLength) {
      return message.substring(0, maxLength) + "..."
    } else {
      return message || "N/A"
    }
  }

  const function1 = () => {
    navigation("/view-detail", { state: { rowData: rowData } })
  }
  const function2 = () => {
    navigation("/dashboard")
  }
  const filteredStrategy = rowData?.strategy?.filter(
    item => item?.lab === "RFR"
  )

  // Define common style for all headings
  const headingStyle = {
    color: "#1B242B",
    fontSize: "14px",
    fontWeight: "600",
  }
  const dispatch = useDispatch()
  const role = sessionStorage.getItem("role")
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576)
    }

    handleResize() // Check screen size on component mount
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role !== "ADVOCATE") {
          dispatch(getSingleCasesAdmin("", "", ""))
        } else {
          dispatch(getSingleCases("", "", ""))
          // dispatch(fetchCasesRequest({ status: "" }))
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        // Handle error if needed
      }
    }

    fetchData()
  }, [dispatch, DateStart, DateEnd])
  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"

      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const memoizedData =
    role === "ADVOCATE"
      ? useSelector(state =>
          state.advocate.singleCases?.content?.filter(
            item => item?.caseId === rowData?.caseId
          )
        )
      : useSelector(state =>
          state.admin.singleCasesAdmin?.content?.filter(
            item => item?.caseId === rowData?.caseId
          )
        )

  const shouldShowTable = rowData?.complaintFile.length > 0

  // useMemo to memoize the filtered data
  const adminSingleData = useMemo(() => memoizedData, [memoizedData])
  let advocate_name = adminSingleData && adminSingleData[0]?.advocate_name

  const dynamicColumns = useMemo(() => {
    const keysToDisplay = ["caseId"]

    const columns = keysToDisplay.map(key => ({
      Header: getCustomHeaderName(key),
      accessor: key,
      disableFilters: true,
      Cell: cellProps => {
        if (key === "caseId") {
          return <h6 className="">{cellProps.value}</h6>
        }
      },
    }))

    // Handle complaintFile documents separately
    if (complaintFile) {
      complaintFile.forEach((document, index) => {
        columns.push({
          Header: `Document ${index + 1}`, // Customize the header here as needed
          accessor: `document_${index}`,
          disableFilters: true,
          Cell: cellProps => {
            const openPdfFile = () => {
              window.open(`${Base_API_KEY}/documents/${document}`, "_blank")
            }

            return (
              <div>
                <Button
                  type="button"
                  style={{ backgroundColor: "#024E80", color: "white" }}
                  className="btn-sm btn-rounded"
                  onClick={openPdfFile}
                  id={`viewCaseButton_${index}`}
                >
                  View Document {index + 1}
                </Button>
                {/* Add any additional elements or logic specific to each document */}
              </div>
            )
          },
        })
      })
    }

    return columns
  }, [rowData, complaintFile])
  const data = [
    "Initial Contact & Preliminary Assessments",
    "Intake Interview",
    "Records Request",
    "Strategy Lab",
    "Receipt of Records",
    "Execution",
    "Resolution & Closure",
  ]
  const totalSteps = data.length
  const [activeStep, setActiveStep] = useState(
    "Initial Contact & Preliminary Assessments"
  )
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    if (rowData?.step) {
      const stepIndex = data.findIndex(step => step === rowData.step)
      if (stepIndex !== -1) {
        setActiveStep(rowData.step)
        setActiveTab(stepIndex + 1)
      }
    }
  }, [rowData])
  function truncateStepName(stepName) {
    const maxLength = 32
    if (stepName.length > maxLength) {
      return stepName.slice(0, maxLength) + "..."
    }
    return stepName
  }

  return (
    <div className="page-content">
      <Container>
        <React.Fragment>
          <Row>
            <Col lg="12">
              <Row>
                <div className="clearfix mt-0 mb-2 mt-lg-0 d-flex justify-content-start">
                  <Button
                    type="button"
                    style={{ backgroundColor: "#1b242b" }}
                    onClick={function2}
                  >
                    <i className="bx bx-left-arrow-alt"></i>
                  </Button>
                </div>
              </Row>
              {role !== "ADVOCATE" && (
                <CardBody className="mt-3">
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        {data.map((stepName, index) => (
                          <NavItem
                            key={index}
                            className={classnames({
                              current: activeTab === index + 1,
                            })}
                          >
                            {isSmallScreen && activeTab === index + 1 && (
                              <NavLink
                                style={{ height: "112px", background: "" }}
                                className={classnames({
                                  active: activeTab === index + 1,
                                })}
                                onClick={() => {
                                  setActiveTab(index + 1)
                                }}
                                disabled={
                                  !(passedSteps || []).includes(index + 1)
                                }
                              >
                                <span className="number">{index + 1}.</span>
                                <br />
                                {truncateStepName(stepName)}
                              </NavLink>
                            )}
                            {!isSmallScreen && (
                              <NavLink
                                style={{ height: "112px", background: "" }}
                                className={classnames({
                                  active: activeTab === index + 1,
                                })}
                                onClick={() => {
                                  setActiveTab(index + 1)
                                }}
                                disabled={
                                  !(passedSteps || []).includes(index + 1)
                                }
                              >
                                <span className="number">{index + 1}.</span>
                                <br />
                                {truncateStepName(stepName)}
                              </NavLink>
                            )}
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              )}

              <Card className="mt-4">
                <CardBody>
                  <Row>
                    <Col lg="10" className="d-flex">
                      <div className="d-flex align-items-center">
                        <div className="text-muted">
                          <h4 className="mb-1">{rowData?.caseId}</h4>
                        </div>
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="clearfix mt-4 mt-lg-0 d-flex justify-content-end">
                        <Button
                          type="button"
                          style={{ backgroundColor: "#1b242b" }}
                          onClick={function1}
                        >
                          Edit Case
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <div>
                  <Row>
                    <Col>
                      <div className="p-4">
                        <h4 className="text-[#1b242b] mb-3">
                          Personal Information
                        </h4>

                        <div className="text-muted">
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Child Name: </span>
                            {rowData?.child_name}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Gender: </span>
                            {rowData?.gender}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Parent Name: </span>
                            {`${rowData?.parent_first_name} ${rowData?.parent_last_name}`}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Cell Phone: </span>
                            {rowData?.cell_phone}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Home Phone: </span>
                            {rowData?.cell_phone}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Email: </span>
                            {rowData?.email}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <div>
                  <Row>
                    <Col>
                      <div className="p-4">
                        <h4 className="text-[#1b242b] mb-3">
                          School Information
                        </h4>

                        <div className="text-muted">
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>School Name: </span>
                            {rowData?.school_name}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>School District: </span>
                            {rowData?.district}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Address: </span>
                            {rowData?.home_address}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Phone: </span>
                            {rowData?.school_phone}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Principal Name: </span>
                            {rowData?.principal_name}
                          </p>
                          <p className="mb-3 h6" style={{ fontSize: "14px" }}>
                            <span style={headingStyle}>Education Issue: </span>
                            {formatEducation(educationWithSpaces)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={rowData?.callHistory?.length > 0 ? 6 : 12}>
              <Card>
                <div>
                  <Row>
                    <Col>
                      <div className="p-4">
                        <h4 className="mb-3 text-[#1b242b]">Details</h4>
                        <div className="text-muted">
                          <Row className="mb-1">
                            <Col>
                              <p style={headingStyle}>RFR Status:</p>
                              {filteredStrategy?.length > 0 ? (
                                filteredStrategy?.map((item, index) => (
                                  <p key={item._id}>
                                    {index + 1}. {item.description}
                                  </p>
                                ))
                              ) : (
                                <p
                                  className="mb-3 h6"
                                  style={{ fontSize: "14px" }}
                                >
                                  N/A
                                </p>
                              )}
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <p
                                className="mb-3 h6"
                                style={{ fontSize: "14px" }}
                              >
                                <span style={headingStyle}>Summary: </span>
                                {rowData?.summary || "N/A"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <p
                                className="mb-3 h6"
                                style={{ fontSize: "14px" }}
                              >
                                <span style={headingStyle}>
                                  Classification:{" "}
                                </span>
                                {rowData?.classification || "N/A"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <p
                                className="mb-3 h6"
                                style={{ fontSize: "14px" }}
                              >
                                <span style={headingStyle}>
                                  Brief Message:{" "}
                                </span>
                                {truncateMessage(rowData?.briefly_message)}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <p
                                className="mb-3 h6"
                                style={{ fontSize: "14px" }}
                              >
                                <span style={headingStyle}>
                                  Case Referral Condition:{" "}
                                </span>
                                {rowData?.case_closed_condition || "N/A"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>

            {adminSingleData && adminSingleData[0]?.callHistory?.length > 0 && (
              <Col lg="6">
                <ActivityComp
                  data={adminSingleData[0]?.callHistory}
                  advocate_name={advocate_name}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <div>
                  <Row>
                    <Col>
                      {shouldShowTable && (
                        <div className="p-4">
                          <h4 className="mb-3 text-[#1b242b]">Documents</h4>
                          <div className="text-muted">
                            <Row className="mb-1">
                              <div className="table-responsive">
                                <table className="table align-middle table-nowrap">
                                  <thead className="table-light">
                                    <tr>
                                      {dynamicColumns?.map(column => (
                                        <th key={column.accessor}>
                                          {column.Header}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {adminSingleData?.map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {dynamicColumns?.map(column => (
                                          <td key={column.accessor}>
                                            {column.Cell({
                                              value: row[column.accessor],
                                            })}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Row>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    </div>
  )
}

export default CaseDetailsPage
