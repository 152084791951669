import React, { useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"

const EcommerceOrdersModal = props => {
  const navigation = useNavigate()
  const { isOpen, toggle, selectedClientData, selectedOptions } = props
  let isSuperAdmin

  const accessToken = sessionStorage.getItem("accessToken")
  const [Response, setResponse] = useState("")
  const educationWithSpaces = selectedClientData?.education?.map(edu =>
    edu ? edu.replace(/_/g, " ") : edu
  )

  // Now, `updatedEducation` is an array where each non-null element has underscores replaced with spaces.
  const renderCaseDetails = () => (
    <div className="d-flex justify-content-center">
      <p className="mb-4">
        Case id:{" "}
        <span className="text-primary">{selectedClientData?.caseId}</span>
      </p>
      {/* <p className="mb-4">
        Lawyer Name: <span className="text-primary">{selectoption}</span>
      </p> */}
    </div>
  )
  const formatEducation = education => {
    if (!education || !Array.isArray(education)) return ""

    return education
      .map(edu =>
        edu
          ?.toLowerCase() // Convert the entire string to lowercase
          .split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(", ") // Join formatted elements with commas or any separator you prefer
  }


  const renderTableHead = () => (
    <thead>
      <tr>
        <th scope="col">Cell Phone</th>
        <th scope="col">Child Name</th>
        <th scope="col">Email</th>
      </tr>
    </thead>
  )

  const renderTableBody = () => (
    <tbody>
      <tr>
        <th scope="row">
          <div>
            <p className="text-muted mb-0">{selectedClientData?.cell_phone}</p>
          </div>
        </th>
        <td>
          <div>
            <p className="text-muted mb-0">{selectedClientData?.child_name}</p>
          </div>
        </td>
        <td>{selectedClientData?.email}</td>
      </tr>
      <tr>
        <th scope="col">Gender</th>
        <th scope="col">Education</th>
        <th scope="col">Parents Name</th>
      </tr>
      <tr>
        <th scope="row">
          <div>
            <p className="text-muted mb-0">{selectedClientData?.gender}</p>
          </div>
        </th>
        <td>
          <div>
            <p className="text-muted mb-0">
              {formatEducation(educationWithSpaces)}
            </p>
          </div>
        </td>
        <td>
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.parent_first_name}{" "}
              {selectedClientData?.parent_last_name}
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="col">City</th>
        <th scope="col">Zip</th>
        <th scope="col">Street</th>
      </tr>
      <tr>
        <th scope="row">
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.school_address?.city}
            </p>
          </div>
        </th>
        <td>
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.school_address?.zip}
            </p>
          </div>
        </td>
        <td>
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.school_address?.street}
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="col">District</th>
        <th scope="col">School Phone</th>
        <th scope="col">Principal Name</th>
      </tr>
      <tr>
        <th scope="row">
          <div>
            <p className="text-muted mb-0">{selectedClientData?.school_name}</p>
          </div>
        </th>
        <td>
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.school_phone}
            </p>
          </div>
        </td>
        <td>
          <div>
            <p className="text-muted mb-0">
              {selectedClientData?.principal_name}
            </p>
          </div>
        </td>
      </tr>{" "}
    </tbody>
  )
  const function1 = () => {
    navigation("/view-detail", { state: { rowData: selectedClientData } })
  }
  const renderModalBody = () => {
    let userRole = sessionStorage.getItem("role")

    // Filter the strategy array based on the lab value
    const filteredStrategy = selectedClientData?.strategy?.filter(
      item => item?.lab === "RFR"
    )

    isSuperAdmin = userRole === "super-admin"

    if (isSuperAdmin) {
      return (
        <ModalBody>
          {renderCaseDetails()}
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              {renderTableHead()}
              {renderTableBody()}
            </Table>
            <div>
              <Col md={6}>{selectedClientData?.briefly_message}</Col>
            </div>
            <div>
              <Col md={12} style={{ marginTop: "10px" }}>
                <div>
                  <h5>Summary</h5>
                  {selectedClientData?.summery ? (
                    selectedClientData?.summery || "nothing to show"
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
              </Col>

              <Col md={12} style={{ marginTop: "10px" }}>
                {selectedClientData?.case_closed_condition ? (
                  <div>
                    <h5>Case Referral Condition</h5>
                    {selectedClientData?.case_closed_condition ||
                      "nothing to show"}
                  </div>
                ) : (
                  <div>
                    <h5>Case Referral Condition</h5>
                    N/A
                  </div>
                )}
              </Col>
            </div>
            <Col md={12} style={{ marginTop: "10px" }}>
              <h5>RFR Status</h5>
              {filteredStrategy?.length > 0 ? (
                filteredStrategy?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}. </td>
                    <td>{item.description}</td>
                    {/* Add more columns if needed */}
                  </tr>
                ))
              ) : (
                <p>N/A</p>
              )}
            </Col>
          </div>
        </ModalBody>
      )
    } else {
      return (
        <ModalBody>
          {renderCaseDetails()}
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              {renderTableHead()}
              {renderTableBody()}
            </Table>
            <div>
              <Col md={6}>{selectedClientData?.briefly_message}</Col>
            </div>
            <div>
              <Col md={12} style={{ marginTop: "10px" }}>
                <div>
                  <h5>Summary</h5>
                  {selectedClientData?.summery ? (
                    selectedClientData?.summery || "nothing to show"
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
              </Col>
            </div>{" "}
          </div>
        </ModalBody>
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal modal-lg"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Case Details</ModalHeader>
        {renderModalBody()}
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>

          <Button
            type="button"
            style={{ backgroundColor: "#1b242b" }}
            onClick={function1}
          >
            View More
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
