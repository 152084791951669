import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  layoutType: 'vertical',
  layoutModeType: 'light',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarThemeImage: 'none',
  leftSideBarType: 'default',
  topbarTheme: 'dark',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayout: (state, action) => {
      state.layoutType = action.payload;
    },
    changePreloader: (state, action) => {
      state.isPreloader = action.payload;
    },
    changeLayoutMode: (state, action) => {
      state.layoutModeType = action.payload;
    },
    changeLayoutWidth: (state, action) => {
      state.layoutWidth = action.payload;
    },
    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload;
    },
    clearSidebarTheme: (state, action) => {
      state.leftSideBarTheme = initialState.leftSideBarTheme;
    },
    changeSidebarThemeImage: (state, action) => {
      state.leftSideBarThemeImage = action.payload;
    },
    changeSidebarType: (state, action) => {
      state.leftSideBarType = action.payload.sidebarType;
    },
    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload;
    },
    clearTopbarTheme: (state, action) => {
      state.topbarTheme = initialState.topbarTheme;
    },
    showRightSidebar: (state, action) => {
      state.showRightSidebar = action.payload;
    },
    showSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    toggleLeftmenu: (state, action) => {
      state.leftMenu = action.payload;
    },
  },
});

export const {
  changeLayout,
  changePreloader,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebar,
  showSidebar,
  toggleLeftmenu,
} = layoutSlice.actions;

export default layoutSlice.reducer;
