// data.js

const raceOptions = [
  "RACE",
  "AFRICAN_AMERICAN",
  "CAUCASIAN",
  "HISPANIC_LATINO",
  "ASIAN",
  "OTHER",
]
const primaryComplaint = [
  "Bullying_and_Harassment",
  "Safety",
  "Transportation",
  "Special_Education",
  "Suspension",
  "Attendance",
  "Placement",
  "Truancy",
  "Academic_Progress"
];
const displayOptionsWithUnderscores = {
  Bullying_and_Harassment: "Bullying and Harassment",
  Safety: "Safety",
  Transportation: "Transportation",
  Special_Education: "Special Education",
  Suspension: "Suspension",
  Attendance: "Attendance",
  Placement: "Placement",
  Truancy: "Truancy",
  Academic_Progress: "Academic Progress"
};


// Create a new array with underscores instead of spaces

const displayRaceOptions = {
  RACE: "Race",
  AFRICAN_AMERICAN: "African American",
  CAUCASIAN: "Caucasian",
  HISPANIC_LATINO: "Hispanic/Latino",
  ASIAN: "Asian",
  OTHER: "Other",
}

const educationOptions = [
  "SPECIAL_EDUCATION",
  "GENERAL_EDUCATION",
  "MCKINNEY_VENTO",
  "BUYYING",
  "EP",
  "PLAN_504",
  "DISCIPLINE",
  "OTHER",
]

const programs = [
  "Medicaid",
  "SNAP (Food Stamps)",
  "TANF",
  "Free or Reduced Lunch Program",
  "Reduced Lunch Program",
  "None",
  "Prefer not to say",
]

const displayEducationOptions = {
  SPECIAL_EDUCATION: "Special Education",
  GENERAL_EDUCATION: "General Education",
  MCKINNEY_VENTO: "McKinney Vento",
  BUYYING: "Bullying",
  EP: "IEP",
  PLAN_504: "Plan 504",
  DISCIPLINE: "Discipline",
  OTHER: "Other",
}

export {
  raceOptions,
  displayOptionsWithUnderscores,
  displayRaceOptions,
  educationOptions,
  programs,
  primaryComplaint,
  displayEducationOptions,
}
