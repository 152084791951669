import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../assets/images/product/img-7.png"
import img4 from "../../assets/images/product/img-4.png"
const modalContentStyle = {
  maxWidth: "300px", // Set maximum width
  overflow: "auto", // Add overflow to handle content that exceeds the maximum width
  maxHeight: '100px', // Set maximum height
  overflowY: 'auto',   // Add vertical scroll
}
const LawyersDetailModal = props => {
  const { isOpen, toggle } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Case Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Name: <span className="text-primary">Tom Jerry</span>
          </p>
          <p className="mb-2">
            Email: <span className="text-primary">Tom@gmail.com</span>
          </p>
          <p className="mb-2">
            PhoneNumber: <span className="text-primary">0321-13231241</span>
          </p>
          <p className="mb-2">
            dob: <span className="text-primary">11-11-1998</span>
          </p>{" "}
          <p className="mb-2">
            Gender: <span className="text-primary">M</span>
          </p>
          <p className="mb-2">
            Case no: <span className="text-primary">#Sk250</span>
          </p>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Message</th>
                  <th scope="col">Abuser</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={modalContentStyle}>
                    <div>
                      <p className="text-truncate font-size-14">
                        School Abused by Teacher Name is travis whose is
                        Computer Lecturer
                      </p>
                      <p className="text-muted mb-0">
                        Physical Disorder: Blind
                      </p>
                    </div>
                  </td>

                  <td>Tim School</td>
                </tr>
                {/* <tr>
                  <th scope="row">
                    <div>
                      <img src={img4} alt="" className="avatar-sm"/>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                      <p className="text-muted mb-0">$ 145 x 1</p>
                    </div>
                  </td>
                  <td>$ 145</td>
                </tr> */}
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">
                      <span className="text-bold">Summary: </span>Its solved we
                      have found proves
                    </h6>
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Shipping:</h6>
                  </td>
                  <td>
                    Free
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Total:</h6>
                  </td>
                  <td>
                    $ 400
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

LawyersDetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default LawyersDetailModal
