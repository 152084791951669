// reducer.js
import {
  GET_ADVOCATE_SUCCESS_ADMIN,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES_SUCCESS_ADMIN,
  GET_SINGLE_CASES_FAIL,
  GET_SINGLE_CASES_SUCCESS1,
  GET_SINGLE_CASES_FAIL1,
  GET_SINGLE_CASES_SUCCESS2,
  GET_SINGLE_CASES_FAIL2,
  //loading
  START_LOADING,
  STOP_LOADING,
  // ... other imports
} from "./actionTypes"
import * as types from "./actionTypes"

const INIT_STATE = {
  // ... other state properties

  advocateAdmin: [],
  singleCasesAdmin: [],
  singleCases1: [],
  singleCases2: [],

  // ... other state properties
  error: {},
  cases: [],
  error: null,
  // ... other state properties
  loading: false,
}
const Admin = (state = INIT_STATE, action) => {
  switch (action.type) {
    // ... other cases
    //loading

    case GET_ADVOCATE_SUCCESS_ADMIN:
      return {
        ...state,
        advocateAdmin: action.payload,
        loading: false,
      }

    case GET_ADVOCATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SINGLE_CASES_SUCCESS_ADMIN:
      return {
        ...state,
        loading: true,
        singleCasesAdmin: action.payload,
        // Handle loading or other state properties if needed
      }

    case GET_SINGLE_CASES_FAIL:
      return {
        ...state,
        loading: false,

        // Handle error or other state properties if needed
      }
    case GET_SINGLE_CASES_SUCCESS1:
      return {
        ...state,
        loading: false,

        singleCases1: action.payload,
        // Handle loading or other state properties if needed
      }

    case GET_SINGLE_CASES_FAIL1:
      return {
        ...state,
        // Handle error or other state properties if needed
      }

    // ... other cases
    case types.FETCH_CASES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case types.FETCH_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        cases: action.payload,
      }
    case types.FETCH_CASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Admin
