import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { dropRight, isEmpty } from "lodash"
import axios from "utils/api"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap" // Import the necessary components

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction } from "../../common/data/dashboard"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentStatus2,
  PaymentMethod,
} from "../Dashboard/LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
// function format date
const formatDate = dateString => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }
  return new Date(dateString).toLocaleDateString("en-US", options)
}

const CasesList = props => {
  // use effect
  const [selectedOption, setSelectedOption] = useState("")

  const handleSelectOption = option => {
    setSelectedOption(option)
  }
  const accessToken = sessionStorage.getItem("accessToken")

  const [Response, setResponse] = useState("")
  const [selectoption, setSelectoption] = useState("")
  const [advocateDatas, setadvocateDatas] = useState("")

  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/admin/complaint/list/${"ASSIGNED"}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json", // Adjust the content type as needed
            },
          }
        )
        setResponse(response.data.content)
        // Process the response data as needed
      } catch (error) {
        console.error(error)
      }
    }

    fetchData() // Call the function to fetch data when the component mounts
  }, [])

  const [modal1, setModal1] = useState(false)

  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }

  const [modal2, setModal2] = useState(false)

  const toggleViewModal2 = clientData => {
    setSelectedClientData(clientData)

    setModal2(!modal2)
  }
  const onClickDelete = customer => {}
  const handleCustomerClick = arg => {
    const customer = arg
  }
  useEffect(() => {
    const fetchAdvocateList = async () => {
      const accessToken = sessionStorage.getItem("accessToken")
      try {
        const response = await axios.get("/admin/advocate/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })

        const variable = response?.data?.content

        const firstNamesWithIds = variable.map(item => ({
          id: item?._id,
          firstName: item?.first_name,
          lastName: item?.last_name,
        }))
        setDropdownOptions(firstNamesWithIds)

        const advocateId = variable?.map(item => item?._id)
        setid(advocateId)
        setadvocateDatas(variable)
      } catch (error) {
        console.error("Error fetching advocate list:", error)
      }
    }

    const fetchData = async () => {
      setLoadingOptions(true)
      await fetchAdvocateList()
      // await assignCaseToAdvocate();
      setLoadingOptions(false)
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once


  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "advocate_name":
        return "Advocate Name"

      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!Response || Response.length === 0) return []

    const keysToDisplay = ["advocate_name", "date", "child_name"]

    return [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => (
      //     <input type="checkbox" className="form-check-input" />
      //   ),
      // },

      ...keysToDisplay.map(key => ({
        Header: getCustomHeaderName(key),
        accessor: key,
        disableFilters: true,
        Cell: cellProps => {
          if (key === "date" && cellProps.row.original[key]) {
            // Convert and format the date if the key is 'date'
            const [datePart, timePart] = cellProps.row.original[key].split("T")
            const [year, month, day] = datePart.split("-")

            return <span>{`${day}-${month}-${year}`}</span> // Adjust the formatting as needed
          } else {
            // Display other properties as they are
            return <span>{cellProps.row.original[key]}</span>
          }
        },
      })),
      {
        Header: "Status",
        accessor: "paymentStatus2",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus2 {...cellProps} />
        },
      },
      {
        Header: "Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original)}
            >
              View Details
            </Button>
          )
        },
      },
      // {
      //   Header: 'Action',
      //   Cell: (cellProps) => {
      //     return (
      //       <UncontrolledButtonDropdown>
      //         <DropdownToggle tag="a" className="card-drop">
      //           <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //         </DropdownToggle>

      //         <DropdownMenu className="dropdown-menu-end">
      //           <DropdownItem

      //               onClick={() => toggleViewModal2(selectedClientData)}

      //           >
      //             <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
      //             Edit
      //             <UncontrolledTooltip placement="top" target="edittooltip">
      //               Edit
      //             </UncontrolledTooltip>
      //           </DropdownItem>

      //           <DropdownItem
      //             onClick={() => {
      //               const customerData = cellProps.row.original;
      //               onClickDelete(customerData);
      //             }}>
      //             <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
      //             Delete
      //             <UncontrolledTooltip placement="top" target="deletetooltip">
      //               Delete
      //             </UncontrolledTooltip>
      //           </DropdownItem>
      //         </DropdownMenu>
      //       </UncontrolledButtonDropdown>
      //     );
      //   }
      // },

      {
        Header: "Reassign Advocate",
        disableFilters: true,
        accessor: "view2",
        Cell: cellProps => {
          const clientData = cellProps.row.original

          // Check if the modal is open for this row

          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal2(cellProps.row.original)}
            >
              Select Advocate
            </Button>
          )
        },
      },
      // DropdownColumn,
    ]
  }, [Response])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />
      <ModalData
        isOpen={modal2}
        toggle={toggleViewModal2}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        Id={Id}
        selectOption={handleSelectOption}
        advocateDatas={advocateDatas}
      />
      {Response?.length > 0 ? (
        <Card>
          <CardBody style={{ marginTop: "5rem" }}>
            <div className="mb-4 h4 card-title"> Assigned Cases </div>

            <TableContainer
              columns={dynamicColumns}
              data={Response} // Pass your custom data here
              isGlobalFilter={true}
              isAddOptions={false}
              isPagination={true}
              order="desc"
              // iscustomPageSizeOptions={false}
              customPageSize={10}
              tableClass="align-middle table-nowrap table-check table"
              theadClass="table-light"
              paginationDiv="col-12"
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          </CardBody>
        </Card>
      ) : (
        <div className="alert alert-warning mt-3">
          <p className="text-primary mb-0">No Cases found</p>
        </div>
      )}
    </React.Fragment>
  )
}

CasesList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(CasesList)
