import React, { Fragment, useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import autoTable from "jspdf-autotable"
import logo from "../../assets/images/Final.png"
import { useDateContext } from "Context/DateContext"
import CalendarFilter from "./Calendar"

import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import jsPDF from "jspdf"

import { Table, Row, Col, Button, Badge } from "reactstrap"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { Link, useNavigate, useNavigation } from "react-router-dom"
import TimeIntervalFilter from "./TimeIntervalFilter"
import QuarterlyFilter from "./QuarterlyFilter"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const { DateStart, setDateStart, DateEnd, setDateEnd } = useDateContext()

  const [selectedQuarter, setSelectedQuarter] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedInterval, setSelectedInterval] = React.useState("")
  const [selectedWeek, setSelectedWeek] = React.useState(null)
  const [selectedMonth, setSelectedMonth] = React.useState(null)
  const [selectedYear, setSelectedYear] = React.useState(null)
  const [value, setValue] = React.useState(globalFilter)
  //
  const userRole = sessionStorage.getItem("role") // Fetch user role from session storage

  // Condition for user role not being 'super-admin'
  const location = useLocation()
  const isAdvocateListExcluded = !location.pathname.includes("/advocates-list")
  const shouldRenderFilterCol = userRole == "super-admin"
  //
  const [showQuarterlyFilter, setShowQuarterlyFilter] = React.useState(false) // New state variable
  function addOneMonth(dateString, Num) {
    const currentDate = new Date(dateString)
    currentDate.setMonth(currentDate.getMonth() + Num)

    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0") // Add leading zero if needed
    const day = currentDate.getDate().toString().padStart(2, "0") // Add leading zero if needed

    return `${year}-${month}-${day}`
  }

  const handleDatesChange = async data => {
    // Access start date and end date from the data object
    // Check if selectedOption is "monthly" and update the dates accordingly
    if (data.selectedOption === "monthly") {
      data.dates.endDate = addOneMonth(data.dates.selectedDate, 1)
      setDateStart(data.dates.selectedDate?.replace(/%/g, "-"))
      setDateEnd(data.dates.endDate?.replace(/%/g, "-"))
    } else if (data.selectedOption === "quarterly") {
      data.dates.endDate = addOneMonth(data.dates.selectedDate, 2)
      setDateStart(data.dates.selectedDate?.replace(/%/g, "-"))
      setDateEnd(data.dates.endDate?.replace(/%/g, "-"))
    } else if (data.selectedOption === "yearly") {
      data.dates.endDate = addOneMonth(data.dates.selectedDate, 12)
      setDateStart(data.dates.selectedDate)
      setDateEnd(data.dates.endDate)
    } else {
      setDateStart(data.dates.startDate)
      setDateEnd(data.dates.endDate)
    }

    // Apply date range filter logic
  }

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  function getWeekNumber(date) {
    const target = new Date(date.valueOf())
    const dayNr = (date.getDay() + 6) % 7
    target.setDate(target.getDate() - dayNr + 3)
    const firstThursday = target.valueOf()
    target.setMonth(0, 1)
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000)
  }

  return (
    <div className="d-flex flex-wrap gap-2 justify-content-between">
      <Col xxl={4} lg={4} sm={12}>
        {shouldRenderFilterCol && isAdvocateListExcluded && (
          <h5 className="mb-4">Cases</h5>
        )}

        <input
          type="search"
          className="form-control"
          id="search-bar-0"
          value={value || ""}
          placeholder="Please enter case Id ..."
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Col>
      {shouldRenderFilterCol && isAdvocateListExcluded && (
        <Col xxl={2} lg={4} sm={12}>
          <div className="d-flex justify-content-between">
            <h5 className="mb-4">Filter</h5>
            <i
              className="mdi mdi-filter"
              style={{ fontSize: "24px", marginRight: "8px" }}
            ></i>
          </div>

          <CalendarFilter onDatesChange={handleDatesChange} />
        </Col>
      )}

      {isJobListGlobalFilter && (
        <Col xxl={4} lg={4} sm={12}>
          <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />
        </Col>
      )}
    </div>
  )
}

const TableContainer = ({
  columns,
  boolvalue,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const navigate = useNavigate()
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  const Handlefuncc = () => {
    navigate("/AdvocateRegister")
  }

  const pdfTableRef = useRef(null)

  const generatePDF = () => {
    const pdf = new jsPDF()
    const imagedata = logo // Assuming 'logo' is defined or imported

    // Image formatting
    const imgWidth = 60
    const imgHeight = 60
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const xPos = (pdfWidth - imgWidth) / 2
    pdf.addImage(imagedata, "JPEG", xPos, 0, imgWidth, imgHeight)

    // Table data preparation
    const columns = [
      "Case Id",
      "Child Name",
      "Assign Date",
      "Updated Status",
      "Advocate",
      "School Name",
      "Grade",
      "County",
      "Education",
    ]
    const detailsColumnIndex = headerGroups[0].headers.findIndex(
      column => column.id === "Case Id"
    )

    // Efficient column filtering (avoids unnecessary splicing)
    const filteredColumns =
      detailsColumnIndex !== -1
        ? columns
            .slice(0, detailsColumnIndex)
            .concat(columns.slice(detailsColumnIndex + 1))
        : columns

    // Data extraction with safer cell value handling
    const flattenedRows = page.map(row => {
      const cellValues = row.cells.map(cell => {
        // Use optional chaining to gracefully handle undefined values
        return cell.value?.toString().replace(/"/g, "") || ""
      })
      return cellValues
    })

    // Set table starting point
    const startY = 60 // Adjust as needed

    // Create the table without modifying original headerGroups
    const columnWidths = [
      "20",
      "50",
      "30",
      "40",
      "20",
      "40",
      "20",
      "20",
      "30",
      "25",
      "30",
      "40",
    ] // Adjusted widths
    pdf.setFontSize(4) // Set a smaller font size
    pdf.autoTable({
      head: [filteredColumns],
      body: flattenedRows,
      startY: startY,
      splitTable: true,
      margin: { left: 5, right: 5 }, // No left and right margin

      columnWidth: columnWidths,
    })

    pdf.save("table_data.pdf")
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions && (
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={Handlefuncc}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Advocate
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive  " style={{ overflowY: "unset" }}>
        <Table {...getTableProps()} className={tableClass} ref={pdfTableRef}>
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    className={column.isSort ? "sorting" : ""}
                  >
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => (
                      <td key={cell.id} {...cell.getCellProps()}>
                        {cell.value === "OPEN" ||
                        cell.value === "CLOSED" ||
                        cell.value === "PENDING" ? (
                          <Badge
                            className={
                              cell.value === "OPEN"
                                ? "badge-soft-success"
                                : cell.value === "CLOSED"
                                ? "badge-soft-dark"
                                : cell.value === "URGENT"
                                ? "badge-soft-danger"
                                : cell.value === "NON_URGENT"
                                ? "badge-soft-info"
                                : ""
                            }
                          >
                            {cell.value}
                          </Badge>
                        ) : cell.value === "NON_URGENT" ? (
                          <Badge className="badge-soft-info">Non Urgent</Badge>
                        ) : cell.value === "URGENT" ? (
                          <Badge className="badge-soft-danger">Urgent</Badge>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      {isPagination && pageOptions.length > 1 && (
        <Row className="justify-content-between align-items-center">
          {isShowingPageLength && (
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            </div>
          )}
          <div className={paginationDiv}>
            <ul className={`${pagination} list-unstyled d-flex flex-wrap`}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageOptions.slice(0, 5).map((item, key) => (
                <React.Fragment key={key}>
                  <li
                    className={
                      pageIndex === item ? "page-item active" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => gotoPage(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}

      {boolvalue && (
        <Col sm="7" xxl="8" className="d-flex">
          <div className="text-sm-end">
            <Button
              type="button"
              style={{ backgroundColor: "#024E80", color: "white" }}
              className="btn mb-2 me-2"
              onClick={generatePDF}
            >
              <i className="mdi mdi-file-pdf-outline me-1" />
              Generate PDF
            </Button>
          </div>
        </Col>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
