// actionTypes.js
export const GET_ADVOCATE = "GET_ADVOCATE";
export const GET_ADVOCATE_SUCCESS = "GET_ADVOCATE_SUCCESS";
export const GET_ADVOCATE_FAIL = "GET_ADVOCATE_FAIL";
// actionTypes.js
export const GET_SINGLE_CASES = "GET_SINGLE_CASES";
export const GET_SINGLE_CASES1 = "GET_SINGLE_CASES1";
export const GET_SINGLE_CASES2 = "GET_SINGLE_CASES2";

export const GET_SINGLE_CASES_SUCCESS = "GET_SINGLE_CASES_SUCCESS";
export const GET_SINGLE_CASES_FAIL = "GET_SINGLE_CASES_FAIL";
export const GET_SINGLE_CASES_SUCCESS1 = "GET_SINGLE_CASES_SUCCESS1";
export const GET_SINGLE_CASES_FAIL1 = "GET_SINGLE_CASES_FAIL1";
export const GET_SINGLE_CASES_SUCCESS2 = "GET_SINGLE_CASES_SUCCESS2";
export const GET_SINGLE_CASES_FAIL2 = "GET_SINGLE_CASES_FAIL2";
// get notifications
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

// 
export const FETCH_CASES_REQUEST = 'FETCH_CASES_REQUEST';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE';
//
export const SET_NOTIFICATION_SEEN_REQUEST = "SET_NOTIFICATION_SEEN_REQUEST";
