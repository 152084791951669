import React from "react";
import { Doughnut } from "react-chartjs-2";
import getChartColorsArray from "components/Common/ChartsDynamicColor";

const DountChart = ({ dataColors, style,open,close }) => {
  var doughnutChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: ["Open", "Close"],
    datasets: [
      {
        data: [open, close],
        backgroundColor: doughnutChartColors,
        hoverBackgroundColor: doughnutChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  };
  const chartStyle = { padding: '36px !important' };

  // Apply the style directly to the Doughnut component
  return <Doughnut width={734} height={269} className="chartjs-chart" data={data} style={chartStyle} />;
}

export default DountChart;
