// actionTypes.js
export const GET_ADVOCATE = "GET_ADVOCATE";
export const GET_ADVOCATE_SUCCESS_ADMIN = "GET_ADVOCATE_SUCCESS_ADMIN";
export const GET_ADVOCATE_FAIL = "GET_ADVOCATE_FAIL";
// actionTypes.js
export const GET_SINGLE_CASES_ADMIN = "GET_SINGLE_CASES_ADMIN";
export const GET_SINGLE_CASES1 = "GET_SINGLE_CASES1";
export const GET_SINGLE_CASES2 = "GET_SINGLE_CASES2";

export const GET_SINGLE_CASES_SUCCESS_ADMIN = "GET_SINGLE_CASES_SUCCESS_ADMIN";
export const GET_SINGLE_CASES_FAIL = "GET_SINGLE_CASES_FAIL";
export const GET_SINGLE_CASES_SUCCESS1 = "GET_SINGLE_CASES_SUCCESS1";
export const GET_SINGLE_CASES_FAIL1 = "GET_SINGLE_CASES_FAIL1";
export const GET_SINGLE_CASES_SUCCESS2 = "GET_SINGLE_CASES_SUCCESS2";
export const GET_SINGLE_CASES_FAIL2 = "GET_SINGLE_CASES_FAIL2";
//loading
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// new
// 
export const FETCH_CASES_REQUEST = 'FETCH_CASES_REQUEST';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE';
