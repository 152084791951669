import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { dropRight, isEmpty } from "lodash"
import axios from "utils/api"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap" // Import the necessary components

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction } from "../../common/data/dashboard"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentStatus2,
  PaymentMethod,
} from "../Dashboard/LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
import Spinners from "components/Common/Spinner"
import { useNavigate } from "react-router-dom"
import { fetchCasesRequest } from "store/Admin/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
// function format date
const formatDate = dateString => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }
  return new Date(dateString).toLocaleDateString("en-US", options)
}

const PendingCasesList = props => {
  // use effect
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedOptions, setSelectedOptions] = useState([])

  const handleSelectOption = option => {
    setSelectedOption(option)
  }
  const accessToken = sessionStorage.getItem("accessToken")

  const handleSelectOption2 = (e, index) => {
    setSelectedOptions(prevOptions => {
      const newOptions = [...prevOptions]
      newOptions[index] = {
        value: e.target.value,
        index: index,
      }
      return newOptions
    })
  }

  const [Response, setResponse] = useState("")
  const [advocateDatas, setadvocateDatas] = useState("")
  const [loading, setLoading] = useState(true)
  const [updateCaseIds, setUpdateCaseIds] = useState([])

  const [rowIndex, setrowIndex] = useState(null)
  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)
  //nav
  const navigate = useNavigate()
  //dispatch
  const dispatch = useDispatch()
  useEffect(() => {
    // Fetch OPEN cases
    dispatch(fetchCasesRequest({ status: "PENDING" }))
  }, [dispatch])
  const singleCases1 = useSelector(state => state?.admin?.cases)
  const valuesaved = useSelector(state => state?.admin?.loading)
  useEffect(() => {
    setLoading(valuesaved)
  }, [singleCases1, valuesaved])

  const [modal1, setModal1] = useState(false)

  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }
  const [Lastvalue, setLastValue] = useState("")

  const [modal2, setModal2] = useState(false)
  const [selectedOptions1, setSelectedOptions1] = useState([])

  const [dropdownStates, setDropdownStates] = useState([])

  const toggleDropdown = index => {
    const updatedDropdownStates = [...dropdownStates]
    updatedDropdownStates[index] = !updatedDropdownStates[index]
    setDropdownStates(updatedDropdownStates)
  }

  const handleSelectOption3 = (rowIndex, value, clientData) => {
    const updatedOptions = [...selectedOptions1]
    const value2 = value === "Non Urgent" ? "NON_URGENT" : value
    updatedOptions[rowIndex] = value // Combining status and case ID
    setSelectedOptions1(updatedOptions)
    setLastValue(value2)
    const updatedCaseIds = [...updateCaseIds]

    updatedCaseIds[rowIndex] = { value: value2, caseId: clientData?.caseId }
    setUpdateCaseIds(updatedCaseIds)

    // Additional logic if needed
  }
  const toggleViewModal2 = clientData => {
    setSelectedClientData(clientData)

    setModal2(!modal2)
  }

  const handleLinkClick = (cellValue, data) => {
    // Navigate to the desired URL when the link is clicked, passing selectedClientData as state
    navigate(`/case-description/${cellValue}`, { state: { rowData: data } })
  }
  useEffect(() => {
    const fetchAdvocateList = async () => {
      const accessToken = sessionStorage.getItem("accessToken")
      try {
        const response = await axios.get("/admin/advocate/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })

        const variable = response?.data?.content

        const firstNamesWithIds = variable.map(item => ({
          id: item?._id,
          firstName: item?.first_name,
          lastName: item?.last_name,
        }))
        setDropdownOptions(firstNamesWithIds)

        const advocateId = variable?.map(item => item?._id)
        setid(advocateId)
        setadvocateDatas(variable)
      } catch (error) {
        console.error("Error fetching advocate list:", error)
      }
    }

    const fetchData = async () => {
      setLoadingOptions(true)
      await fetchAdvocateList()
      // await assignCaseToAdvocate();
      setLoadingOptions(false)
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once

  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"

      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!singleCases1 || singleCases1.length === 0) return []

    const keysToDisplay = ["caseId", "date", "child_name"]

    return [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => (
      //     <input type="checkbox" className="form-check-input" />
      //   ),
      // },

      ...keysToDisplay.map(key => ({
        Header: getCustomHeaderName(key),
        accessor: key,
        disableFilters: true,
        Cell: cellProps => {
          if (key === "caseId") {
            // Render Case ID as a button or link, or any other UI element you prefer
            return (
              <a
                className="link-primary override-text-decoration"
                style={{
                  color: "blue",
                  textDecoration: "underline !important",
                }}
                onClick={() =>
                  handleLinkClick(cellProps.value, cellProps.row.original)
                }
              >
                {cellProps.value}
              </a>
            )
          }
          if (key === "date" && cellProps.row.original[key]) {
            // Convert and format the date if the key is 'date'
            const [datePart, timePart] = cellProps.row.original[key].split("T")
            const [year, month, day] = datePart.split("-")

            return <span>{`${day}-${month}-${year}`}</span> // Adjust the formatting as needed
          } else {
            // Display other properties as they are
            return <span>{cellProps.row.original[key]}</span>
          }
        },
      })),
      {
        Header: "Status",
        accessor: "paymentStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },

      {
        Header: "Urgency",
        disableFilters: true,
        accessor: "stat",
        Cell: cellProps => {
          const rowIndex = cellProps.row.index
          const clientData = cellProps.row.original

          return (
            <Dropdown
              isOpen={dropdownStates[rowIndex]}
              toggle={() => toggleDropdown(rowIndex)}
              className="mt-4 mt-sm-0"
            >
              <DropdownToggle
                className=""
                color=""
                style={{ backgroundColor: "#024E80", color: "white" }}
                caret
              >
                {selectedOptions1[rowIndex] || "Select Status"}{" "}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption3(rowIndex, "URGENT", clientData)
                  }
                >
                  Urgent
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption3(rowIndex, "Non Urgent", clientData)
                  }
                >
                  Non Urgent
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        },
      },
      {
        Header: "Assign To",
        disableFilters: true,
        accessor: "view2",
        Cell: cellProps => {
          const clientData = cellProps.row.original

          // Check if the modal is open for this row

          return (
            <Button
              type="button"
              style={{ backgroundColor: "#024E80", color: "white" }}
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal2(cellProps.row.original)}
            >
              Select Advocate
            </Button>
          )
        },
      },

      // DropdownColumn,
    ]
  }, [singleCases1, selectedOption, dropdownStates, selectedOptions1])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />
      <ModalData
        isOpen={modal2}
        rowIndex={rowIndex}
        updateCaseIds={updateCaseIds}
        toggle={toggleViewModal2}
        selectedOptions={selectedOptions}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        selectOption={handleSelectOption}
      />

      {singleCases1 && valuesaved == false ? (
        singleCases1.length > 0 ? (
          <Card>
            <CardBody style={{ marginTop: "5rem" }}>
              <TableContainer
                columns={dynamicColumns}
                data={singleCases1} // Pass your custom data here
                isGlobalFilter={true}
                isAddOptions={false}
                order="asc"
                isPagination={true}
                customPageSize={10}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            </CardBody>
          </Card>
        ) : (
          <div className="alert alert-warning mt-3">
            <p className="text-primary mb-0">No Cases found</p>
          </div>
        )
      ) : (
        // Loader component or loading message
        <Spinners setLoading={setLoading} />
      )}
    </React.Fragment>
  )
}

PendingCasesList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(PendingCasesList)
