import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
} from "reactstrap"
import axios from "utils/api"

const ModalData = props => {
  const {
    isOpen,
    updateCaseIds,
    toggle,
    dropdownOptions,
    selectOption,
    selectedOptions,

    selectedClientData,
  } = props

  // Assuming clientData and updateCaseIds are defined somewhere
  let valueToSave
  // Find the matching object in updateCaseIds array based on clientData.caseId
  const matchingCase = updateCaseIds?.find(
    caseObj => caseObj?.caseId === selectedClientData?.caseId
  )

  if (matchingCase) {
    // If a matching case is found, extract the value
    valueToSave = matchingCase.value

    // Now you can save valueToSave wherever you need to.
  }

  const currentDate = new Date()
  const year = currentDate.getUTCFullYear()
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(currentDate.getUTCDate()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}`

  const [modalSelectedOption, setModalSelectedOption] = useState("")
  const [successPopupVisible, setSuccessPopupVisible] = useState(false)

  const handleSelectOption = e => {
    setModalSelectedOption(e.target.value)
  }
  useEffect(() => {
    // Reset selected option when the modal is opened
    setModalSelectedOption("")
  }, [isOpen])
  const handleButtonClick = async () => {
    try {
      if (!modalSelectedOption) {
        // Display an error or handle the situation where no option is selected
        console.error("No option selected")
        return
      }
      const authToken = sessionStorage.getItem("accessToken")

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }

      const response = await axios.put(
        `/admin/assign/${modalSelectedOption}/case/${selectedClientData._id}?referral=${valueToSave}&assign_date=${formattedDate}`,
        {},
        config
      )

      // Check if the message in the response is "case assigned success"
      if (response.data.message === "case assigned success") {
        // Display the success pop-up
        setSuccessPopupVisible(true)
      }

      // Optionally, you can perform additional actions based on the response
      setTimeout(() => {
        window.location.reload()
      }, 2000) // Refresh after 2 seconds (2000 milliseconds)
    } catch (error) {
      // Handle errors
      console.error("Axios error:", error)
    }
  }

  const renderModalBody = () => (
    <select
      className="form-select"
      value={modalSelectedOption}
      onChange={e => handleSelectOption(e)}
    >
      <option value="" disabled>
        Select Type
      </option>
      {dropdownOptions &&
        dropdownOptions?.map((option, index) => (
          <option key={index} value={option.id}>
            {`${option.firstName} ${option.lastName}`}
          </option>
        ))}
    </select>
  )
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-md"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Select Advocate</ModalHeader>
          <ModalBody>{renderModalBody()}</ModalBody>
          <ModalFooter>
            {/* {modalSelectedOption && (
            <p>Selected Option: {modalSelectedOption}</p>
          )} */}
            <Button
              type="button"
              disabled={!modalSelectedOption}
              color="primary"
              onClick={handleButtonClick}
            >
              Assign Case
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                selectOption(modalSelectedOption)
                toggle()
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={successPopupVisible}
        toggle={() => setSuccessPopupVisible(false)}
      >
        <Alert color="success">
          {/* Customize the success message as needed */}
          Case assigned successfully!
        </Alert>
      </Modal>
    </>
  )
}

ModalData.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  selectOption: PropTypes.func,
}

export default ModalData
