// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import axios from "utils/api";
import {
  GET_ADVOCATE,
  GET_ADVOCATE_SUCCESS,
  GET_ADVOCATE_FAIL,
  GET_SINGLE_CASES,
  GET_SINGLE_CASES_SUCCESS,
  GET_SINGLE_CASES_FAIL,
} from "./actionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAdvocateFail, getAdvocateSuccessAdmin,getSingleCasesFail, getSingleCasesSuccess } from "./actions";

function* fetchAdvocateAdmin() {
  try {
    const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
    const response = yield call(axios.get, "/admin/advocate/list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    yield put(getAdvocateSuccessAdmin(response.data));
  } catch (error) {
    yield put(getAdvocateFail(error));
    toast.error("Failed to fetch advocate data", { autoClose: 2000 });
  }
}

function* AdminSaga() {
  yield takeEvery(GET_ADVOCATE, fetchAdvocateAdmin);
}

export default AdminSaga;


