import React, { createContext, useContext, useState } from "react";

const LinkContext = createContext();

export const useLinkContext = () => useContext(LinkContext);

export const LinkProvider = ({ children }) => {
  const [linkId, setLinkId] = useState('');

  const value = {
    linkId,
    setLinkId,
  };

  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>;
};
