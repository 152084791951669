import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"
import axios from "utils/api"

const fireBaseBackend = getFirebaseBackend()

// function* editProfile({ payload: { user } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.editProfileAPI,
//         user.username,
//         user.idx
//       )
//       yield put(profileSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtProfile, "/post-jwt-profile", {
//         username: user.username,
//         idx: user.idx,
//       })
//       yield put(profileSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeProfile, {
//         username: user.username,
//         idx: user.idx,
//       })
//       yield put(profileSuccess(response))
//     }
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }
function* editProfile({ payload: { user } }) {
  const accessToken = sessionStorage.getItem("accessToken")
  const role = sessionStorage.getItem("role")
  let apiUrl
  if (role == "ADVOCATE") {
    apiUrl = "/advocate/update"
  } else {
    apiUrl = "admin/profile/update"
  }

  try {
    const response = yield call(
      axios.put,
      `${apiUrl}/${user.payload.user.id}`,
      {
        first_name: user.payload.user.username,
        last_name: user.payload.user.username2,

        // Include other fields as needed
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // Add other headers as needed
        },
      }
    )

    const resp = yield put(profileSuccess(response))

    const successMessage = response?.payload?.data?.success

    if (successMessage) {
      toast.success("Profile Updated Successfully", { autoClose: 2000 })
    }
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
