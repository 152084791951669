import { createContext, useContext, useEffect, useState } from "react"
import axios from "utils/api"
import React from "react"
const SchoolContext = createContext()

export const SchoolProvider = ({ children }) => {
  const [schoolNames, setSchoolNames] = useState([])
  const [SchoolDistrict, setSelectedDistrict] = useState("") // State to hold selected district

  useEffect(() => {
    // Your API endpoint
    const apiUrl = `https://api.picdpeop.org/admin/schoolData?SchoolDistrict=${SchoolDistrict}
    `

    // Your access token (replace 'YOUR_ACCESS_TOKEN' with the actual token)
    const accessToken = sessionStorage.getItem("accessToken")

    // Axios GET request configuration
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        // You can add other headers if needed
      },
    }

    // Making the GET request using Axios
    axios
      .get(apiUrl)
      .then(response => {
        // Handle the successful response here
        const extractedSchoolNames = response?.data?.data?.school?.map(
          school => school.schoolName
        )
        setSchoolNames(extractedSchoolNames)
      })
      .catch(error => {
        // Handle the error here
        console.error("Error fetching data:", error)
      })
  }, [SchoolDistrict]) //

  // Sending the selected district to the API endpoint could be implemented here if needed.

  return (
    <SchoolContext.Provider
      value={{ schoolNames, SchoolDistrict, setSelectedDistrict }}
    >
      {/* Include selectedDistrict */}
      {children}
    </SchoolContext.Provider>
  )
}

export const useSchoolContext = () => {
  return useContext(SchoolContext)
}
