import { configureStore } from "@reduxjs/toolkit"
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux"
import { persistStore, persistReducer } from "redux-persist"
import rootReducer, { rootPersistConfig } from "./rootReducer"

// ----------------------------------------------------------------------

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
})

export const persistor = persistStore(store)

const { dispatch } = store

const useSelector = useAppSelector

const useDispatch = () => useAppDispatch()

export { dispatch, useSelector, useDispatch }
