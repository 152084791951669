import React, { Fragment, useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import autoTable from "jspdf-autotable"
import logo from "../../assets/images/Final.png"
import { useDateContext } from "Context/DateContext"

import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import jsPDF from "jspdf"

import { Table, Row, Col, Button, Badge } from "reactstrap"
import { Link, useNavigate, useNavigation } from "react-router-dom"


// Define a default UI for filtering

const TableConatinerReport = ({
  columns,
  boolvalue,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const navigate = useNavigate()
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  const Handlefuncc = () => {
    navigate("/AdvocateRegister")
  }

  const pdfTableRef = useRef(null)

  const generatePDF = () => {
    const pdf = new jsPDF()
    const imagedata = logo // Assuming 'logo' is defined or imported

    // Image formatting
    const imgWidth = 60
    const imgHeight = 60
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const xPos = (pdfWidth - imgWidth) / 2
    pdf.addImage(imagedata, "JPEG", xPos, 0, imgWidth, imgHeight)

    // Table data preparation
    const columns = [
      "Case Id",
      "Child Name",
      "Assign Date",
      "Updated Status",
      "Advocate",
      "School Name",
      "Grade",
      "County",
      "Education",
    ]
    const detailsColumnIndex = headerGroups[0].headers.findIndex(
      column => column.id === "Case Id"
    )

    // Efficient column filtering (avoids unnecessary splicing)
    const filteredColumns =
      detailsColumnIndex !== -1
        ? columns
            .slice(0, detailsColumnIndex)
            .concat(columns.slice(detailsColumnIndex + 1))
        : columns

    // Data extraction with safer cell value handling
    const flattenedRows = page.map(row => {
      const cellValues = row.cells.map(cell => {
        // Use optional chaining to gracefully handle undefined values
        return cell.value?.toString().replace(/"/g, "") || ""
      })
      return cellValues
    })

    // Set table starting point
    const startY = 60 // Adjust as needed

    // Create the table without modifying original headerGroups
    const columnWidths = [
      "20",
      "50",
      "30",
      "40",
      "20",
      "40",
      "20",
      "20",
      "30",
      "25",
      "30",
      "40",
    ] // Adjusted widths
    pdf.setFontSize(4) // Set a smaller font size
    pdf.autoTable({
      head: [filteredColumns],
      body: flattenedRows,
      startY: startY,
      splitTable: true,
      margin: { left: 5, right: 5 }, // No left and right margin

      columnWidth: columnWidths,
    })

    pdf.save("table_data.pdf")
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions && (
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}

        
        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={Handlefuncc}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Advocate
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive  " style={{ overflowY: "unset" }}>
        <Table {...getTableProps()} className={tableClass} ref={pdfTableRef}>
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    className={column.isSort ? "sorting" : ""}
                  >
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => (
                      <td key={cell.id} {...cell.getCellProps()}>
                        {cell.value === "OPEN" ||
                        cell.value === "CLOSED" ||
                        cell.value === "PENDING" ? (
                          <Badge
                            className={
                              cell.value === "OPEN"
                                ? "badge-soft-success"
                                : cell.value === "CLOSED"
                                ? "badge-soft-dark"
                                : cell.value === "URGENT"
                                ? "badge-soft-danger"
                                : cell.value === "NON_URGENT"
                                ? "badge-soft-info"
                                : ""
                            }
                          >
                            {cell.value}
                          </Badge>
                        ) : cell.value === "NON_URGENT" ? (
                          <Badge className="badge-soft-info">Non Urgent</Badge>
                        ) : cell.value === "URGENT" ? (
                          <Badge className="badge-soft-danger">Urgent</Badge>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      {isPagination && pageOptions.length > 1 && (
        <Row className="justify-content-between align-items-center">
          {isShowingPageLength && (
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            </div>
          )}
          <div className={paginationDiv}>
            <ul className={`${pagination} list-unstyled d-flex flex-wrap`}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageOptions.slice(0, 5).map((item, key) => (
                <React.Fragment key={key}>
                  <li
                    className={
                      pageIndex === item ? "page-item active" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => gotoPage(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}

      {boolvalue && (
        <Col sm="7" xxl="8" className="d-flex">
          <div className="text-sm-end">
            <Button
              type="button"
              style={{ backgroundColor: "#024E80", color: "white" }}
              className="btn mb-2 me-2"
              onClick={generatePDF}
            >
              <i className="mdi mdi-file-pdf-outline me-1" />
              Generate PDF
            </Button>
          </div>
        </Col>
      )}
    </Fragment>
  )
}

TableConatinerReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableConatinerReport
