import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"

import { Button, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
import { fetchCasesRequest } from "store/advocate/actions"
import Modal from "pages/AdvocatesDashboard/Modal"
import ModalStreatagy from "pages/AdvocatesDashboard/Modal2"
import axios from "utils/api"
import Spinners from "components/Common/Spinner"
import { useNavigate } from "react-router-dom"
// function format date

const LatestTransaction2 = props => {
  // use effect
  const { value } = props
  const [selectedOption, setSelectedOption] = useState("")

  const handleSelectOption = option => {
    setSelectedOption(option)
  }
  const accessToken = sessionStorage.getItem("accessToken")
  const dispatch = useDispatch()

  const [Response, setResponse] = useState("")
  const [loading, setLoading] = useState(true)

  const [selectoption, setSelectoption] = useState("")
  const [advocateDatas, setadvocateDatas] = useState("")

  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)

  useEffect(() => {
    // Fetch OPEN cases
    dispatch(fetchCasesRequest({ status: "CLOSED" }))
  }, [dispatch])
  const singleCases1 = useSelector(state => state?.advocate?.cases)
  const storeState = useSelector(state => state)
  const valuesaved = useSelector(state => state?.advocate?.loading)
  //     // Function to make the API call

  useEffect(() => {
    setLoading(valuesaved)
  }, [singleCases1, valuesaved])
  const [modal1, setModal1] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)

  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }
  const toggleViewModal3 = clientData => {
    setSelectedClientData(clientData)

    setModal3(!modal3)
  }
  const toggleViewModal4 = clientData => {
    setSelectedClientData(clientData)

    setModal4(!modal4)
  }

  const [modal2, setModal2] = useState(false)
  // update case status by closed
  const toggleViewModal2 = async clientData => {
    try {
      // Fetch data based on clientData.caseId
      const caseId = clientData?._id

      // Check if caseId is valid before making the API call
      if (caseId) {
        const accessToken = sessionStorage.getItem("accessToken")
        const response = await axios.get(
          `/advocate/complaint/close/${caseId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      } else {
        // Handle the case where caseId is not valid
        console.error("Invalid caseId")
      }
    } catch (error) {
      // Handle errors if the API call fails
      console.error("Error fetching data:", error)
    }
  }
  function capitalizeFirstLetter(inputString) {
    if (inputString == "OPEN")
      return (
        inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
      )
    else return ""
  }

  // Example usage:
  let outputText = capitalizeFirstLetter(value)
  //nav
  const navigate = useNavigate()
  const handleLinkClick = (cellValue, data) => {
    // Navigate to the desired URL when the link is clicked, passing selectedClientData as state
    navigate(`/case-description/${cellValue}`, { state: { rowData: data } })
  }
  useEffect(() => {
    const fetchAdvocateList = async () => {
      const accessToken = sessionStorage.getItem("accessToken")
      try {
        const response = await axios.get("/admin/advocate/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })

        const variable = response?.data?.content

        const firstNamesWithIds = variable.map(item => ({
          id: item?._id,
          firstName: item?.first_name,
        }))
        setDropdownOptions(firstNamesWithIds)

        const advocateId = variable?.map(item => item?._id)
        setid(advocateId)
        setadvocateDatas(variable)
      } catch (error) {
        console.error("Error fetching advocate list:", error)
      }
    }

    const fetchData = async () => {
      setLoadingOptions(true)
      await fetchAdvocateList()
      // await assignCaseToAdvocate();
      setLoadingOptions(false)
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once

  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"
      case "isClosed":
        return "Update Status"

      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      case "isClosed":
        return "Updated Status"
      case "advocate_name":
        return "Advocate"
      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const dynamicColumns = useMemo(() => {
    if (!singleCases1 || singleCases1.length === 0) return []

    const keysToDisplay = [
      "caseId",
      "date",
      "child_name",
      "isClosed",
      "advocate_name",
    ]

    const columns = keysToDisplay.map(key => ({
      Header: getCustomHeaderName(key),
      accessor: key, // Make sure accessor is defined for each column
      disableFilters: true,
      Cell: cellProps => {
        if (key === "caseId") {
          // Render Case ID as a button or link, or any other UI element you prefer
          return (
            <a
              className="link-primary override-text-decoration"
              style={{
                color: "blue",
                textDecoration: "underline !important",
              }}
              onClick={() =>
                handleLinkClick(cellProps.value, cellProps.row.original)
              }
            >
              {cellProps.value}
            </a>
          )
        }
        if (key === "date" && cellProps.row.original[key]) {
          const [datePart, timePart] = cellProps.row.original[key].split("T")
          const [year, month, day] = datePart.split("-")
          return <span>{`${day}-${month}-${year}`}</span>
        } else {
          return <span>{cellProps.row.original[key]}</span>
        }
      },
    }))

    if (value == "OPEN") {
      // columns.push({
      //   Header: "Case Active",
      //   disableFilters: true,
      //   accessor: "view2",
      //   Cell: cellProps => (
      //     <Button
      //       type="button"
      //       color="primary"
      //       className="btn-sm btn-rounded"
      //       onClick={() => toggleViewModal2(cellProps.row.original)}
      //     >
      //       Open Case
      //     </Button>
      //   ),
      // });
      columns.push({
        Header: "Strategy Lab",
        disableFilters: true,
        accessor: "view0",
        Cell: cellProps => {
          const rowIndex = cellProps.row.index
          return (
            <select
              onChange={() => toggleViewModal4(cellProps.row.original)}
              className="form-select  form-select-md"
              aria-label="Small select examples"
              style={{ padding: "0.1rem 0.1rem" }}
            >
              <option value="" disabled selected>
                Select Value
              </option>
              <option>Strategy Lab</option>
              <option>Others</option>
            </select>
          )
        },
      })

      columns.push({
        Header: "Closed Case",
        disableFilters: true,
        accessor: "view3",
        Cell: cellProps => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => toggleViewModal3(cellProps.row.original)}
          >
            Case Closed
          </Button>
        ),
      })
      // columns.push({
      //   Header: "StatusCase",
      //   accessor: "StatusCase",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     if (value === 'OPEN') {
      //       // Render PaymentStatus3 if value is OPEN
      //       return <PaymentStatus3 {...cellProps} />;
      //     } else {
      //       // Otherwise, render PaymentStatus2
      //       return <PaymentStatus2 {...cellProps} />;
      //     }
      //   },
      // });
    }

    return columns
  }, [value, singleCases1])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />
      <Modal
        isOpen={modal3}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal3}
        toggleViewModal2={toggleViewModal2}
      />
      <ModalStreatagy
        isOpen={modal4}
        value={true}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal4}
        toggleViewModal2={toggleViewModal2}
      />
      <ModalData
        isOpen={modal2}
        toggle={toggleViewModal2}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        Id={Id}
        selectOption={handleSelectOption}
        advocateDatas={advocateDatas}
      />
      {singleCases1 && valuesaved == false ? (
        singleCases1.length > 0 ? (
          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">{outputText} Cases</div>
              <TableContainer
                columns={dynamicColumns}
                data={singleCases1} // Pass your custom data here
                isGlobalFilter={true}
                isAddOptions={false}
                order="asc"
                isPagination={true}
                customPageSize={10}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            </CardBody>
          </Card>
        ) : (
          <div className="alert alert-warning mt-3">
            <p className="text-primary mb-0">No Cases found</p>
          </div>
        )
      ) : (
        // Loader component or loading message
        <Spinners setLoading={setLoading} />
      )}
    </React.Fragment>
  )
}

LatestTransaction2.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTransaction2)
