import React from 'react';

const TimeIntervalFilter = ({ selectedInterval, handleIntervalChange }) => {
  const intervals = ['Weekly', 'Monthly', 'Quarterly', 'Yearly'];

  return (
    <select
      className="form-select"
      value={selectedInterval}
      onChange={e => handleIntervalChange(e.target.value)}
    >
      <option value="">Select Interval</option>
      {intervals.map(interval => (
        <option key={interval} value={interval.toLowerCase()}>
          {interval}
        </option>
      ))}
    </select>
  );
};

export default TimeIntervalFilter;
