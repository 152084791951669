import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  const accessToken = sessionStorage.getItem("accessToken");

  if (!accessToken) {
    // Redirect to the login page if no access token is present
    return <Navigate to="/" />;
  }

  // Render the children components if the access token is present
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
