import {
  React,
  useState,
  useEffect,
  useNavigate,
  logo,
  Select,
  Container,
  Button,
  Col,
  Label,
  Input,
  Form,
  Row,
  ToastHeader,
  ToastBody,
  CardBody,
  Card,
  Toast,
  Breadcrumbs,
  useLinkContext,
  Link,
  useParams,
  Field,
  useFormik,
  Spinners,
  Yup,
  axios,
  useSchoolContext,
  useClasssificationContext,
} from "../Admin/Form/import"
//data
import { useFormikConfig } from "../Admin/Form/formikConfig"
import {
  raceOptions,
  displayRaceOptions,
  displayOptionsWithUnderscores,
  educationOptions,
  primaryComplaint,
  programs,
  displayEducationOptions,
} from "../Admin/Form/data"
import CustomSelect from "pages/Admin/Form/CustomSelect"
const ManualForm = () => {
  //meta title
  document.title = "Client Intake | dpeopconnect"
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  }

  //use State //use context
  const { linkId, setLinkId } = useLinkContext()
  const { Classsification } = useClasssificationContext()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState("")

  const [selectedprograms, setselectedprograms] = useState([])
  const [otherRace, setOtherRace] = useState("")
  const [selectedEducation, setSelectedEducation] = useState([])
  const [otherEducation, setOtherEducation] = useState("")
  const { linkId: routeLinkId } = useParams() // Retrieve linkId from route params
  //use effect
  const [loading, setLoading] = useState(true)
  const [myDoc, setMyDoc] = useState({
    uplodeDoc: [], // Array to store uploaded documents
  })
  const { schoolNames, setSelectedDistrict } = useSchoolContext()

  //  functions
  //

  const handleProgramChange = selectedOptions2 => {
    setselectedprograms(selectedOptions2)
  }
  const handleOtherRaceChange = event => {
    setOtherRace(event.target.value)
    formik.setFieldValue("race", "OTHER: " + event.target.value)
  }

  const handleEducationChange = selectedOptions => {
    setSelectedEducation(selectedOptions)
  }
  const isOtherSelected = selectedEducation.some(
    option => option.value === "OTHER"
  )

  const {
    formik,
    toast,
    selectedRace,
    selectedPrimary,
    handlePrimary,
    handleRaceChange,
    toggleToast,
    handleFileChange,
    submissionSuccess,
  } = useFormikConfig()

  // school get
  useEffect(() => {
    setSelectedDistrict(formik.values.schoolDistrict) // Set selectedDistrict when schoolDistrict changes
  }, [formik.values.schoolDistrict, Classsification])
  console.log(formik.values, Classsification)
  return (
    <React.Fragment>
      {submissionSuccess ? (
        <div className="page-content" style={{ paddingTop: "0" }}>
          {/* Success message goes here */}
          <Row>
            <Col xl={12}>
              <Card>
                <div>
                  <div
                    className="position-fixed top-50 start-50 translate-middle p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast}>
                      <ToastBody>
                        Form has been Submitted Successfully
                      </ToastBody>
                    </Toast>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>{" "}
        </div>
      ) : (
        <div className="page-content">
          <Container
            className=" container-custom
           "
          >
            <Form onSubmit={formik.handleSubmit}>
              {/* ... other form elements ... */}
              <div className=" d-flex  mt-0 justify-content-center fw-bold">
                <strong className="fs-4">
                  MANUAL CLIENT INTAKE INFORMATION{" "}
                </strong>
              </div>
              <div className="mb-3 mt-4">
                <h4 className="fw-bold">Personal Bio</h4>
              </div>
              <div className="row mt-4">
                <div className="col-md-6 mb-3">
                  <Label htmlFor="parentName">Parents First Name:</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="parentName"
                    placeholder="Enter Your Parent's First Name"
                    onChange={formik.handleChange}
                    value={formik.values.parentName}
                  />
                  {formik.errors.parentName && formik.touched.parentName && (
                    <div className="text-danger">
                      {formik.errors.parentName}
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <Label htmlFor="parentName">Parents Last Name:</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="parentName2"
                    placeholder="Enter Your Parent's Last Name"
                    onChange={formik.handleChange}
                    value={formik.values.parentName2}
                  />
                  {formik.errors.parentName2 && formik.touched.parentName2 && (
                    <div className="text-danger">
                      {formik.errors.parentName2}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <Label htmlFor="homeAddress">Home Address:</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="homeAddress"
                    placeholder="Enter Your Home Address"
                    onChange={formik.handleChange}
                    value={formik.values.homeAddress}
                  />
                  {formik.errors.homeAddress && formik.touched.homeAddress && (
                    <div className="text-danger">
                      {formik.errors.homeAddress}
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <Label htmlFor="email">Email:</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="text-danger">{formik.errors.email}</div>
                  )}
                </div>
              </div>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="homePhone">Home Phone:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="homePhone"
                      placeholder="Enter Your Home Phone"
                      onChange={formik.handleChange}
                      value={formik.values.homePhone}
                    />
                    {formik.errors.homePhone && formik.touched.homePhone && (
                      <div className="text-danger">
                        {formik.errors.homePhone}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="cellPhone">Cell Phone:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="cellPhone"
                      placeholder="Enter Your Cell Phone"
                      onChange={formik.handleChange}
                      value={formik.values.cellPhone}
                    />
                    {formik.errors.cellPhone && formik.touched.cellPhone && (
                      <div className="text-danger">
                        {formik.errors.cellPhone}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="row mb-3">
                <label className="col-form-label col-sm-4">
                  I Prefer to be contacted via:
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox1"
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="checkbox1">
                      Home Phone
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox2"
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="checkbox2">
                      Cell Phone
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox3"
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="checkbox3">
                      Email
                    </label>
                  </div>

                  {formik.errors.atLeastOneCheckbox &&
                    formik.touched.checkbox1 &&
                    formik.touched.checkbox2 &&
                    formik.touched.checkbox3 && (
                      <div className="text-danger">
                        {formik.errors.atLeastOneCheckbox}
                      </div>
                    )}
                </div>
              </div>
              <div className="mb-3 fw-bold mt-4">
                <h4 className="fw-bold"> School Info</h4>
              </div>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="county">Select County:</Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="county"
                      name="county"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.county}
                    >
                      <option
                        value=""
                        label="Select County"
                        disabled
                        selected
                      />
                      <option value="Sussex">Sussex</option>
                      <option value="New Castle">New Castle</option>
                      <option value="Kent">Kent</option>
                    </Input>
                    {formik.errors.county && formik.touched.county && (
                      <div className="text-danger">{formik.errors.county}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="schoolDistrict">Select District:</Label>
                    <Input
                      className="form-control"
                      type="select"
                      id="schoolDistrict"
                      name="schoolDistrict"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.schoolDistrict}
                    >
                      <option value="" label="Select District" default hidden />
                      <option value="Appoquinimink School District">
                        Appoquinimink School District
                      </option>
                      <option value="Brandywine School District">
                        Brandywine School District
                      </option>
                      <option value="Caesar Rodney School District">
                        Caesar Rodney School District
                      </option>
                      <option value="Cape Henlopen School District">
                        Cape Henlopen School District
                      </option>
                      <option value="Capital School District">
                        Capital School District
                      </option>
                      <option value="Christina School District">
                        Christina School District
                      </option>
                      <option value="Colonial School District">
                        Colonial School District
                      </option>
                      <option value="Delmar School District">
                        Delmar School District
                      </option>
                      <option value="Indian River School District">
                        Indian River School District
                      </option>
                      <option value="Lake Forest School District">
                        Lake Forest School District
                      </option>
                      <option value="Laurel School District">
                        Laurel School District
                      </option>
                      <option value="Milford School District">
                        Milford School District
                      </option>
                      <option value="New Castle County Vocational-Technical School District">
                        New Castle County Vocational-Technical School District
                      </option>
                      <option value="Polytech School District">
                        Polytech School District
                      </option>
                      <option value="Red Clay Consolidated School District">
                        Red Clay Consolidated School District
                      </option>
                      <option value="Seaford School District">
                        Seaford School District
                      </option>
                      <option value="Smyrna School District">
                        Smyrna School District
                      </option>
                      <option value="Sussex Technical School District">
                        Sussex Technical School District
                      </option>
                      <option value="Woodbridge School District">
                        Woodbridge School District
                      </option>
                      {/* Add more options for other school districts */}
                    </Input>
                    {formik.errors.schoolDistrict &&
                      formik.touched.schoolDistrict && (
                        <div className="text-danger">
                          {formik.errors.schoolDistrict}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              <Row>
                {/* ... (existing code) */}

                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="schoolName">Select School:</Label>
                    <Input
                      className="form-control"
                      type="select"
                      id="schoolName"
                      name="schoolName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.schoolName}
                    >
                      <option value="" label="Select School" default hidden />
                      {schoolNames?.map((schoolName, index) => (
                        <option key={index} value={schoolName}>
                          {schoolName}
                        </option>
                      ))}
                    </Input>
                    {formik.errors.schoolName && formik.touched.schoolName && (
                      <div className="text-danger">
                        {formik.errors.schoolName}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="principal">Principal:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="principal"
                      autoComplete="off"
                      placeholder="Enter Name"
                      onChange={formik.handleChange}
                      value={formik.values.principal}
                    />
                    {formik.errors.principal && formik.touched.principal && (
                      <div className="text-danger">
                        {formik.errors.principal}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="street">Street:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="street"
                      placeholder="School Street"
                      onChange={formik.handleChange}
                      value={formik.values.street}
                    />
                    {formik.errors.street && formik.touched.street && (
                      <div className="text-danger">{formik.errors.street}</div>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="city">City:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="city"
                      placeholder="School City"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <div className="text-danger">{formik.errors.city}</div>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="zip">Zip:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="zip"
                      placeholder="Enter Zip Code"
                      onChange={formik.handleChange}
                      value={formik.values.zip}
                    />
                    {formik.errors.zip && formik.touched.zip && (
                      <div className="text-danger">{formik.errors.zip}</div>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="schoolPhone">School Phone:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="schoolPhone"
                      placeholder="School Phone Number"
                      onChange={formik.handleChange}
                      value={formik.values.schoolPhone}
                    />
                    {formik.errors.schoolPhone &&
                      formik.touched.schoolPhone && (
                        <div className="text-danger">
                          {formik.errors.schoolPhone}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              <div className="mb-3 fw-bold mt-4">
                <h4 className="fw-bold">Student Info</h4>
              </div>
              <Row>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="childName">Child Name:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="childName"
                      placeholder="Enter Child Name"
                      onChange={formik.handleChange}
                      value={formik.values.childName}
                    />
                    {formik.errors.childName && formik.touched.childName && (
                      <div className="text-danger">
                        {formik.errors.childName}
                      </div>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="dob">Date of birth:</Label>

                    <Input
                      className="form-control"
                      placeholder="MM/DD/YYYY"
                      type="date"
                      id="dob"
                      name="dob"
                      onChange={formik.handleChange}
                      value={formik.values.dob}
                    />
                    {formik.errors.dob && formik.touched.dob && (
                      <div className="text-danger">{formik.errors.dob}</div>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="gender">Gender:</Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="gender"
                      onChange={formik.handleChange}
                      value={formik.values.gender}
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">FEMALE</option>
                      <option value="OTHER">Other</option>
                    </Input>
                    {formik.errors.gender && formik.touched.gender && (
                      <div className="text-danger">{formik.errors.gender}</div>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="pronoun">Pronouns:</Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="pronoun"
                      onChange={formik.handleChange}
                      value={formik.values.pronoun}
                    >
                      <option value="">Select Pronouns</option>
                      <option value="he">He/Him</option>
                      <option value="she">She/Her</option>
                      <option value="other">OTHER</option>
                      <option value="they">They/Them</option>

                      {/* Add additional pronoun options as needed */}
                    </Input>
                    {formik.errors.pronoun && formik.touched.pronoun && (
                      <div className="text-danger">{formik.errors.pronoun}</div>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="ethnicity">Ethnicity:</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="ethnicity"
                      placeholder="Enter Ethnicity"
                      onChange={formik.handleChange}
                      value={formik.values.ethnicity}
                    />
                    {formik.errors.ethnicity && formik.touched.ethnicity && (
                      <div className="text-danger">
                        {formik.errors.ethnicity}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="grade">Grade:</Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="grade"
                      onChange={formik.handleChange}
                      value={formik.values.grade}
                    >
                      <option value="" disabled selected>
                        Select Grade
                      </option>
                      <option value="Pre-K">Pre-K</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="Other">Other</option>
                    </Input>
                    {formik.errors.grade && formik.touched.grade && (
                      <div className="text-danger">{formik.errors.grade}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="" style={{ marginBottom: "30px" }}>
                <Row className="mb-3">
                  <Col sm={6}>
                    <Label htmlFor="race" className="col-sm-4 col-form-label">
                      Race:
                    </Label>
                    <select
                      id="race"
                      name="race"
                      className="form-select"
                      value={selectedRace}
                      onChange={handleRaceChange}
                    >
                      {raceOptions.map(option => (
                        <option key={option} value={option}>
                          {displayRaceOptions[option]}
                        </option>
                      ))}
                    </select>
                    {formik.touched.race && formik.errors.race && (
                      <div className="text-danger">{formik.errors.race}</div>
                    )}
                    {selectedRace === "OTHER" && (
                      <Input
                        type="text"
                        id="otherRace"
                        name="otherRace"
                        className="mt-2 form-control"
                        placeholder="Enter Other Race"
                        value={otherRace}
                        onChange={handleOtherRaceChange}
                      />
                    )}
                  </Col>
                  <Col sm={6}>
                    <Label
                      htmlFor="primaryComplaint"
                      className="col-sm-4 col-form-label"
                    >
                      Primary Complaint:
                    </Label>
                    <Input
                      id="primaryComplaint"
                      type="select"
                      name="primaryComplaint"
                      className="form-select"
                      value={selectedPrimary}
                      onChange={handlePrimary}
                    >
                      <option value="" disabled>
                        Select Primary Complaint
                      </option>
                      {primaryComplaint.map(option => (
                        <option key={option} value={option}>
                          {displayOptionsWithUnderscores[option]}
                        </option>
                      ))}
                    </Input>
                    {formik.touched.primaryComplaint &&
                      formik.errors.primaryComplaint && (
                        <div className="text-danger">
                          {formik.errors.primaryComplaint}
                        </div>
                      )}
                  </Col>
                </Row>
              </div>
              <CustomSelect formik={formik} />

              <Row className="mb-3">
                <Label className="col-form-label col-sm-6">
                  Please check the type(s) of educational issues that apply:
                </Label>
                <Col sm={6}>
                  <Select
                    id="education"
                    name="education"
                    isMulti
                    value={selectedEducation}
                    styles={customStyles}
                    onChange={selectedOptions => {
                      handleEducationChange(selectedOptions)
                      formik.setFieldValue("education", selectedOptions)
                      formik.handleBlur("education")
                    }}
                    options={educationOptions?.map(option => ({
                      value: option,
                      label: displayEducationOptions[option],
                    }))}
                  />
                  {formik.touched.education && formik.errors.education && (
                    <div className="text-danger">{formik.errors.education}</div>
                  )}

                  {selectedEducation.some(
                    option => option.value === "OTHER"
                  ) && (
                    <>
                      <Label className="col-form-label col-sm-4 ">
                        Enter Other Education Issue:
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          id="otherEducation"
                          name="otherEducation"
                          className="mb-4 form-control"
                          placeholder="Enter Other Education Issue"
                          value={formik.values.otherEducation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.otherEducation &&
                          formik.errors.otherEducation && (
                            <div className="text-danger">
                              {formik.errors.otherEducation}
                            </div>
                          )}
                      </Col>
                    </>
                  )}
                </Col>
              </Row>

              <Row>
                <Label className="col-form-label col-sm-6">
                  Are you or your household currently participating in any of
                  the following programs? (Please select all that apply)
                </Label>
                <Col sm={6}>
                  <Select
                    id="programs"
                    name="programs"
                    isMulti
                    value={selectedprograms}
                    styles={customStyles}
                    onChange={selectedOptions2 => {
                      handleProgramChange(selectedOptions2)
                      formik.setFieldValue("programs", selectedOptions2)
                      formik.handleBlur("programs")
                    }}
                    options={programs?.map(option => ({
                      value: option,
                      label: option,
                    }))}
                  />

                  {formik.touched.programs && formik.errors.programs && (
                    <div className="text-danger">{formik.errors.programs}</div>
                  )}
                </Col>
              </Row>

              <div className="mb-3">
                <Label htmlFor="commentMessage-input">
                  Please briefly state the nature of the problem you wish to
                  discuss with this office.
                </Label>
                <textarea
                  className={`form-control ${
                    formik.touched.commentMessage &&
                    formik.errors.commentMessage
                      ? "is-invalid"
                      : ""
                  }`}
                  id="commentMessage-input"
                  name="commentMessage"
                  value={formik.values.commentMessage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Your message..."
                  rows="8"
                ></textarea>
                {formik.touched.commentMessage &&
                formik.errors.commentMessage ? (
                  <div className="invalid-feedback">
                    {formik.errors.commentMessage}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <Label htmlFor="howDidYouHear-input">
                  How did you hear about us?
                </Label>
                <Input
                  type="text"
                  className={`form-control ${
                    formik.touched.howDidYouHear && formik.errors.howDidYouHear
                      ? "is-invalid"
                      : ""
                  }`}
                  id="howDidYouHear-input"
                  name="howDidYouHear"
                  value={formik.values.howDidYouHear}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter text"
                />
                {formik.touched.howDidYouHear && formik.errors.howDidYouHear ? (
                  <div className="invalid-feedback">
                    {formik.errors.howDidYouHear}
                  </div>
                ) : null}
              </div>
              <div className="mt-4">
                <div>
                  <Label className="form-label">Upload documents</Label>
                  <div className="input-group">
                    <Input
                      type="file"
                      className="form-control"
                      id="document"
                      name="document"
                      multiple
                      onChange={event =>
                        handleFileChange(event, formik.setFieldValue)
                      }
                    />
                  </div>
                  {formik.errors.uplodeDoc && formik.touched.uplodeDoc && (
                    <div className="text-danger">{formik.errors.uplodeDoc}</div>
                  )}
                </div>
              </div>

              {/* Render uploaded documents */}
              {formik.values.uplodeDoc.map((doc, index) => (
                <div key={index} className="mt-3">
                  <div className="d-flex align-items-center">
                    <p className="mb-0">
                      <i className="mdi mdi-file mr-2" />
                      {doc.file.name}
                    </p>
                    {/* Add delete functionality */}
                    <i
                      className="mdi mdi-delete font-size-18 mr-4"
                      id="deletetooltip"
                      onClick={() => {
                        const updatedDocs = formik.values.uplodeDoc.filter(
                          (_, i) => i !== index
                        )
                        formik.setFieldValue("uplodeDoc", updatedDocs)
                      }}
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </div>
                </div>
              ))}

              <div className="hstack gap-2 justify-content-end  mt-4">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ManualForm
