import { takeLatest, put, call } from 'redux-saga/effects';
import * as types from './actionTypes';
import axios from 'utils/api';
import {
  fetchCasesSuccess,
  fetchCasesFailure,
} from './actions';

function* fetchCasesSagaAdmin(action) {
  try {
    const { status } = action.payload;
    const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
    const response = yield call(
      axios.get,
      `admin/complaint/list?cases=${status}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    yield put(fetchCasesSuccess(response.data.content));
  } catch (error) {
    yield put(fetchCasesFailure(error.message));
  }
}

export function* watchAdminCasesSaga() {
  yield takeLatest(types.FETCH_CASES_REQUEST, fetchCasesSagaAdmin);
}
