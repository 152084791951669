import React, { useState, useEffect, useCallback } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const WeeklyCalendar = ({ onDatesChange }) => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(() => {
    onDatesChange({ startDate, endDate })
  }, [startDate, endDate, onDatesChange])

  return (
    <div className="d-flex gap-4 mt-3">
      <div>
        <DatePicker
          selected={startDate}
          placeholderText="Start Date"
          className="form-control"
          onChange={date => setStartDate(date)}
          dateFormat="MMMM d, yyyy"
          showWeekNumbers
          popperPlacement="bottom-start"
        />
      </div>
      <div>
        <DatePicker
          selected={endDate}
          placeholderText="End Date"
          className="form-control"
          onChange={date => setEndDate(date)}
          dateFormat="MMMM d, yyyy"
          showWeekNumbers
          popperPlacement="bottom-start"
        />
      </div>
    </div>
  )
}

const MonthlyCalendar = ({ onDatesChange }) => {
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    onDatesChange({ selectedDate })
  }, [selectedDate, onDatesChange])

  return (
    <div className="d-flex gap-4 mt-3">
      <DatePicker
        className="form-control"
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        popperPlacement="bottom-start"
      />
    </div>
  )
}

const QuarterlyCalendar = ({ onDatesChange }) => {
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    onDatesChange({ selectedDate })
  }, [selectedDate, onDatesChange])

  return (
    <div className="d-flex gap-4 flex-direction-column mt-3">
      <DatePicker
        selected={selectedDate}
        className="form-control"
        onChange={date => setSelectedDate(date)}
        dateFormat="MMMM yyyy"
        showQuarterYearPicker
        popperPlacement="bottom-start"
      />
    </div>
  )
}

const YearlyCalendar = ({ onDatesChange }) => {
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    onDatesChange({ selectedDate })
  }, [selectedDate, onDatesChange])

  return (
    <div className="d-flex  gap-4 mt-3">
      <DatePicker
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="yyyy"
        className="form-control"
        showYearPicker
        popperPlacement="top-start"
      />
    </div>
  )
}

const CalendarContainer = ({ onDatesChange }) => {
  const [selectedOption, setSelectedOption] = useState("")
  const [dates, setDates] = useState({})

  // Memoize the function with useCallback

  const handleDatesChange = useCallback(() => {
    // Format dates in YYYY-MM-DD format
    const formattedDates = Object.keys(dates).reduce((acc, key) => {
      if (key === "startQuarterDate") {
        // Calculate and set the end of the quarter month
        const startQuarterDate = new Date(dates[key])
        startQuarterDate.setMonth(startQuarterDate.getMonth() + 2)

        acc["endQuarterDate"] = new Date(
          startQuarterDate.getFullYear(),
          startQuarterDate.getMonth() + 1,
          1
        )

        // Add a new property 'endDate' specifically for the quarterly case
        acc["endDate"] = startQuarterDate.toISOString().slice(0, 10)
      } else {
        // Check if the date is defined before formatting
        acc[key] = dates[key]
          ? dates[key].toLocaleDateString("en-CA")
          : undefined
      }

      return acc
    }, {})

    // Debug logs

    if (selectedOption === "quarterly") {
      // Check if endQuarterDate is defined before formatting
      formattedDates["endQuarterDate"] = formattedDates["endQuarterDate"]
        ? formattedDates["endQuarterDate"].toLocaleDateString("en-CA")
        : undefined
    }

    // Debug logs

    // Pass both selected option and formatted dates to the callback
    onDatesChange({ selectedOption, dates: formattedDates })
  }, [selectedOption, dates, onDatesChange])

  // ... (rest of the code)

  useEffect(() => {
    // Access selectedOption and formatted dates here

    // Pass the state to the parent component or another component
    handleDatesChange()
  }, [selectedOption, dates, handleDatesChange])

  return (
    <div className="flex-direction-column">
      <select
        value={selectedOption}
        onChange={e => setSelectedOption(e.target.value)}
        className="form-select"
      >
        <option value="" disabled>
          Filter By
        </option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
        <option value="yearly">Yearly</option>
      </select>

      {selectedOption === "weekly" && (
        <WeeklyCalendar onDatesChange={setDates} />
      )}
      {selectedOption === "monthly" && (
        <MonthlyCalendar onDatesChange={setDates} />
      )}
      {selectedOption === "quarterly" && (
        <QuarterlyCalendar onDatesChange={setDates} />
      )}
      {selectedOption === "yearly" && (
        <YearlyCalendar onDatesChange={setDates} />
      )}
    </div>
  )
}

export default CalendarContainer
