import React, { useState, useEffect } from "react"
import axios from "utils/api"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  ModalBody,
  ModalHeader,
  Modal,
  Toast,
  ToastBody,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, profileError, resetProfileFlag } from "../../store/actions"
import { toast } from "react-toastify"
import { Base_API_KEY } from "config-global"
import { ToastContainer } from "react-toastify";

const UserProfile = () => {
  //meta title
  document.title = "Profile | dpeopconnect"

  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const[userId,setUserId]=useState('');
  const [newUsername, setNewUsername] = useState("");
  const [newUsername2, setNewUsername2] = useState("");


  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [selectedImage, setSelectedImage] = useState(null)
  const [binaryImage, setBinaryImage] = useState(null)
  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleImageUpload = event => {
    const file = event.target.files[0]

    if (file) {
      setSelectedImage(file)

      // Convert image to binary format
      convertImageToBinary(file)
    }
  }
  const convertImageToBinary = image => {
    const reader = new FileReader()

    reader.onloadend = () => {
      // Set the binary image in state
      setBinaryImage(reader.result)
    }
    reader.readAsDataURL(image)
  }

  const handleAccept = async () => {
    const token = sessionStorage.getItem("accessToken")

    try {
      if (binaryImage) {
        const formData = new FormData()
        const userId = profileData?._id
        setUserId
        formData.append("profile", selectedImage)
        const Role = sessionStorage.getItem("role")
        let url;
        if (Role == "ADVOCATE") {
          url = `/advocate/picture/${userId}`
        } else {
          url = `/admin/profile/picture/${userId}`
        }
        const response = await axios.put(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.statusText=='OK') {
          // Handle success
          setSubmissionSuccess(true)
          setTimeout(() => {
            window.location.reload();
          }, 100);
          
        } else {
          // Handle error
          console.error("Error uploading image")
        }

        // You might dispatch an action here if needed
        // dispatch(fetchUser());
      }

      toggleModal() // Close the modal
    } catch (error) {
      console.error("Error:", error)
    }
  }
  const selectProfileState = state => state.Profile
  const ProfileProperties = createSelector(selectProfileState, profile => ({
    error: profile.error,
    success: profile.success,
  }))

  const { error, success } = useSelector(ProfileProperties)

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     const obj = JSON.parse(localStorage.getItem("authUser"))
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName)
  //       setemail(obj.email)
  //       setidx(obj.uid)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username)
  //       setemail(obj.email)
  //       setidx(obj.uid)
  //     }
  //     setTimeout(() => {
  //       dispatch(resetProfileFlag())
  //     }, 3000)
  //   }
  // }, [dispatch, success])

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
   

  //   initialValues: {
  //     username: name || "",
    
  //   },
  //   validationSchema: Yup.object({
  //     username: Yup.string().required("Please Enter Your UserName"),
  //   }),
  //   onSubmit: values => {
  //     dispatch(editProfile(values))
  //   },
  // })
  // toast
  const toastComponent = (
    <Toast isOpen={submissionSuccess}>
      <div
        className="position-fixed  start-50 translate-middle p-3"
        style={{ zIndex: "1005", top: "2.875rem" }}
      >
        <Toast isOpen={toast}>
          <ToastBody>Image Uploaded Successfully</ToastBody>{" "}
        </Toast>
      </div>
    </Toast>
  )
  //toast
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")
    const Role = sessionStorage.getItem("role")

    // axios request configuration
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    // Make a GET request using Axios within useEffect
    if (Role != "ADVOCATE") {
      axios
        .get("admin/profile", axiosConfig)
        .then(response => {
          // Handle the successful response here
          setProfileData(response?.data?.content)
          setUserId(response?.data?.content?._id)
        })
        .catch(error => {
          // Handle errors here
          console.error("Error:", error)
        })
    } else {
      axios
        .get("advocate/profile", axiosConfig)
        .then(response => {
          // Handle the successful response here
          setProfileData(response?.data?.content)
          setUserId(response?.data?.content?._id)

        })
        .catch(error => {
          // Handle errors here
          console.error("Error:", error)
        })
    }
  }, [])
  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };  const handleUsernameChange2 = (e) => {
    setNewUsername2(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming you are using some form of form validation and the user details are available in the component state
    
    const updatedUser = {
      username: newUsername,
      username2:newUsername2,
      id:userId,
      // other properties as needed
    };

    // Dispatch the editProfile action
    // dispatch(editProfile({ payload: { user: updatedUser } }));
    const updateProfile = async () => {
      const accessToken=sessionStorage.getItem('accessToken');
      try {

        // const response = await axios.put(`/advocate/update/65b0a9d89adc3791e9e9cff1`, {
        //   first_name: updatedUser.username,
        //   // Include other fields as needed
        // }, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //     // Add other headers as needed
        //   },
        // });
        dispatch(editProfile({ payload: { user: updatedUser } }));
        toast.success("Profile Updated Successfully", { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        const value=dispatch(profileSuccess(response.data));
      } catch (error) {
        dispatch(profileError(error));
      }
    

    };

    updateProfile();
  };

  return (
    <>
      {toastComponent}

      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            {/* <Breadcrumb title="dpeopconnect" breadcrumbItem="Profile" /> */}

            <Row>
              <Col lg="12">
                {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
                {success ? <Alert color="success">{success}</Alert> : null} */}

                <>
                  <Card>
                    <CardBody>
                      <div className="position-relative">
                        <img
                          src={`${Base_API_KEY}/profile/${profileData?.profile_picture}`}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                        <Button
                          color="link"
                          onClick={toggleModal}
                          className="position-absolute  translate-middle p-0 icon-button"
                          style={{ border: "none", top: "55px", left: "50px" }}
                        >
                          <i
                            className="bx bx-image rounded-circle "
                            style={{ color: "#cf5a5a" }}
                          ></i>
                        </Button>
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{name}</h5>
                          <p className="mb-1">{email}</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Upload Image</ModalHeader>
                    <ModalBody>
                      {/* Add your image upload form or component here */}
                      <div style={{ marginBottom: "15px" }}>
                        <input type="file" onChange={handleImageUpload} />
                      </div>
                      <Button color="primary" onClick={handleAccept}>
                        Accept
                      </Button>
                    </ModalBody>
                  </Modal>
                </>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Details</h4>

            <Card>
              <CardBody>
              <Form onSubmit={handleSubmit}>
                  <div className="form-group  gap-4">
                    <div className="col-12">
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Enter First Name"
                      type="text"
                      value={newUsername}
                      onChange={handleUsernameChange}
                    />
                    </div>
                    <div className="col-12 mt-4">
                     <Label className="form-label">Last Name</Label>
                      <Input
                      name="username2"
                      className="form-control"
                      placeholder="Enter Last Name"
                      type="text"
                      value={newUsername2}
                      onChange={handleUsernameChange2}
                    />
                  </div>
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update User Name
                    </Button>
                  </div>
                  <ToastContainer />

                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>

      </React.Fragment>
    </>
  )
}

export default withRouter(UserProfile)
