import React, { useState, useRef } from "react"
import { Container, Button, Row, Col } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "../../utils/api"
import { useLinkContext } from "../../Context/LinkContext"
import { useNavigate } from "react-router-dom"

const Link = () => {
  const inputRef = useRef(null)

  const [userEmail, setUserEmail] = useState("")
  const [showLink, setShowLink] = useState(false)
  const { linkId, setLinkId } = useLinkContext()
  //use Navigate
  const navigate = useNavigate()

  const [link, setLink] = useState("") // State to store the link
  const handleCopyClick = () => {
    // Copy text from input field to clipboard
    inputRef.current.select()
    document.execCommand("copy")
  }
  const handleButtonClick = async () => {
    try {
      // Replace 'yourAccessToken' with the actual way you retrieve the access token in your application
      const accessToken = sessionStorage.getItem("accessToken")

      const response = await createLink(userEmail, accessToken)

      if (response.title === "Success") {
        const link = response.content

        // Extract the ID from the link
        const id = link.substring(response.content.lastIndexOf("/") + 1)

        setLink(link)
        setLinkId(id)

        setTimeout(() => {
          setShowLink(false)
          setLink("")
        }, 25 * 60 * 1000)
      }
    } catch (error) {
      console.error("Error creating link:", error)
    }
  }

  const createLink = async (email, accessToken) => {
    try {
      const response = await axios.post(
        "/admin/create_link",
        {
          email: "super-admin@gmail.com",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  document.title = "Create Link | dpeopconnect"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="mb-3">
            <Col md={10}>
              <div className="input-group">
                <input
                  ref={inputRef}
                  className="form-control"
                  type="text"
                  value={link}
                  placeholder="please click on the button to generate URL"
                />
                <div className="input-group-append">
                  <Button
                    color="secondary"
                    className="btn btn-secondary waves-effect waves-light"
                    onClick={handleCopyClick}
                  >
                    <i className="bx bx-copy"></i>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={10} className="d-flex">
              <Button
                color="primary"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleButtonClick}
              >
                Generate Link
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Link
