import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { dropRight, isEmpty } from "lodash"
import Spinners from "components/Common/Spinner"

import axios from "utils/api"
import { useDateContext } from "Context/DateContext"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
  Input,
} from "reactstrap" // Import the necessary components

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction } from "../../common/data/dashboard"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"
import ModalData from "pages/Ecommerce/EcommerceOrders/ModalData"
import ModalStreatagy from "pages/AdvocatesDashboard/Modal2"
import { useLocation, useNavigate } from "react-router-dom"

//redux
import { useDispatch, useSelector } from "react-redux"
import { getSingleCases } from "store/Admin/actions"
import { store } from "redux/store"
import { Base_API_KEY } from "config-global"

// function format date

const LatestTranaction = props => {
  // use effect

  const [selectedOptions, setSelectedOptions] = useState([])
  const [Lastvalue, setLastValue] = useState("")
  const [singlebtn1, setSinglebtn1] = useState(false)
  const { DateStart, setDateStart, DateEnd, setDateEnd } = useDateContext()

  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getSingleCases("", DateStart, DateEnd))
      } catch (error) {
        console.error("Error fetching data:", error)
        // Handle error if needed
      }
    }

    fetchData()
  }, [dispatch, DateStart, DateEnd])
  const navigate = useNavigate()

  const toggleDropdown = rowIndex => {
    const updatedStates = [...dropdownStates]
    updatedStates[rowIndex] = !updatedStates[rowIndex]
    setDropdownStates(updatedStates)
  }

  const handleSelectOption = (rowIndex, value) => {
    const updatedOptions = [...selectedOptions]
    updatedOptions[rowIndex] = value
    setSelectedOptions1(updatedOptions)
    setLastValue(value)
    toggleViewModal4(rowIndex, value)

    // Additional logic if needed
  }

  const [advocateDatas, setadvocateDatas] = useState("")
  const [seloption, setseloption] = useState("")

  const [selectedClientData, setSelectedClientData] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [Id, setid] = useState([])
  const [IndexValue, setIndexValue] = useState("")
  const [modal4, setModal4] = useState(false)

  const [modal1, setModal1] = useState(false)

  const memoizedAdminSingleData = useSelector(state => {
    return state?.admin?.singleCasesAdmin?.content
  })

  const adminSingleData = useMemo(
    () => memoizedAdminSingleData,
    [memoizedAdminSingleData]
  )

  const [dropdownStates, setDropdownStates] = useState(
    Array(adminSingleData?.length).fill(false)
  )
  const [selectedOptions1, setSelectedOptions1] = useState(
    Array(adminSingleData?.length).fill("")
  )
  const toggleViewModal = clientData => {
    setSelectedClientData(clientData)
    setModal1(!modal1)
  }

  //use State
  const loader = useSelector(state => {
    return state?.admin?.loading
  })
  const [loading, setLoading] = useState(loader)
  const [loadingOptions, setLoadingOptions] = useState(true)

  const [modal2, setModal2] = useState(false)

  const toggleViewModal2 = (rowIndex, clientData) => {
    setSelectedClientData(clientData)
    setIndexValue(rowIndex)

    setModal2(!modal2)
  }

  const toggleViewModal4 = (clientData, val) => {
    setSelectedClientData(clientData)
    setseloption(val)
    setModal4(!modal4)
  }
  useEffect(() => {
    const fetchAdvocateList = async () => {
      const accessToken = sessionStorage.getItem("accessToken")
      try {
        const response = await axios.get("/admin/advocate/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })

        const variable = response?.data?.content

        const firstNamesWithIds = variable?.map(item => ({
          id: item?._id,
          firstName: item?.first_name,
          lastName: item?.last_name,
          email: item?.email,
        }))

        setDropdownOptions(firstNamesWithIds)

        const advocateId = variable?.map(item => item?._id)
        setid(advocateId)
        setadvocateDatas(variable)
      } catch (error) {
        console.error("Error fetching advocate list:", error)
      }
    }

    const fetchData = async () => {
      setLoadingOptions(true)
      await fetchAdvocateList()
      // await assignCaseToAdvocate();
      setLoadingOptions(false)
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once

  const getCustomHeaderName = key => {
    // You can customize the header names based on the key
    switch (key) {
      case "caseId":
        return "Case Id"
      case "isClosed":
        return "Updated Status"
      case "child_name":
        return "Child Name"
      case "date":
        return "Date"
      case "advocate_name":
        return "Advocate"
      case "assign_date":
        return "Assign Date"
      case "referral":
        return "Urgency"
      case "student_grade":
        return "Grade"
      case "school_name":
        return "School Name"
      case "county":
        return "County"
      case "education":
        return "Education"

      default:
        return key // Use the key itself if no custom name is specified
    }
  }
  const HandleLinkCLick = (cellValue, data) => {
    // Navigate to the desired URL when the link is clicked, passing selectedClientData as state
    navigate(`/case-description/${cellValue}`, { state: { rowData: data } })
  }
  const dynamicColumns = useMemo(() => {
    if (!adminSingleData || adminSingleData?.length === 0) return []

    const keysToDisplay = [
      "caseId",
      "child_name",
      "assign_date",
      "isClosed",
      "advocate_name",
      "referral",
      "school_name",
      "student_grade",
      "county",
      "education",
    ]
    const educationMappings = {
      SPECIAL_EDUCATION: "Special Education",
      GENERAL_EDUCATION: "General Education",
      MCKINNEY_VENTO: "McKinney Vento",
      BUYYING: "Bullying",
      EP: "IEP",
      PLAN_504: "Plan 504",
      DISCIPLINE: "Discipline",
      OTHER: "Other",
    }
    return [
      ...keysToDisplay.map(key => ({
        Header: getCustomHeaderName(key),
        accessor: key,
        disableFilters: true,
        Cell: cellProps => {
          if (key === "caseId") {
            // Render Case ID as a button or link, or any other UI element you prefer
            return (
              <a
                className="link-primary override-text-decoration"
                style={{
                  color: "blue",
                  textDecoration: "underline !important",
                }}
                onClick={() =>
                  HandleLinkCLick(cellProps.value, cellProps.row.original)
                }
              >
                {cellProps.value}
              </a>
            )
          }
          if (key === "date" && cellProps.row.original[key]) {
            // Convert and format the date if the key is 'date'
            const [datePart, timePart] = cellProps.row.original[key].split("T")
            const [year, month, day] = datePart.split("-")

            return <span>{`${day}-${month}-${year}`}</span> // Adjust the formatting as needed
          } else if (key === "education" && cellProps.row.original[key]) {
            // Handle 'education' array
            const educationArray = cellProps.row.original[key]

            if (Array.isArray(educationArray)) {
              const formattedEducation = educationArray.map(
                value => educationMappings[value] || value
              )

              return <span>{formattedEducation.join(", ")}</span>
            } else {
              // Handle non-array values, or just return the original value
              return cellProps.row.original[key]
            }
          } else {
            // Display other properties as they are
            return <span>{cellProps.row.original[key]}</span>
          }
        },
      })),
      {
        Header: "Status",
        disableFilters: true,
        accessor: "view0",
        Cell: cellProps => {
          const rowIndex = cellProps.row.index

          return (
            <Dropdown
              isOpen={dropdownStates[rowIndex]}
              toggle={() => toggleDropdown(rowIndex)}
              className="mt-4 mt-sm-0"
            >
              <DropdownToggle
                className=""
                color=""
                style={{ backgroundColor: "#024E80", color: "white" }}
                caret
              >
                {selectedOptions1[rowIndex] || "Select Value"}{" "}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption(cellProps.row.original, "Strategy lab")
                  }
                >
                  Strategy lab
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption(cellProps.row.original, "RFR")
                  }
                >
                  RFR
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleSelectOption(cellProps.row.original, "Others")
                  }
                >
                  Others
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        },
      },
      // {
      //   Header: "Details",
      //   disableFilters: true,
      //   accessor: "view",
      //   Cell: cellProps => {
      //     return (
      //       <Button
      //         type="button"
      //         style={{ backgroundColor: "#024E80", color: "white" }}
      //         className="btn-sm btn-rounded"
      //         onClick={() => toggleViewModal(cellProps.row.original)}
      //       >
      //         View Details
      //       </Button>
      //     )
      //   },
      // },

      {
        Header: "View Document",
        disableFilters: true,
        accessor: "case",
        Cell: cellProps => {
          const complaintFiles = cellProps.row.original.complaintFile || [] // Assuming 'complaintFile' holds an array of documents
          const openPdfFile = complaintFile => {
            if (complaintFile && complaintFile !== "emptyFile") {
              window.open(
                `${Base_API_KEY}/documents/${complaintFile}`,
                "_blank"
              )
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  caret
                  style={{
                    backgroundColor: "#024E80",
                    color: "white",
                  }}
                  disabled={complaintFiles.length === 0}
                >
                  Select Document
                </DropdownToggle>
                <DropdownMenu>
                  {complaintFiles.map((complaintFile, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => openPdfFile(complaintFile)}
                    >
                      Document {index + 1}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          )
        },
      },
    ]
  }, [
    adminSingleData,
    selectedOptions,
    seloption,
    dropdownStates,
    selectedOptions1,
  ])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal1}
        selectedOptions={selectedOptions}
        toggle={toggleViewModal}
        selectedClientData={selectedClientData}
      />

      <ModalData
        isOpen={modal2}
        toggle={toggleViewModal2}
        IndexValue={IndexValue}
        selectedOptions={selectedOptions}
        selectedClientData={selectedClientData}
        dropdownOptions={dropdownOptions}
        Id={Id}
        selectOption={handleSelectOption}
        advocateDatas={advocateDatas}
      />
      <ModalStreatagy
        isOpen={modal4}
        Lastvalue={Lastvalue}
        value={false}
        selectedClientData={selectedClientData}
        toggle={toggleViewModal4}
        seloption={seloption}
        toggleViewModal2={toggleViewModal2}
      />

      {loader && !adminSingleData ? (
        <p>
          <Spinners setLoading={setLoading} />
        </p>
      ) : adminSingleData?.length > 0 ? (
        <Card>
          <CardBody>
            <TableContainer
              boolvalue={true}
              columns={dynamicColumns}
              data={adminSingleData}
              isGlobalFilter={true}
              isAddOptions={false}
              order="asc"
              isPagination={true}
              customPageSize={10}
              tableClass="align-middle table-nowrap table-check table"
              theadClass="table-light"
              paginationDiv="col-12"
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          </CardBody>
        </Card>
      ) : (
        <div className="alert alert-warning mt-3">
          <p className="text-primary mb-0">No Cases found</p>
        </div>
      )}
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
