import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { DateProvider } from "Context/DateContext"
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeTopbarTheme,
} from "store/actions"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
//axios
import axios from "utils/api"
// Import all middleware

import Authmiddleware from "./routes/route"
//context

import { LinkProvider } from "../src/Context/LinkContext"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { SchoolProvider } from "Context/SchoolContext"
import { ClasssificationProvider } from "Context/ClassificationContext"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const [isDashboardLawyers, setisDashboardLawyers] = useState(false)
  const [Resp, setResp] = useState("")
  const dispatch = useDispatch()
  const [isRole, setisRole] = useState("")

  const Role = sessionStorage.getItem("role")
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'YOUR_TOKEN_HERE' with your actual token
        const token = sessionStorage.getItem("accessToken")

        setisRole(Role)

        // axios request configuration
        const axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }

        // Make the API request
        const response = await axios.get("/auth/ping", axiosConfig)

        // Handle successful response
        setResp(response?.data?.content)
        if (Role == "ADVOCATE") {
          setisDashboardLawyers(true)
          dispatch(changeTopbarTheme("light"))
          dispatch(changeSidebarTheme("light"))
        } else if (Role != "ADVOCATE") {
          dispatch(changeTopbarTheme("dark"))
          dispatch(changeSidebarTheme("dark"))
        }
      } catch (error) {
        // Handle error
        console.error("Error:", error)
      }
    }

    fetchData() // Call the async function immediately
  }, [dispatch, isRole])
  const selectLayoutModeState = state => state.Layout
  const LayoutModeProperties = createSelector(
    selectLayoutModeState,
    layout => ({
      layoutModeType: "HORIZONTAL", // Use logical OR for default value
    })
  )

  const { layoutModeType } = useSelector(LayoutModeProperties)

  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  // dispatch({
  //   type: CHANGE_SIDEBAR_THEME,
  //   payload: leftSideBarThemeTypes.LIGHT,
  // });

  return (
    <React.Fragment>
      <ClasssificationProvider>
      <SchoolProvider>
        <LinkProvider>
          <DateProvider>
            <Routes>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                  key={idx}
                  exact={true}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    <Authmiddleware>
                      <Layout>{route.component}</Layout>
                    </Authmiddleware>
                  }
                  key={idx}
                  exact={true}
                />
              ))}
            </Routes>
          </DateProvider>
        </LinkProvider>
      </SchoolProvider>
      </ClasssificationProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
