import React from "react"
import { Col, Row, Card, CardBody, Input, Label } from "reactstrap"



const RadioGroup = ({ question, options, selectedValue, onChange }) => {
    return (
      <Col lg="12">
        <Row className="d-flex justify-content-center">
          <div className="mb-3">
            {options.map((option, index) => (
              <div key={index} className="form-check mt-3">
                <Input
                  type="radio"
                  id={`customRadioInline${index + 1}`}
                  name={`customRadioGroup_${question}`} 
                  className="form-check-input"
                  checked={selectedValue === option.value}
                  onChange={() => onChange(option.value)}
                />
                <Label
                  className="form-check-label"
                  htmlFor={`customRadioInline${index + 1}`}
                >
                  {option.label}
                </Label>
              </div>
            ))}
          </div>
        </Row>
      </Col>
    );
  };
  
  export default RadioGroup;
  

