import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "utils/api"
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const ModalStreatagy = props => {
  const {
    isOpen,
    toggle,
    Id,
    selectedClientData,
    toggleViewModal2,
    value,
    seloption,
    Lastvalue,
  } = props
  const accessToken = sessionStorage.getItem("accessToken")
  const [response, setResponse] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("")
  // const educationWithSpaces = selectedClientData?.education?.replace(/_/g, " ")
  const [successPopupVisible, setSuccessPopupVisible] = useState(false)
  const [strategyDescription, setStrategyDescription] = useState("") // Add state for strategy description
  const userRole = sessionStorage.getItem("role") // Fetch user role from session storage

  const shouldRenderButtons =
    (userRole == "super-admin" && seloption == "RFR") || userRole == "ADVOCATE"

  const handleDescriptionChange = event => {
    setStrategyDescription(event.target.value)
  }
  const handleStatusChange = event => {
    setSelectedStatus(event.target.value)
  }
  let apiUrl
  let requestBody = {}
  const handleSubmit = async () => {
    const accessToken = sessionStorage.getItem("accessToken")
    const role = sessionStorage.getItem("role")

    if (role == "ADVOCATE") {
      apiUrl = `/advocate/complaint/add_strategy/${selectedClientData?._id}`
      requestBody = {
        lab: "STRATEGY",
        description: strategyDescription,
      }
    } else {
      apiUrl = `/admin/complaint/add_strategy/${selectedClientData?._id}`
      requestBody = {
        lab: "RFR",
        description: strategyDescription,
      }
    }

    await axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        toggle()
        setStrategyDescription("")
        setSuccessPopupVisible(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch(err => {
        console.error("error on Response", err)
      })

    // Handle the response as needed

    // Replace the following logic as needed
    // toggleViewModal2(selectedClientData);
    // setSuccessPopupVisible(true);
  }

  const renderCaseDetails = () => (
    <div className="d-flex justify-content-center">
      {/* Add your case details here */}
    </div>
  )

  const renderTableHead = () => (
    <thead>
      <tr></tr>
    </thead>
  )
  let iteration = 0

  const renderTableBody = () => (
    <tbody className="d-flex flex-column gap-4 ">
      {value || seloption === "RFR" ? (
        <tr className="mb-5">
          <textarea
            className="form-control"
            value={strategyDescription}
            id="Strategy"
            rows={6}
            onChange={handleDescriptionChange}
            placeholder="Enter Details"
          ></textarea>
        </tr>
      ) : (
        <table className="table ">
          <thead>
            <tr>
              <th scope="col">Strategies Records</th>
            </tr>
          </thead>
          <tbody>
            {selectedClientData?.strategy?.map((strategy, index) => (
              <React.Fragment key={index}>
                {strategy.lab !== "RFR" && (
                  <tr>
                    <th scope="row">{`Streatagy lab ${++iteration}`}</th>
                  </tr>
                )}
                {strategy.lab !== "RFR" && (
                  <tr>
                    <td colSpan="2">
                      <div>
                        <p className="text-muted mb-0">
                          {strategy?.description}
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </tbody>
  )

  const renderModalBody = () => (
    <ModalBody>
      {renderCaseDetails()}
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap">
          {renderTableBody()}

          {renderTableHead()}
        </Table>
      </div>
    </ModalBody>
  )

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-md"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Case Details</ModalHeader>
          {renderModalBody()}
          <ModalFooter className="">
            {shouldRenderButtons && (
              <div className="d-flex justify-content-end gap-4">
                <div className="d-flex ">
                  <Button type="submit" color="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
                <div className="d-flex ">
                  <Button type="button" color="secondary" onClick={toggle}>
                    Close
                  </Button>
                </div>
              </div>
            )}
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={successPopupVisible}
        toggle={() => setSuccessPopupVisible(false)}
      >
        <Alert color="success">
          {/* Customize the success message as needed */}
          Status Added Sucessfully!
        </Alert>
      </Modal>
    </>
  )
}

ModalStreatagy.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ModalStreatagy
